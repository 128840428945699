import React, { useRef, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Layout from "layouts";
import SignUp from "views/signup/index";
import SignIn from "views/signin/index";
import VerifyEmail from "views/verifyEmail/index";
import ForgetPassword from "views/forgetPassword/index";
import ResetPassword from "views/resetPassword/index";
import request from "services/request";
import { useLoading } from "contexts/LoadingContextManagement";
function Routes() {
  const routerRef = useRef();
  const { setLoading } = useLoading();

  useEffect(() => {
    request.setLoadingFunc(setLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (routerRef && routerRef.current) {
      request.setRouter(routerRef.current);
    }
  }, [routerRef]);

  return (
    <Router ref={routerRef}>
      <Switch>
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/verify-email" component={VerifyEmail} />
        <Route exact path="/forgetpassword" component={ForgetPassword} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route path="/" component={Layout} />
      </Switch>
    </Router>
  );
}

export default Routes;
