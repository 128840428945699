export const ACTION_TYPES = {
  MULTI: "multi",
  PROVIDER_INGREDEINTS: "provider-ingredients",
  INGREDIENTS: "ingredients",
  ALLERGENS: "allergens",
};

export const initialState = {
  allergens: [],
  ingredients: [],
};

export default function reducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.PROVIDER_INGREDEINTS:
      return { ...state, providerIngredients: action.ingredients };
    case ACTION_TYPES.INGREDIENTS:
      return { ...state, ingredients: action.ingredients };
    case ACTION_TYPES.ALLERGENS:
      return { ...state, allergens: action.allergens };
    case ACTION_TYPES.MULTI:
      return { ...state, ...action.payload };

    default:
      throw new Error();
  }
}
