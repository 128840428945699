import SettingsView from "../views/settings";
import CoeqView from "../views/coeqView";


import { ReactComponent as SettingsSvg } from "../assets/images/icon/SETTINGS.svg";
import { ReactComponent as CoeqSvg } from "../assets/images/icon/CoeqSvg.svg";

const Routes = [
  {
    path: "/co2eq",
    exact: true,
    name: "CO2eq",
    component: CoeqView,
    heading: "CO2eq",
    icon: <CoeqSvg />,
    filterIcon: true,
  },
  {
    path: "/settings",
    exact: true,
    name: "Settings",
    component: SettingsView,
    heading: "Settings",
    subHeading: " ",
    icon: <SettingsSvg />,
    filterIcon: false,
  },
];

export default Routes;
