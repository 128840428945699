import React from "react";
import { Dropdown } from "react-bootstrap";

import SortIcon from "assets/images/icon/SORT.svg";
import ArrowDownIcon from "assets/images/icon/arrow_down.svg";
import ArrowUpIcon from "assets/images/icon/arrow_up.svg";
import { useTranslation } from "react-i18next";

function SortFilter({ cols, setfd, fd, rootClassName }) {
  const { t } = useTranslation();
  const handleSelect = (val) => {
    let { orderBy } = JSON.parse(JSON.stringify(fd));
    if (fd.sortby === val) {
      orderBy = orderBy === "asc" ? "desc" : "asc";
    }
    setfd({ ...fd, sortby: val, orderBy });
  };

  return (
    <>
      <Dropdown onSelect={handleSelect} className={rootClassName}>
        <Dropdown.Toggle
          variant="link"
          className="btn btn-white dropdown-toggle btn-icon"
        >
          {fd.orderBy !== "asc" && (
            <img
              src={ArrowDownIcon}
              alt=""
              className="me-0"
              style={{ width: "11px", paddingTop: "3px" }}
            />
          )}
          {fd.orderBy === "asc" && (
            <img
              src={ArrowUpIcon}
              alt=""
              className="me-0"
              style={{ width: "11px" }}
            />
          )}
          <img
            src={SortIcon}
            alt=""
            style={{
              ...(fd.orderBy === "asc" && {
                transform: "rotate(180deg)",
              }),
            }}
          />
          {fd?.sortby
            ? cols.find((c) => c.dataField === fd.sortby)?.caption
            : t("SortBy")}
        </Dropdown.Toggle>

        <Dropdown.Menu style={{width: "min-content"}}>
          {cols
            .filter((c) => c.dataField !== "action")
            .map((d, i) => (
              <Dropdown.Item key={i} eventKey={d.dataField}>
                {d.caption}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default SortFilter;

export const sortTableData = (fd, tableColumns, tableData) => {
  if (!fd.sortby) {
    return tableData;
  }
  const sortByCol = tableColumns.find((c) => c.dataField === fd.sortby);
  let sortedData = [];
  if (fd.orderBy === "desc") {
    if (sortByCol.type === "string") {
      sortedData = tableData.sort((a, b) =>
        a[fd.sortby].localeCompare(b[fd.sortby])
      );
    } else if (sortByCol.type === "number") {
      sortedData = tableData.sort((a, b) => b[fd.sortby] - a[fd.sortby]);
    }
  } else {
    if (sortByCol.type === "string") {
      sortedData = tableData.sort((a, b) =>
        b[fd.sortby].localeCompare(a[fd.sortby])
      );
    } else if (sortByCol.type === "number") {
      sortedData = tableData.sort((a, b) => a[fd.sortby] - b[fd.sortby]);
    }
  }

  return sortedData;
};
