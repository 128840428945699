import { useState, useCallback, useRef, useEffect } from "react";
import { Button, Modal, Row, Col, Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DragDrop from "./DragDrop";
import Imported from "./Imported";
import { read, utils } from "xlsx";
import request from "services/request";
import { useUserData } from "contexts/AuthContextManagement";
import EYE_ICON from "assets/images/icon/eye.svg";
import Trashicon from "assets/images/icon/filter.svg";
import DeleteModal from "views/commonViews/DeleteModal";
import JPEG_ICON from "assets/images/icon/jpeg.svg";
import CLOSE_ICON from "assets/images/icon/DELETE.svg";
import Uploaded from "./Uploaded";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { ALLERGENS_TRANSLATIONS, CATEGORY_TRANSLATIONS, MEAL_TYPE_TRANSLATIONS, STORAGE_UNIT_TRANSLATIONS, columnMapping, getImportedFileFormatData, translateFrenchToEnglish } from "../utils";
import storage from "services/storage";
import { useOnDropAccepted } from "common/utils";
import { useLoading } from "contexts/LoadingContextManagement";
import { useDropzone } from "react-dropzone";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

function ImportModule({ show, modalName, setFd, setIsAILoading, setFileImportLoading, onHide, title, options, requiredOptions, requestPath, onRowAdded }) {
  const { t  } = useTranslation();
  const location = useLocation();
  const { setError } = useLoading();
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState([]);
  const [state, setState] = useState({
    sumbitEnabled: true,
    rows: [],
    stepIndex: 0,
    selectedRows: {},
    optionsVisible: false,
    deleteModal: false,
    providerValMsg: undefined,
    ingredients: [],
    providers: [],
    fileValMsg: undefined,
    uploaded: false,
    responseMessage: undefined,
    errors: [],
  });
  const { selectedRestaurantId, hasRetaurants, isRestaurantLoaded } = useUserData();
  const localOptions = useRef(options)
  const localRequiredOptions = useRef(requiredOptions)
  const localModalTitle = useRef(title)

  const onDropAccepted = useOnDropAccepted(setFiles, setError, t);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: "image/jpg, image/jpeg, image/png, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/msword",
    maxFiles: 1,
    onDropAccepted
  });

  useEffect(() => {
      localOptions.current = options
      localRequiredOptions.current = requiredOptions
      localModalTitle.current = title
  }, [options, requiredOptions, title])

  const onDropAcceptedFile = useCallback(async (_acceptedFiles) => {
    setLoading(true);
    const file = _acceptedFiles[0];
    if(isRestaurantLoaded && !hasRetaurants) {
      setTimeout(() => {
      setLoading(false);
      setFileImportLoading(false)
      setState((prev) => ({ ...prev, stepIndex: 0, uploaded: true }));
      onHide(false)
    }, 2000)
      storage.setItem("uploaded_file", file?.name?.split(".")[1]);
      return
    }
    let formData = new FormData();
      formData.append("document", _acceptedFiles[0]);
    const result = await request.post("/carbon-footprint-meals/import-recipe-document", formData);
    const res = await result.json()
    const reader = new FileReader();
  }, []);

  const transformExcelData = (rows) => {
    const result = [Object.keys(rows[0])];
    for (let i = 0; i < rows.length; i++) {
      const element = rows[i];
      const temp = [];
      for (const key in element) {
        temp.push(element[key]);
      }
      result.push(temp);
    }
    return result;
  }

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="..." />
      </div>
    </div>
  ));

  const handleHeaderSelect = (value, index) => {
    setState((prev) => {
      const newState = { ...prev };
      newState.rows[0][index] = value;
      newState.fileValMsg = checkDataValidity();
      return newState;
    });
  };

  const onRemoveFile = (file, i) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const handleHide = () => {
    setState((prev) => ({
      ...prev,
      rows: [],
      stepIndex: 0,
      selectedRows: {},
      optionsVisible: false,
      deleteModal: false,
      providerValMsg: undefined,
      fileValMsg: undefined,
      ingredients: [],
      providers: [],
      uploaded: false,
    }));
    onHide(false);
  };

  const createPayload = () => {
    let result = [];
    for (let i = 1; i < state.rows.length; i++) {
      const element = state.rows[i];
      const obj = {};
      for (let j = 0; j < state.rows[0].length; j++) {
        if(options[state.rows[0][j]]) {
          const head = state.rows[0][j];
          obj[head] = (element[j]?.toString())
          if(head === 'cost_excl_tax'){
            obj[head] = element[j] || 0;
          }
          if(head === 'unit' || head === 'ingredient_unit' || head === 'recipe_unit' || head === 'conditioning_unit') {
            if(element[j] === 'piece'){
              obj[head] = 'item';
            }else{
              obj[head] = element[j]?.toLowerCase()
            }
          }

          if(head === "type") {
            obj[head] = translateFrenchToEnglish(element[j], MEAL_TYPE_TRANSLATIONS)
          }

          if(head === 'category') {
            obj[head] = translateFrenchToEnglish(element[j], CATEGORY_TRANSLATIONS)
          }
          if(head === 'allergens') {
            obj[head] = element[j] ? new Array(translateFrenchToEnglish(element[j], ALLERGENS_TRANSLATIONS)) : null
          }
          if(head === 'provider_reference_number') {
            obj[head] = element[j]?.toString() || null
          }
        }
      }
        obj.restaurant_id = selectedRestaurantId;
        result.push(obj);
    }
    return result;
  };

  const checkDataValidity = () => {
    const requiredKeys = Object.keys(requiredOptions);
    const head = state.rows[0];
    let names = "";
    let message = undefined;
    for (const key of requiredKeys) {
      if (!head.includes(key)) {
        names += ` ${requiredOptions[key]},`;
      }
    }
    if (Boolean(names)) {
      names = names.slice(0, -1);
      message =
        "These fields are required" +
        names +
        ". Make sure you have selected these fields.";
    }

    return message;
  };

 

  const validated = () => {
    let errors = {};
    if (!state.rows.length) {
      errors.fileValMsg = "Please select an excel file first.";
    }

    if (state.stepIndex === 1) {
      const msg = checkDataValidity();
      if (msg) {
        errors.fileValMsg = msg;
      }
    }

    const hasErrors = Object.keys(errors).length;
    if (hasErrors) {
      setState((prev) => ({ ...prev, ...errors }));
    }
    return !hasErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();


    setLoading(true);
    setFileImportLoading(true)
    setIsAILoading(true)
    const file = acceptedFiles[0];

    if(isRestaurantLoaded && !hasRetaurants) {
      setTimeout(() => {
      setLoading(false);
      setFileImportLoading(false)
      setIsAILoading(false)
    }, 2000)
    
      storage.setItem("uploaded_file", file?.name?.split(".")[1]);
      setState((prev) => ({ ...prev, stepIndex: 0, uploaded: true }));
      onHide(false)
      return
    }
    const fileExtension = acceptedFiles[0]?.name?.split('.').pop();

    if (!fileExtension || (fileExtension !== 'jpg' && fileExtension !== 'jpeg' && fileExtension !== 'png' && fileExtension !== 'pdf' && fileExtension !== 'xlsx' && fileExtension !== 'docx' && fileExtension !== 'doc' && fileExtension !== 'xls') || !acceptedFiles.length) {
      toast.error(t("Please upload only image files"));
      setLoading(false)
      return
    }
    try {
      let formData = new FormData();
      formData.append("document", acceptedFiles[0]);
      setLoading(false)
      setState((prev) => ({ ...prev, stepIndex: 0, uploaded: true }));
      setIsAILoading(true)
      onHide(false)
    const result = await request.patchFile("/carbon-footprint-meals/import-recipe-document", formData, 'POST');
    if(result.ok) {
      const res = await result.json()
      setFd((prevFd) => ({ ...prevFd, ingredients: res.extracted_recipe?.map(i => ({ingredient_id: {name: i?.matching_ing, id: i?.id},ingredient_unit: "kg", carbon_emission: i?.co2_emission, ingredient_quantity: i?.quantity, is_local: false})) }));
      setIsAILoading(false)
      setFileImportLoading(false)
    }else {
      toast.error(t("Uploaded file is not supported."))
      setIsAILoading(false)
      setFileImportLoading(false)
    }
    //   let payload
    //   if(title === 'AddMultipleIngredients') {
    //      payload = { ingredients: createPayload() }
    //   }else if(title === 'AddMultipleProviderIngredients') {
    //     payload = { provider_ingredients: createPayload() };
    //   }else if(title === 'AddMultipleMeals') {
    //     payload = {meals: createPayload()}
    //   }else {
    //     payload = {providers: createPayload()}
    //   }

    //  const result = await request.post(requestPath, payload);
    }catch(err) {
      console.log({err})
     }
  };

  // const handleChange = (key) => (value) => {
  //   setState((prev) => ({
  //     ...prev,
  //     selectedProvider: value,
  //     providerValMsg: undefined,
  //   }));
  // };

  const handleSelectRow = (checked, rowKey) => {
    setState((prev) => {
      const newState = { ...prev };
      newState.selectedRows[rowKey] = checked;
      newState.optionsVisible = !Object.keys(newState.selectedRows).every(
        (v) => newState.selectedRows[v] === false
      );
      return newState;
    });
  };

  const deleteSelected = () => {
    setState((prev) => ({ ...prev, deleteModal: true }));
  };

  
const setErrors = (errors) => {
  setState((prev) => ({ ...prev, errors }))
}

const setData = (rows) => {
  const data = getImportedFileFormatData(rows, true, localModalTitle, t);
  setState((prev) => ({ ...prev, rows, errors: data.error }))
}

  const deleteConfirmed = () => {
    const newState = { ...state };
    const keys = Object.keys(newState.selectedRows);
    for (const element of keys.reverse()) {
      if (newState.selectedRows[element]) {
        newState.rows.splice(element, 1);
      }
    }

    const data = getImportedFileFormatData(newState.rows, true, localModalTitle, t);
    setState((prev) => ({
      ...prev,
      rows: newState.rows,
      errors: data.error?.length ? data.error : [],
      selectedRows: {},
      optionsVisible: false,
      deleteModal: false
    }));  
  };

  const renderBody = () => (
    <>
      <Modal.Header className="add-restaurants-modal-header" closeButton>
        <Modal.Title className="add-restaurants-modal-title">
          {t(`${title}`)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-between">
          {/* <Col>
            <span className="add-restaurants-input-title ps-0">
              {t("Provider")}
            </span>
            <SelectInput
              dataField="provider_id"
              placeholder={t("SelectProvider")}
              options={providers?.map((p) => ({
                ...p,
                label: p.name,
                value: p.id,
              }))}
              onChange={handleChange}
              value={state.selectedProvider}
              validated={false}
            />
            <Form.Text className="text-danger">
              {state.providerValMsg}
            </Form.Text>
          </Col> */}
          <Col className="text-end">
            {state.optionsVisible && (
              <img
                src={Trashicon}
                className="mt-4"
                onClick={deleteSelected}
                alt="..."
              />
            )}
          </Col>
        </Row>
        {state.stepIndex === 0 && 
                <Row className="mt-4 photo-container">
                  <Col lg={12}>
                    <span className="input-title ps-0">{t("Photo / File")}</span>
                    <div className="">
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <p>{t("Drag&Drop")}</p>
                      </div>
                      <aside>
                        <span className="input-title m-0">
                          {t("AcceptedFiles")}
                        </span>
                        <div className="files-list">
                          <ul>
                            {files.map((file, i) => (
                              <li
                                key={i}
                                className="d-flex justify-content-between"
                              >
                                <div className="d-flex">
                                  <img src={JPEG_ICON} alt="..." />
                                  <p className="ps-3">
                                    <span>{file.path}</span>
                                    <span className="size">
                                      {parseInt(file.size * 0.001)} Kb
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <img
                                    src={EYE_ICON}
                                    alt="..."
                                    className="eye me-3"
                                  />
                                  <img
                                    src={CLOSE_ICON}
                                    alt="..."
                                    className="close"
                                    onClick={onRemoveFile(file, i)}
                                  />
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </aside>
                      <aside style={thumbsContainer}>{thumbs}</aside>
                      {/* {fd.image_path && !files.length && (
                        <div style={thumb}>
                          <div style={thumbInner}>
                            <img src={fd.image_path} style={img} alt="..." />
                          </div>
                        </div>
                      )} */}
                    </div>
                  </Col>
                </Row>}
        {/* {state.stepIndex === 1 && (
          <Imported
            rows={state.rows}
            selectedRows={state.selectedRows}
            selectHeader={handleHeaderSelect}
            handleSelectRow={handleSelectRow}
            errors={state.errors}
            setErrors={setErrors}
            setData={setData}
            dropdownOptions={options}
            allergens={state.allergens}
          />
        )} */}

        {(state.stepIndex === 1 && loading)  && 
        <div style={{textAlign: 'center'}}>
                <Spinner animation="border" variant="primary" />
        </div>}
        <Form.Text className="text-danger">{state.fileValMsg}</Form.Text>
      </Modal.Body>
      <Modal.Footer className="add-restaurants-modal-footer">
        <Button
          type="submit"
          disabled={!state.sumbitEnabled}
          className="add-restaurant-confirm-btn"
          onClick={handleSubmit}
        >
          {t("Confirm")}
        </Button>
      </Modal.Footer>
    </>
  );

  return (
    <Modal
      show={show}
      onHide={handleHide}
      size="xl"
      centered
      className="add-ingredient"
      backdropClassName="add-ingredient-backdrop"
    >
      <DeleteModal
        show={state.deleteModal}
        onHide={() => setState((prev) => ({ ...prev, deleteModal: false }))}
        onPositiveClicked={deleteConfirmed}
        modalData={{
          title: t(`Delete ${(modalName).toLowerCase()}s`),
          description: t(
            `Are you sure you want to delete ${(modalName).toLowerCase()}s ? You cannot undo this action.`
          ),
          positiveBtnTitle: t(`Yes, delete ${(modalName).toLowerCase()}s`),
        }}
      />
      {!state.uploaded && renderBody()}
      {/* {state.uploaded && <Uploaded responseMessage={state.responseMessage} modalName={modalName} ingredients={state.ingredients} providers={state.providers} onHide={handleHide} t={t} />} */}
    </Modal>
  );
}

export default ImportModule;
