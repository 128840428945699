import React, { useState, useEffect, useCallback } from "react";
import Creatable from "react-select/creatable";
import { useDropzone } from "react-dropzone";
import convertUnits from 'convert-units';

import {
  Modal,
  Button,
  Row,
  Col,
  Container,
  Card,
  Badge,
  Form,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { withAsyncPaginate } from "react-select-async-paginate";

import { useLoading } from "contexts/LoadingContextManagement";
import { useUserData } from "contexts/AuthContextManagement";
import { cloneDeep, roundToTwo } from "common/utils.ts";
import { useOnDropAccepted } from 'common/utils.ts';
import SelectInput from "components/selectInput";
import SelectAsyncPaginate from "components/selectAsyncPaginate/index.tsx";
import request from "services/request";
import {
  INGREDIENT_CATEGORIES,
  DEFAULT_ERROR_MESSAGE,
  UNITS,
} from "common/constants";

import UploadedIcon from "assets/images/uploaded_meal.png";
import JPEG_ICON from "assets/images/icon/jpeg.svg";
import CLOSE_ICON from "assets/images/icon/DELETE.svg";
import EYE_ICON from "assets/images/icon/eye.svg";
import { getConvertibleUnitOptions } from "../../settings/utils";


function TextInput({
  type = "text",
  dataField,
  required = true,
  caption,
  ...props
}) {
  return (
    <>
      <span className="add-restaurants-input-title ps-0">{caption}</span>
      <input
        type={type}
        name={dataField}
        className="form-control custom-input"
        required={required}
        {...props}
      />
    </>
  );
}


function Text({caption, style}) {
  let customStyle = {...style}
  return (
    <>
      <span style={customStyle} className="add-restaurants-input-title ps-0 form-control">{caption}</span>
    </>
  );
}

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

function CustomModal({
  onHide,
  onRowAdded,
  show,
  data: { allergens = [], providers = [], selectedIngredient },
  isUploadedModal = true,
}) {
  const { t } = useTranslation();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isUploaded, setIsUploaded] = useState(false);
  const { setLoading, setError } = useLoading();
  const [files, setFiles] = useState([]);
  const [fd, setFd] = useState({ allergens: [], details: [] });

  const onDropAccepted = useOnDropAccepted(setFiles, setError, t);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: "image/jpg, image/jpeg, image/png",
    maxFiles: 1,
    onDropAccepted
  });

  const {
    selectedRestaurantId,
    isRestaurantLoaded,
    hasRetaurants,
    setSampleRestaurantModal,
  } = useUserData();

  useEffect(() => {
    setFd({ allergens: [], details: [] });
    setFiles([]);
    setIsSubmitDisabled(true);
    setIsUploaded(false);
  }, [show]);

  useEffect(() => {
    if (
      fd?.name &&
      fd?.cost_excl_tax &&
      fd?.category &&
      fd?.unit
    ) {
      setIsSubmitDisabled(false);
      return;
    }
    setIsSubmitDisabled(true);
  }, [fd]);

  useEffect(() => {
    if (selectedIngredient) {
      setFd({
        ...selectedIngredient,
        cost_excl_tax: (selectedIngredient.cost_excl_tax / selectedIngredient.details?.find(p => p.is_default === true)?.provider_ingredient?.recipe_unit_quantity)?.toFixed(4),
        allergens: selectedIngredient?.allergens?.map((a) => a.id),
      });
    }
  }, [selectedIngredient]);

  const onAllergenClick =
    ({ id }) =>
    () => {
      let newAllergens = cloneDeep(fd.allergens);
      if (newAllergens.includes(id)) {
        newAllergens = newAllergens.filter((allergenId) => allergenId !== id);
      } else {
        newAllergens = [...newAllergens, id];
      }
      setFd({
        ...fd,
        allergens: newAllergens,
        details: []
      });
    };

  const onSelectionChange =
    (type) =>
    ({ id }) => {
      if(type === 'unit'){
        const defaultProvider = fd?.details.find(p => p.is_default)?.provider_ingredient
        if(id === defaultProvider?.recipe_unit){
          setFd({ ...fd, cost_excl_tax: (defaultProvider.price_excl_tax / defaultProvider.recipe_unit_quantity)?.toFixed(4), converted_unit: null, [type]: id });
          return
        }
        if (convertUnits().possibilities().includes(id) && 
            convertUnits().from(id)
            .possibilities().includes(defaultProvider?.recipe_unit)) {
              const convertedValue = (convertUnits(1).from(id).to(defaultProvider?.recipe_unit));
              const convertedCost = (convertedValue * fd?.cost_excl_tax)?.toFixed(4);
              setFd({ ...fd, cost_excl_tax: convertedCost,converted_unit: null, [type]: id });
              return
            }
        setFd({ ...fd, converted_unit: id, [type]: id });
      }
      if(type === "category"){
        setFd({ ...fd, [type]: id });
      }
    };

  const onTextChange = ({ target: { name, value, type } }) => {
    if (["cost_excl_tax"].includes(name)) {
      if (name === "cost_excl_tax") {
        return setFd({
          ...fd,
          [name]: value,
        });
      }else if (name === "format") {
        return setFd({
          ...fd,
          [name]: value,
          unit_price: roundToTwo(fd?.total_price / value),
        });
      }
    }
    setFd({ ...fd, [name]: value });
  };

  const onSubmit = async (ev) => {
    ev.preventDefault();
    try {
      if (isRestaurantLoaded && !hasRetaurants) {
        setSampleRestaurantModal(true);
        onHide();
        return;
      }
      setIsSubmitDisabled(true);
      setLoading(true);
      const payload = {
        restaurant_id: selectedRestaurantId,
        ...fd,
        cost_excl_tax: fd.cost_excl_tax * fd.details?.find(p => p.is_default)?.provider_ingredient.recipe_unit_quantity,
        unit: fd?.converted_unit ?? fd?.unit,
        details: fd?.details.map(item => ({provider_ingredient_id: item.provider_ingredient.id, is_default: item.is_default }))
      };

      // if(fd.details?.some((item => item.provider_ingredient?.recipe_unit !== fd.unit))){
      //   setError("Units for Provider and Fullsoon ingredients should be same")
      //   return
      // }

      if(fd.details.length === 0) {
        setError(t("Please Select Provider ingredient to create ingredient"))
        return
      }

      if(!fd.details?.some((item => item.is_default))){
        setError("Please Select Default value")
        return
      }
      delete payload.converted_unit;

      if (!selectedIngredient) {
        const result = await request.post("/ingredients", payload);
        if ([200, 201].includes(result.status)) {
          fileUpload(await result.json());
          return;
        }
        throw Error(result);
      }
      delete payload.id;
      delete payload.provider_name;
      delete payload.createdAt;
      delete payload.updatedAt;
      delete payload.label;
      delete payload.value;
      delete payload.deletedAt;
      if (payload.image_path !== "" && payload.image_path !== null) {
        delete payload.image_path;
      }
      const resultQuery = await request.patch(
        `/ingredients/${selectedIngredient.id}`,
        payload
      );
      if ([200].includes(resultQuery.status)) {
        fileUpload(await resultQuery.json(), false);
        return;
      }
      throw Error(resultQuery);
    } catch (error) {
      console.log(error);
      setIsSubmitDisabled(false);
    }
  };

  const fileUpload = async (ingredient, create = true) => {
    if (!acceptedFiles.length) {
      onRowAdded(ingredient, create);
      setFd({ allergens: [], details: [] });
      setLoading(false);
      setIsSubmitDisabled(false);
      if (isUploadedModal) {
        setIsUploaded(true);
        return;
      }
      onHide();
      return;
    }
    try {
      let formData = new FormData();
      formData.append("image", acceptedFiles[0]);

      await request.patchFile(
        `/ingredients/upload-image/${ingredient.id}`,
        formData
      );
      onRowAdded(ingredient, create);
      if (!isUploadedModal) {
        onHide();
      }
      setIsUploaded(true);

      setLoading(false);
      setFd({ allergens: [], details: [] });
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    } finally {
      setIsSubmitDisabled(false);
    }
  };

  const handleSwitchChange = (id) => {
    const selectedProviderIngredient = fd.details.find(item => item.provider_ingredient?.id === id)?.provider_ingredient
    fd.details.forEach((item,i) => {
        setFd((prevFd) => {
          return {
          ...prevFd,
          details: prevFd.details.map(d => ({...d, is_default: d.provider_ingredient?.id === id ? true : false})),
          cost_excl_tax: (selectedProviderIngredient?.price_excl_tax / selectedProviderIngredient?.recipe_unit_quantity).toFixed(4),
          unit: selectedProviderIngredient?.recipe_unit,
          converted_unit: null
          }
        })
    })
  };

  const onRemoveFile = (file, i) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="..." />
      </div>
    </div>
  ));

  // const getProviderValue = () => {
  //   const result = providers?.find(({ id }) => id === fd?.provider_id);
  //   if (result) {
  //     return { ...result, label: result.name, value: result.id };
  //   }
  // };

  // const loadOptions = async (search) => {
  //   if (!search || search.length < 3 || search === "") {
  //     return { options: [], hasMore: false };
  //   }

  //   try {
  //     let ourIngredients = [];
  //     if (selectedIngredient) {
  //       const ourIngredientsResponse = await request.get(
  //         "ingredients",
  //         { restaurant_id: selectedRestaurantId, search },
  //         true,
  //         false
  //       );
  //       ourIngredients = ourIngredientsResponse.ingredients
  //         .map((i) => ({
  //           ...i,
  //           value: i.id,
  //           label: i.name,
  //         }));
  //       }
  //     const response = await request.get(
  //       "imported-ingredients/search",
  //       { qry: search },
  //       true,
  //       false
  //     );
  //     const globalIngredients = response.ingredients.map((i) => ({
  //       ...i,
  //       value: "no-id-available-" + i.name,
  //       label: i.name,
  //     }));
  //     return {
  //       options: [...ourIngredients, ...globalIngredients],
  //       hasMore: false,
  //     };
  //   } catch (error) {
  //     console.log(error);
  //     return { options: [], hasMore: false };
  //   }
  // };

  // const filterOutAllergen = (selectedAllergens) => {
  //   return allergens
  //     .filter((allergen) =>
  //       selectedAllergens.includes(allergen.name.toLowerCase())
  //     )
  //     .map((allergen) => allergen.id);
  // };

  const onMealSelectBoxChange =  (i) => (type) => (newMealSelected) => {
    if (type === "provider-ingredients") {
      setFd((prevFd) => ({
        ...prevFd,
        details: [...prevFd.details, {is_default: false, provider_ingredient: newMealSelected}],
      }));
      return;
    }
  }

  const deleteIngredient = (index, type) => () => {
    if (type === "provider_ingredient") {
      fd.details.splice(index, 1);
      return setFd({ ...fd });
    }
  }


  // const createSelectChange = (newObj, { action }) => {
  //   setFd((prev) => {
  //     const isNewObjArray = Array.isArray(newObj);
  //     const obj = isNewObjArray
  //       ? newObj.find((o) => (o.value = selectedIngredient?.id)) ?? newObj[0]
  //       : newObj;
  //     if (!obj) return;

  //     let anotherObj;
  //     if (isNewObjArray) {
  //       const dupObjects = newObj.filter(
  //         (o) => !o.value.includes("no-id-available-") && o.value !== obj.value
  //       );
  //       setDupliciateIngredients(dupObjects);
  //       anotherObj = newObj.find(
  //         (o) => o.value.includes("no-id-available-") && o.value !== obj.value
  //       );
  //     }

  //     let selectedObj;
  //     if (newObj[newObj.length - 1]?.brands) {
  //       selectedObj = newObj[newObj.length - 1];
  //     } else {
  //       selectedObj = obj;
  //     }

  //     const name = anotherObj?.label ?? obj?.label;

  //     return {
  //       ...prev,
  //       name: name || "",
  //       ...(action === "select-option" && {
  //         ...(selectedObj?.brands && { brand: selectedObj.brands }),
  //         ...(selectedObj?.format && { format: selectedObj.format }),
  //         unit: (selectedObj?.unit ?? prev.unit)?.toLowerCase()?.trim(),
  //         image_path: selectedObj?.image_small_url,
  //         ...(selectedObj?.allergens && {
  //           allergens: filterOutAllergen(
  //             selectedObj?.allergens
  //               .split(",")
  //               .filter((allergen) => allergen !== "")
  //               .map((allergen) => allergen.toLowerCase())
  //           ),
  //         }),
  //       }),
  //       ...(action === "create-option" && {}),
  //       ...(action === "clear" && {
  //         name: "",
  //         brand: "",
  //         format: "",
  //         unit: "",
  //       }),
  //     };
  //   });
  // };

  const deleteImage = () => {
    setFd((prev) => ({ ...prev, image_path: null }));
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      centered
      className="add-ingredient"
      backdropClassName="add-ingredient-backdrop"
    >
      <Modal.Header className="add-restaurants-modal-header" closeButton>
        {!isUploaded && (
          <Modal.Title className="add-restaurants-modal-title">
            {selectedIngredient ? t("Edit") : t("Add")} {t("ingredient(s)")}
          </Modal.Title>
        )}
      </Modal.Header>

      <Modal.Body>
        <Container className="p-0">
          {isUploadedModal && isUploaded && (
            <div className="d-flex justify-content-center flex-column  text-center upload-container">
              <div>
                <img src={UploadedIcon} alt="..." />
              </div>
              <div className="heading mt-5">
                <label>{t("Ingredient uploaded")} !</label>
              </div>
              <div className="subheading mt-2 mb-5">
                <label>
                  {t("Your ingredient has been successfully uploaded")}.
                </label>
              </div>
              <div>
                <Button className="add-restaurant-confirm-btn" onClick={onHide}>
                  {t("OK")}
                </Button>
              </div>
            </div>
          )}
          {!isUploaded && (
            <Form noValidate className="ps-0 pe-0" onSubmit={onSubmit}>
              <Row className=" d-flex justify-content-between">
              <Col lg={3}>
                  <TextInput
                    dataField={"name"}
                    caption={t("IngredientName")}
                    placeholder={t("IngredientName")}
                    onChange={onTextChange}
                    value={fd?.name}
                    type="text"
                  />
                </Col>
                {/* <Col lg={4}>
                  <span className="add-restaurants-input-title ps-0">
                    {t("IngredientName")}
                  </span>
                  <CreatableAsyncPaginate
                    value={dropdownIngredientValue}
                    loadOptions={loadOptions}
                    isMulti={!!selectedIngredient}
                    onChange={createSelectChange}
                    placeholder={t("IngredientName")}
                    isClearable
                  />
                </Col> */}
                {/* <Col lg={2}>
                  <TextInput
                    dataField={"reference_name"}
                    caption={t("Reference Name")}
                    placeholder={t("Reference Name")}
                    onChange={onTextChange}
                    value={fd?.reference_name}
                  />
                </Col> */}
                {/* <Col lg={2}>
                  <TextInput
                    dataField={"brand"}
                    caption={t("Brand Name")}
                    placeholder={t("Brand Name")}
                    onChange={onTextChange}
                    value={fd?.brand}
                  />
                </Col> */}
                <Col lg={3}>
                  <span className="add-restaurants-input-title ps-0">
                    {t("Category")}
                  </span>
                  <SelectInput
                    dataField="category"
                    placeholder={t("SelectCategory")}
                    options={INGREDIENT_CATEGORIES.slice(1).map((i) => ({
                      ...i,
                      label: t(i.label),
                    }))}
                    onChange={onSelectionChange}
                    value={INGREDIENT_CATEGORIES.find(
                      ({ id }) => id === fd?.category
                    )}
                  />
                </Col>
                <Col lg={3}>
                  <span className="add-restaurants-input-title ps-0">
                    {t("Unit")}
                  </span>
                  <SelectInput
                    dataField="unit"
                    placeholder={t("SelectUnit")}
                    options={fd.details?.find(({ is_default }) => is_default) ? getConvertibleUnitOptions(fd.details?.find(({ is_default }) => is_default)?.provider_ingredient?.recipe_unit) : UNITS.slice(1).map((r) => ({ ...r, label: t(r.label) }))}
                    onChange={onSelectionChange}
                    value={UNITS.find(({ id }) => id === (fd?.converted_unit ?? fd?.unit)?.toLowerCase())}
                  />
                </Col>
                <Col lg={3}>
                  <TextInput
                    dataField={"cost_excl_tax"}
                    caption={t("UnitCost")}
                    placeholder={t("cost without tax")}
                    onChange={onTextChange}
                    value={fd?.cost_excl_tax ? fd?.cost_excl_tax : undefined}
                    type="string"
                  />
                </Col>
                {/* <Col lg={2}>
                  <TextInput
                    dataField={"selling_price"}
                    caption={t("SellingPrice")}
                    placeholder={t("SellingPrice")}
                    onChange={onTextChange}
                    value={fd?.selling_price}
                    type="number"
                  />
                </Col> */}
              </Row>

              {/* <Row className="mt-4 d-flex justify-content-between"> */}
                {/* <Col lg={2}>
                  <TextInput
                    dataField={"sector_number"}
                    caption={t("Sector Number")}
                    placeholder={t("Sector Number")}
                    onChange={onTextChange}
                    value={fd?.sector_number}
                  />
                </Col> */}
                {/* <Col lg={2}>
                  <TextInput
                    dataField={"reference_number"}
                    caption={t("Reference Number")}
                    placeholder={t("Reference Number")}
                    onChange={onTextChange}
                    value={fd?.reference_number}
                  />
                </Col> */}
                {/* <Col lg={4}>
                  <span className="add-restaurants-input-title ps-0">
                    {t("Mercuriale")}
                  </span>
                  <SelectInput
                    dataField="mercuriale"
                    placeholder={t("Select Mercuriale")}
                    options={[
                      { label: "Fresh", value: "fresh", id: "fresh" },
                      {
                        label: "NOT Fresh",
                        value: "not_fresh",
                        id: "not_fresh",
                      },
                    ]}
                    onChange={onSelectionChange}
                    value={[
                      { label: "Fresh", value: "fresh", id: "fresh" },
                      {
                        label: "NOT Fresh",
                        value: "not_fresh",
                        id: "not_fresh",
                      },
                    ].find((v) => v.value === fd?.mercuriale)}
                  />
                </Col> */}
                {/* <Col lg={2}>
                  <TextInput
                    dataField={"unit_price"}
                    caption={t("Unit Price")}
                    placeholder={t("Unit Price")}
                    onChange={onTextChange}
                    value={fd?.unit_price}
                    type="number"
                  />
                </Col> */}
              
              {/* </Row> */}

              {/* <Row className="mt-4 d-flex justify-content-between"> */}
                {/* <Col lg={2}>
                  <TextInput
                    dataField={"format"}
                    caption={t("Format")}
                    placeholder={t("Format")}
                    onChange={onTextChange}
                    value={fd?.format}
                    type="number"
                  />
                </Col> */}
                {/* <Col lg={2}>
                  <TextInput
                    dataField={"min_stock"}
                    caption={t("min_stock")}
                    placeholder={t("min_stock")}
                    onChange={onTextChange}
                    value={fd?.min_stock}
                    type="number"
                  />
                </Col>
                <Col lg={2}>
                  <TextInput
                    dataField={"max_stock"}
                    caption={t("max_stock")}
                    placeholder={t("max_stock")}
                    onChange={onTextChange}
                    value={fd?.max_stock}
                    type="number"
                  />
                </Col> */}
             
                {/* <Col lg={3}>
                  <span className="add-restaurants-input-title ps-0">
                    {t("Provider")}
                  </span>
                  <SelectInput
                    dataField="provider_id"
                    placeholder={t("SelectProvider")}
                    options={providers?.map((p) => ({
                      ...p,
                      label: p.name,
                      value: p.id,
                    }))}
                    onChange={onSelectionChange}
                    value={getProviderValue()}
                  />
                </Col> */}
              {/* </Row> */}

              <Row className="mt-4">
                  <Col lg={12}>
                    <span className="input-title ps-0">{t("ProviderIngredients")}</span>
                    <Card className="mb-0">
                      <div className="p-4 allergens-container">
                        <div className="row custom-table h-100">
                          <div className="col-lg-5 h-100 p-2">
                            <SelectAsyncPaginate
                              dataField="provider-ingredients"
                              placeholder={t("SelectProviderIngredients")}
                              value={t("SelectIngredientsByMeal")}
                              query="provider-ingredients"
                              isMulti={false}
                              onChange={onMealSelectBoxChange()}
                            />
                          </div>
                        </div>

                        <div className="row custom-table h-100">
                          <div className="col-lg-12 h-100 p-0">
                            <div className="tablescroll">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <td style={{ width: "55%" }}>
                                    {t("Name")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "35%" }}
                                    >
                                      {t("Provider")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "25%" }}
                                    >
                                      {t("Unit")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "30%", textAlign: "end" }}
                                    >
                                      {t("UnitCost")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "30%", textAlign: "end" }}
                                    >
                                      {t("SetAsDefault")}
                                    </td>
                                    <td
                                      style={{
                                        width: "50px",
                                        textAlign: "end",
                                      }}
                                    ></td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fd?.details?.map((currentMeal, i) => (
                                    <tr
                                      key={i}
                                      className="flex justify-between"
                                    >
                                      <td style={{ height: "50px" }}>
                                        {/* <SelectAsyncPaginate
                                          dataField="meal_id"
                                          placeholder={t("SelectIngredient")}
                                          value={currentMeal}
                                          query="meals"
                                          key="meal_id"
                                          isMulti={false}
                                          onChange={onIngredientSelectBoxChange(
                                            currentMeal.id
                                          )}
                                          mapper={(rows) =>
                                            rows.map((row) => ({
                                              ...row,
                                              name: row.name,
                                            }))
                                          }
                                        /> */}
                                        {/* <TextInput
                                          dataField="name"
                                          disabled
                                          onChange={onTextChange}
                                          value={currentMeal?.provider_ingredient?.name}
                                          type="text"
                                        /> */}
                                        <Text style={{width: "100%", display: "inline-block", whiteSpace: "initial"}}  caption={currentMeal?.provider_ingredient?.provider_reference_name ? currentMeal?.provider_ingredient?.provider_reference_name : fd?.name} />
                                      </td>
                                      <td className="text-center" style={{ width: "200px" }}>
                                        {/* <TextInput
                                          dataField="provider.name"
                                          disabled
                                          onChange={onTextChange}
                                          value={currentMeal?.provider_ingredient?.provider?.name}
                                          type="text"
                                        /> */}
                                        <Text style={{overflow: "hidden"}} caption={currentMeal?.provider_ingredient?.provider?.name} />
                                      </td>
                                      <td className="text-center" style={{ width: "100px" }}>
                                        {/* <TextInput
                                          dataField="recipe_unit"
                                          placeholder="unit"
                                          disabled
                                          onChange={onIngredientTextBoxChange(
                                            i
                                          )}
                                          value={currentMeal.provider_ingredient?.recipe_unit}
                                          type="text"
                                        /> */}
                                        <Text caption={currentMeal.provider_ingredient?.recipe_unit} />
                                      </td>
                                      <td className="text-center">
                                      {/* <TextInput
                                          dataField="price_excl_tax"
                                          placeholder="unit"
                                          disabled
                                          onChange={onIngredientTextBoxChange(
                                            i
                                          )}
                                          value={currentMeal.provider_ingredient?.price_excl_tax}
                                          type="number"
                                        /> */}
                                        <Text caption={parseFloat(currentMeal.provider_ingredient?.price_excl_tax / currentMeal.provider_ingredient?.recipe_unit_quantity).toFixed(4)} />
                                      </td>
                                      <td style={{ width: "100px" }} className="text-center">
                                      <Form>
                                        <Form.Check // prettier-ignore
                                          type="switch"
                                          id="custom-switch-check"
                                          checked={currentMeal.is_default}
                                          onChange={() => handleSwitchChange(currentMeal.provider_ingredient?.id)}
                                        />
                                      </Form>
                                      </td>
                                      <td className="p-0">
                                        <button
                                          type="button"
                                          onClick={deleteIngredient(i, `provider_ingredient`)}
                                          className="table-delete-btn p-0"
                                        >
                                          <img src={CLOSE_ICON} alt="" />
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>

              <Row className="mt-4">
                <Col lg={12}>
                  <span className="add-restaurants-input-title ps-0">
                    {t("Allergens")}
                  </span>
                  <Card className="mb-0">
                    <div className="p-4 allergens-container">
                      {allergens.map((allergen, key) => (
                        <Badge
                          key={key}
                          pill
                          className={`allergens-badge ${
                            fd?.allergens?.includes(allergen.id) ? "active" : ""
                          }`}
                          onClick={onAllergenClick(allergen)}
                        >
                          {t(allergen.name)}
                        </Badge>
                      ))}
                    </div>
                  </Card>
                </Col>
              </Row>

              <Row className="mt-4 photo-container">
                <Col lg={12}>
                  <span className="add-restaurants-input-title ps-0">
                    {t("Photo")}
                  </span>
                  <div className="">
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input {...getInputProps()} />
                      <p>{t("Drag&Drop")}</p>
                    </div>
                    <aside>
                      <span className="add-restaurants-input-title m-0">
                        {t("AcceptedFiles")}
                      </span>
                      <div className="files-list">
                        <ul>
                          {files.map((file, i) => (
                            <li
                              key={i}
                              className="d-flex justify-content-between"
                            >
                              <div className="d-flex">
                                <img src={JPEG_ICON} alt="..." />
                                <p className="ps-3">
                                  <span>{file.path}</span>
                                  <span className="size">
                                    {parseInt(file.size * 0.001)} Kb
                                  </span>
                                </p>
                              </div>
                              <div>
                                <img
                                  src={EYE_ICON}
                                  alt="..."
                                  className="eye me-3"
                                />
                                <img
                                  src={CLOSE_ICON}
                                  alt="..."
                                  className="close"
                                  onClick={onRemoveFile(file, i)}
                                />
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </aside>
                    <aside style={thumbsContainer}>{thumbs}</aside>
                    {fd?.image_path && !files.length && (
                      <div style={thumb} className="position-relative">
                        <img
                          src={CLOSE_ICON}
                          alt="..."
                          className="deleteImage"
                          onClick={deleteImage}
                        />
                        <div style={thumbInner}>
                          <img src={fd.image_path} style={img} alt="..." />
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Modal.Footer className="add-restaurants-modal-footer">
                <Button
                  type="submit"
                  disabled={isSubmitDisabled}
                  className="add-restaurant-confirm-btn"
                >
                  {t("Confirm")}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default CustomModal;
