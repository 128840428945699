import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import Creatable from "react-select/creatable";
import { useTranslation } from "react-i18next";
import WorldFlag from 'react-world-flags';
import Select, { components } from 'react-select';
import omit from "lodash/omit";
import convert from "convert";
import {
  Modal,
  Button,
  Row,
  Col,
  Container,
  Card,
  Badge,
  Form,
  Spinner,
} from "react-bootstrap";
import { withAsyncPaginate } from "react-select-async-paginate";

import request from "services/request";
import SelectInput from "components/selectInput";
import { useLoading } from "contexts/LoadingContextManagement";
import { useUserData } from "contexts/AuthContextManagement";
import {
  DEFAULT_ERROR_MESSAGE,
  CARBON_MEAL_CATEGORIES,
  CO2_UNITS,
  COOKING_OPTIONS,
  MEAL_CATEGORIES,
  COUNTRY_CODE_OPTIONS,
} from "common/constants";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { cloneDeep } from "common/utils.ts";
import translations from '../../../data/ingredients_translations.json'
import SelectAsyncPaginate from "components/selectAsyncPaginate/index.tsx";
import AddIngredientModal from "./modal.js"

import UploadedIcon from "assets/images/uploaded_meal.png";
import Addicon from "assets/images/icon/add_purple.svg";
import TelechargerIcon from "assets/images/telecharger.png";

import JPEG_ICON from "assets/images/icon/jpeg.svg";
import CLOSE_ICON from "assets/images/icon/DELETE.svg";
import EYE_ICON from "assets/images/icon/eye.svg";
import Calque from "assets/images/Calque2.png"
import ImportIcon from "assets/images/icon/import-icon.svg";
import ExistingRecipeIcon from "assets/images/icon/existing-recipe-icon.svg";
import Mic from "assets/images/speaker.png"
import Calque_2 from "assets/images/Calque_2.png"
import INFO_ICON from "assets/images/info.png"
import NO_IMAGE_ICON from "assets/images/default-image-icon.png"

import { roundToTwo } from "common/utils";
import { useOnDropAccepted } from 'common/utils.ts';
import { getConvertibleUnitOptions, mealsKeys, requiredMealsKeys } from "../../settings/utils";
import { toast } from "react-toastify";
import dummyIngredients from '../../../data/carbon_ingredient.json'
import carbon_footprint_recipes from "../../../data/carbon_footprint_recipes.json"

import ProgressItem from "./progressItem";
import ImportModule from "../../settings/common/ImportIngredients";

import './index.scss'
import storage from "services/storage";
import SWITCH from "assets/images/switch.png";
const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

const INGREDIENT_INITIAL = {
  ingredient_unit: "g",
  ingredient_quantity: 1,
  ingredient_cost: 0,
  converted_unit: null,
  ingredient_id: {
    id: null,
    name: null,
    unit: null,
  },
  converted_quantity: null,
  name: ""
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const customStyles = {
  // This styles the dropdown itself
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    backgroundColor: "white",
    overflowX: "hidden",
  }),
  
  // This styles the portal (where the menu is rendered)
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999,  // You can adjust this if needed to ensure it's above other elements
    backgroundColor: "white",
    overflowX: "hidden",
  }),
};



function CustomModal({ onHide, show, state, onRowAdded, ...props }) {
  const { t, i18n } = useTranslation();
  const { setLoading, setError } = useLoading();
  const [ingredientModal, setIngredientModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [ingredients, setIngredients] = useState([])
  const [selectedIngredients, setSelectedIngredients] = useState([])
  const [showInfo, setShowInfo] = useState(false);
  const [showLocalInfo, setShowLocalInfo] = useState(false);
  const [importDocument, setImportDocument] = useState(false)
  const [lowerIngredients, setLowerIngredients] = useState([])
  const [alternativeIngredients, setAlternativeIngredients] = useState([])
  const [isAlternativeLoading, setIsAlternativeLoading] = useState(false)
  const [isAILoading, setIsAILoading] = useState(false)
  const [fileImportLoading, setFileImportLoading] = useState(false)
  const [isLoadAlternative, setIsLoadAlternative] = useState(true)
  const [isSwitched, setIsSwitched] = useState(false)
  const [nextLoadAlternative,setNextLoadAlternative] = useState(false)
  const [fd, setFd] = useState({
    allergens: [],
    ingredients: [],
    meals: [],
  });
  const [currentColor, setCurrentColor] = useState(getColorBasedOnCarbonEmission(getSum()?.toFixed(2)).color)
  const is_demo = JSON.parse(storage.getItem("is_demo"))
  const currentBrand = storage.getItem("brand");
  let sumOfCarbonValue = fd.ingredients?.reduce((previousValue, currentValue) => previousValue + (currentValue?.carbon_emission || 0), 0);
  const prevFdQuantity = useRef();
  const { selectedRestaurantId, hasRetaurants, isRestaurantLoaded, setSampleRestaurantModal } = useUserData();
  const imageUrl = `${process.env.PUBLIC_URL}/images/brands/${currentBrand}-${getColorBasedOnCarbonEmission(getSum()?.toFixed(2)).offset}.png`;
  const [offset, setOffset] = useState(null)
  const onDropAccepted = useOnDropAccepted(setFiles, setError, t);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedCookingState, setSelectedCookingState] = useState(null);
  let prevTotalWeight = useRef(null)
  const currentMonth = new Date().toLocaleString('en-US', { month: 'long' });
  const [cookingStates, setCookingStates] = useState([])

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: "image/jpg, image/jpeg, image/png, application/pdf",
    maxFiles: 1,
    onDropAccepted
  });

  useEffect(() => {
    setCurrentColor(getColorBasedOnCarbonEmission(getSum()?.toFixed(2)).color)
    switch(getColorBasedOnCarbonEmission(getSum()?.toFixed(2)).offset) {
      case 0:
        setOffset(10);
        break;
      case 25:
        setOffset(30);
        break;
      case 50:
        setOffset(50);
        break;
      case 75:
        setOffset(70);
        break;
      case 100:
        setOffset(90);
        break;
    }
  }, [imageUrl])

  // Toggle row function
  const handleRowClick = async(index) => {
    setSelectedRowIndex(selectedRowIndex === index ? null : index);
    setSelectedCookingState(COOKING_OPTIONS.find((option) => option.value === fd.ingredients[index].cooking_state)?.value ?? 'raw')
    let response = await request.get(`carbon-footprints/ingredients/get-cooking-methods`, {ingredient: fd.ingredients[index].ingredient_id.name}, true, false);
    setCookingStates(response.cooking_methods.length ? response.cooking_methods : ['raw'])
    
  };

  const findMaxCarbonObject = () => {
      let n = nextLoadAlternative ? (lowerIngredients?.length + 1) + 1 :  lowerIngredients?.length + 1;
      return fd?.ingredients?.reduce((maxObjects, current) => {
          if (maxObjects.length < n) {
              maxObjects.push(current);
              maxObjects.sort((a, b) => {
                  return (b.carbon_emission / b.ingredient_quantity) - (a.carbon_emission / a.ingredient_quantity);
              });
          } else {
              const minMaxObject = maxObjects[maxObjects.length - 1];
              if ((current.carbon_emission / current.ingredient_quantity) > (minMaxObject.carbon_emission / minMaxObject.ingredient_quantity)) {
                  maxObjects.pop();
                  maxObjects.push(current);
                  maxObjects.sort((a, b) => {
                      return (b.carbon_emission / b.ingredient_quantity) - (a.carbon_emission / a.ingredient_quantity);
                  });
              }
          }
          return maxObjects;
      }, []);
  }

  const getLowerIngredients = async () => {
    try {
      let maxCEObject = findMaxCarbonObject().slice(-1).pop();
      let requestIngredient = `${maxCEObject?.ingredient_id?.name}:${maxCEObject.carbon_emission / maxCEObject.ingredient_quantity}`
      if(maxCEObject) {
        setIsAlternativeLoading(true)
        let lowerIngredientResponse = await request.get("carbon-footprints/ingredients/get-recommended-alternatives", {ingredients: requestIngredient}, true, false);
          let newIngredient = lowerIngredientResponse?.alternatives?.find(i => (i?.original_ingredient === maxCEObject?.ingredient_id?.name))
          if(newIngredient) {
            setLowerIngredients((prev => ([...prev, newIngredient && {...newIngredient, alternative_co2_impact_per_kg: newIngredient?.alternative_co2_impact_per_kg * maxCEObject?.ingredient_quantity , ingredient_quantity: maxCEObject?.ingredient_quantity}])));
            setIsAlternativeLoading(false)
            setIsLoadAlternative(false)
          }else {
            toast.error(t(`No Alternative is found for `) + `${maxCEObject?.ingredient_id?.name}`);
            setIsAlternativeLoading(false)
            setIsLoadAlternative(true)
            setNextLoadAlternative(true)
          }
      }
    } catch (error) {
      toast.error(t(`No Alternative is found for `) + `${findMaxCarbonObject().slice(-1).pop()?.ingredient_id?.name}`);
      setIsAlternativeLoading(false)
      setIsLoadAlternative(true)
      setNextLoadAlternative(true)
    }
  }

  function getColorBasedOnCarbonEmission(carbonEmission) {
    let carbonValue = parseFloat(carbonEmission)/fd.servings
    
    if (carbonEmission) {
        if (((fd.type === 'starters' || fd.type === 'desserts') && carbonValue < 0.5) || (fd.type === 'mainDishes' && carbonValue < 1.5)) {
            // setIconColor('#65a700');
            return {color: "#65a700", offset: 0};
        }
        // if (carbonValue >= 0.5 && carbonValue < 1) {
        //     return {color: "#a4f500", offset: 25};
        // }
        if (((fd.type === 'starters' || fd.type === 'desserts') && (carbonValue >= 0.5 && carbonValue < 1)) || (fd.type === 'mainDishes' && carbonValue >= 1.5 && carbonValue < 2.5)) {
            // setIconColor('#ffc300');
            return {color: "#ffc300", offset: 50};
        }
        if (((fd.type === 'starters' || fd.type === 'desserts') && carbonValue > 1) || (fd.type === 'mainDishes' && carbonValue > 2.5)) {
            // setIconColor('#ff1d00');
            return {color: "#890500", offset: 100};
        }
        // if (carbonValue >= 3) {
        //     return {color: "#890500", offset: 100};
        // }
    }
    return {color: "#65a700", offset: 0}
  };

  function getSum() {
    let CO2Total = (fd.ingredients || []).reduce(
      (previousValue, currentValue) => {
        let ce_value = currentValue.carbon_emission || 0;
        // if(currentValue.converted_unit === 'g') {
        //     ce_value = ce_value * (parseFloat(currentValue?.ingredient_quantity)/1000)
        // }
        return previousValue + parseFloat(ce_value || 0)
      },
      0
    );
    return CO2Total
  };

  useEffect(async () => {
    if(selectedRestaurantId) {
    let payload = {
      limit: 999999999,
      page: 1,
    };
    let response = await request.get("carbon-footprints/ingredients", payload, true, false);
    setIngredients(response.cf_ingredients);
  }
  if(is_demo) {
    setIngredients(dummyIngredients.cf_ingredients)
  }
  }, [is_demo, selectedRestaurantId]);

  const handleCookingMethodologyChange = async (e, ingredient) => {
    const newMethodology = e.target.value;
    setSelectedCookingState(newMethodology);

      const newIngredientResponse = await request.get("carbon-footprints/ingredients", {
        search: ingredient.ingredient_id.name,
        cooking_state: newMethodology,
      });   
      
      // Check if cf_ingredients exists and has items
      if (!newIngredientResponse.cf_ingredients || newIngredientResponse.cf_ingredients.length === 0) {
        setError(t("No ingredients found with that name of cooking methadology."));
        return;
      }
      
      const updatedIngredient = {...newIngredientResponse.cf_ingredients[0], carbon_emission: newIngredientResponse.cf_ingredients[0].climate_change};

      const updatedIngredients = fd.ingredients.map((ing) => {
        if (ing.ingredient_id.id === ingredient.ingredient_id.id) {
          return { carbon_emission: updatedIngredient.carbon_emission,
            converted_quantity: updatedIngredient.format,
            converted_unit: updatedIngredient.unit,
            cooking_state: updatedIngredient.cooking_state,
            country: updatedIngredient.country,
            image_path: updatedIngredient.image_path,
            ingredient_cost: updatedIngredient.cost_excl_tax,
            ingredient_id: {
              id: updatedIngredient.id,
              name: updatedIngredient.name,
              unit: updatedIngredient.unit
            },
            ingredient_quantity: updatedIngredient.format,
            ingredient_unit: updatedIngredient.unit,
            is_local: false,
            nutritive_value: updatedIngredient.nutritive_value,
            seasonal_months: updatedIngredient.seasonal_months,
            source: updatedIngredient.source
           }; 
        }
        return ing; 
    });
      setFd({ ...fd, ingredients: updatedIngredients });
    };

  useEffect(() => {
    setFd({
      allergens: [],
      ingredients: [],
      meals: [],
    });
    setFiles([]);
    setIsSubmitDisabled(true);
    setIsUploaded(false);
  }, [show]);

  useEffect(() => {
    if (!state.selectedMeal) {
      setFd({ allergens: [], ingredients: [], servings: "", meals: [] });
      return;
    }
    const newSelectedMeal = cloneDeep(state.selectedMeal);
    prevTotalWeight.current = newSelectedMeal?.weight_in_kg
    prevFdQuantity.current = state.selectedMeal?.format ?? 0;
    let selIngredients = ingredients?.filter(a => state?.selectedMeal?.carbon_footprint_ingredients?.some(b => b.id === a.id)) || [];
    setSelectedIngredients((p) => [...p, ...selIngredients])
    const manipulatedIngredients = newSelectedMeal?.carbon_footprint_ingredients?.map(
      (ingredient) => {
        let ing_unit = ingredient?.CarbonFootprintIngredientMeal?.ingredient_unit
        return {
          ingredient_id: {
            id: ingredient.id,
            name:
              ingredient.name,
              unit: ing_unit === 'kg' || ing_unit === 'l' ? 'g' : ing_unit,
  
          },
          ingredient_cost: ingredient?.CarbonFootprintIngredientMeal?.ingredient_cost,
          ingredient_unit: ing_unit,
          is_local: Boolean(ingredient?.CarbonFootprintIngredientMeal?.ingredient_is_local),
          ingredient_quantity: ingredient.CarbonFootprintIngredientMeal.ingredient_quantity,
          source: ingredient.source,
          seasonal_months: ingredient.seasonal_months,
          country: ingredient.country,
          cooking_state: ingredient.cooking_state,
          nutritive_value: ingredient.nutritive_value,
          image_path: ingredient.image_path,
          carbon_emission: ingredient.CarbonFootprintIngredientMeal.ingredient_co2_impact,
          converted_unit: ingredient.CarbonFootprintIngredientMeal.converted_unit,
          ...(ingredient.CarbonFootprintIngredientMeal.converted_quantity === null && {
            converted_quantity: ingredient.CarbonFootprintIngredientMeal.ingredient_quantity,
          }),
          ...(ingredient.CarbonFootprintIngredientMeal.converted_unit === null && {
            converted_unit: ingredient.CarbonFootprintIngredientMeal.ingredient_unit,
          }),
        };
      }
    );
    setFd({
      ...fd,
      cost: newSelectedMeal.cost_per_person,
      ...newSelectedMeal,
      ...(newSelectedMeal.carbon_footprint_ingredients && {
        ingredients: manipulatedIngredients,
      }),
      allergens: state.selectedMeal.allergens.map((a) => a.id),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedMeal]);
  useEffect(() => {
    if (fd.name) {
      setIsSubmitDisabled(false);
      return;
    }
    setIsSubmitDisabled(true);
  }, [fd]);

  let dummyDatasource = useMemo(
    () =>
      dummyIngredients.cf_ingredients.map((m) => ({
        value: m.name,
        label: m.name,
        name: m.name,
        item_id: m.id,
      })),
    []
  );

  const onAllergenClick =
    ({ id }) =>
    () => {
      let newAllergens = cloneDeep(fd.allergens);
      if (newAllergens.includes(id)) {
        newAllergens = newAllergens.filter((allergenId) => allergenId !== id);
      } else {
        newAllergens = [...newAllergens, id];
      }
      setFd({
        ...fd,
        allergens: newAllergens,
      });
    };

  const onSelectionChange =
    (type) => {
      return ({ id }) => setFd({ ...fd, [type]: id })
    };

  const onTextChange = ({ target: { name, value, type } }) => {
    let newValue = value;
    if (name === 'selling_price_per_person') {
      newValue = formatInput(value)
    }
    setFd({ ...fd, [name]: newValue });
  };

  const importModalCloseHandler = (value) => {
    setImportDocument(value);
    if(isRestaurantLoaded && !hasRetaurants) {
      let file = storage.getItem("uploaded_file")
      let meal;
      if(file) {
        switch(file) {
          case 'pdf':
            meal = carbon_footprint_recipes.cf_meals.find((r) => r.item_id === '0f13bc7e-957c-45fc-b7b2-52b59b4220a5');
            break;
          case 'jpg':
            meal = carbon_footprint_recipes.cf_meals.find((r) => r.item_id === '0727989d-d0b2-4899-bfcf-0bd6f4498534');
            break;
          case 'docx':
            meal = carbon_footprint_recipes.cf_meals.find((r) => r.item_id === '1d2eb03a-de78-4c88-bd0b-6994fd0dea08');
            break;
          case 'xlsx':
            meal = carbon_footprint_recipes.cf_meals.find((r) => r.item_id === 'a5c7aad0-5709-484f-b16b-71104936c5e5');
            break;
          default:
            meal = {};
      }
      storage.removeItem("uploaded_file")
    }
      let newIngredients = meal?.carbon_footprint_ingredients?.map(item => {
        return {
          carbon_emission: item.climate_change,
          converted_unit: null,
          ingredient_cost: null,
          ingredient_unit: item?.CarbonFootprintIngredientMeal?.ingredient_unit,
          ingredient_id: {
            id: item.id,
            name: item.name,
            unit: "kg",
          },
          ingredient_quantity: item?.CarbonFootprintIngredientMeal?.ingredient_quantity,
          is_local: true
        }
      })
      setFd({...meal, ingredients: newIngredients});
    }
    
}

  const onSubmit = async (ev) => {
    ev.preventDefault();

    if (isRestaurantLoaded && !hasRetaurants) {
      setIsUploaded(true);
      return;
    }

    if (selectedRestaurantId === "") {
      setSampleRestaurantModal(true);
      return;
    }
    try {
      setIsSubmitDisabled(true);
      setLoading(true);
      const { cost, selling_price_per_person, ...rest } = fd;
      let fdIngredients =
        fd.ingredients
          ?.map((i) => {
          return  {
            ingredient_id: i.ingredient_id?.id,
            ingredient_unit: i.ingredient_unit,
            ingredient_quantity: i.ingredient_quantity || i.converted_quantity,
            ingredient_cost: i.ingredient_cost,
            ingredient_co2_impact: i?.carbon_emission,
            ingredient_is_local: i?.is_local,
            converted_unit: null,
            converted_quantity: null,
          }}
          )
          .filter((i) => i.ingredient_id) || null;
      let ingredients = [...fdIngredients];

      const format =
        fd?.type === "intermediate-recipe" ? parseFloat(fd.format) : undefined;

      if(!fd?.servings) {
        toast.error(t("Please enter servings"))
      }


        const total_weight_in_kg = fd?.ingredients?.reduce((a, b) => {
          return a + (b?.converted_unit === "g" ? b?.converted_quantity / 1000  : b?.converted_quantity)
        }, 0)
      const payload = {
        ...omit(rest, ["value", "label", "meals", "ingredients", "restaurant"]),
        carbon_footprint_ingredients: alternativeIngredients.length ? alternativeIngredients : ingredients,
        restaurant_id: selectedRestaurantId,
        cost_per_person: cost,
        category: fd.category,
        price_per_person: +fd.selling_price_per_person,
        co2_impact_in_kg: fd?.ingredients?.reduce((a, b) => a + b?.carbon_emission, 0),
        weight_in_kg: parseFloat(total_weight_in_kg),
        total_price: +parseFloat(
          fd.selling_price_per_person * fd.servings
        ).toFixed(2),
        is_weight_alert: total_weight_in_kg !== prevTotalWeight.current ? (total_weight_in_kg / fd?.servings) > 1.5 ?  true : false : fd.is_weight_alert,
        is_seasonal_alert: fd.is_seasonal_alert === false ? false : (fd.ingredients?.map(ingredient => {
          if(ingredient?.seasonal_months === null) {
            return false
          }
         return ingredient.seasonal_months?.some((month => month?.toLowerCase() === currentMonth.toLowerCase()))
        }
        )).some(item => item === false) ? true : null,
        margin_per_person: +(selling_price_per_person - cost) || null,
        total_margin: +parseFloat(
          fd.selling_price_per_person * fd.servings - cost * fd.servings
        ).toFixed(2),
        format,
        unit: fd?.unit,
      };

      if (!state.selectedMeal) {
        const result = await request.post("/carbon-footprint-meals", payload);
        if ([200, 201].includes(result.status)) {
          fileUpload(await result.json());
          setLoading(false)
          setAlternativeIngredients([])
          setLowerIngredients([])
          setIsLoadAlternative(true)
          setIsSwitched(false)
          setSelectedRowIndex(null)
          return;
        }
        throw Error(result);
      }
      delete payload.id;
      delete payload.provider_name;
      delete payload.image_path;
      delete payload.createdAt;
      delete payload.updatedAt;
      delete payload.item_id;
      delete payload.is_external;

      const resultQuery = await request.patch(
        `/carbon-footprint-meals/${state.selectedMeal.id}`,
        payload
      );

      if ([200].includes(resultQuery.status)) {
        fileUpload(await resultQuery.json(), false);
        setAlternativeIngredients([])
        setLowerIngredients([])
        setIsLoadAlternative(true)
        setIsSwitched(false)
        setSelectedRowIndex(null)
        return;
      }
      const err = await resultQuery.json()
      setLoading(false);
      setSelectedRowIndex(null)
      toast.error(err.msg)

      // throw Error(resultQuery);
    } catch (error) {
      setIsSubmitDisabled(false);
    }
  };

  const fileUpload = async (meal, create = true) => {
    if (!acceptedFiles.length) {
      onRowAdded(meal, create);
      setFd({ ...fd, allergens: [] });
      setLoading(false);
      setIsSubmitDisabled(false);
      setIsUploaded(true);
      return;
    }
    try {
      let formData = new FormData();
      formData.append("image", acceptedFiles[0]);
      await request.patchFile(`/carbon-footprint-meals/upload-image/${meal.id}`, formData);

      setIsUploaded(true);
      onRowAdded(meal, create);
      setLoading(false);
      setFd({ ...fd, allergens: [] });
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    } finally {
      setIsSubmitDisabled(false);
    }
  };

  const onRemoveFile = (file, i) => () => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const handleChange = (selectedOption, i) => {
    let newIngredients = cloneDeep(fd.ingredients);
    const ingredient = newIngredients[i];

    ingredient['country'] = selectedOption.value;
    newIngredients[i] = ingredient
    setFd({ ...fd, ingredients: newIngredients });  
  }; 

  const onAddIngredientItem = (ev) => {
    setFd({
      ...fd,
      ingredients: [
        ...(fd.ingredients || []),
        {
          ...INGREDIENT_INITIAL,
          ...(ev.id && {
            ingredient_id: {
              id: ev.id,
              name: ev.name,
              unit_price: ev.unit_price,
              unit: ev.unit,
              carbon_emission: ev.carbon_emission,
              seasonal_months: ev.seasonal_months
            },
          }),
        },
      ],
      // carbon_footprint_ingredients: [
      //   ...(fd.carbon_footprint_ingredients || []),
      //   {
      //     ...INGREDIENT_INITIAL_2,
      //     ...(ev.id && {
      //       CarbonFootprintIngredientMeal : {
      //         ingredient_unit: "g",
      //         ingredient_quantity: 1,
      //         ingredient_cost: 0,
      //         converted_unit: null,
      //         converted_quantity: null,
      //         ingredient_co2_impact: ev.carbon_emission,
      //         ingredient_is_local: ev.ingredient_is_local,
      //       },
      //       category: ev.category,
      //       climate_change: ev.carbon_emission,
      //       country: ev.country,
      //       format: 1,
      //       id: ev.id,
      //       image_path: ev?.image_path || null,
      //       name: ev.name,
      //       nutritional_value: ev?.nutritional_value || null,
      //       unit: ev.unit,
      //       seasonal_months: ev?.seasonal_months || null
      //     })
      //   },
      // ]
    });
  };

  const countries = COUNTRY_CODE_OPTIONS.map(country => ({
    value: country.value,
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <WorldFlag code={country.id} style={{ width: '20px', marginRight: '10px' }} />
        {country.label}
      </div>
    )
  }));

  // Custom Option component
const CountryOption = (props) => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <span style={{ marginRight: 10 }}>
        <WorldFlag code={data.id} style={{ width: '20px', marginRight: '10px' }} />
      </span>
      {data.label}
    </components.Option>
  );
};

const getCountry = (country) => {
  return COUNTRY_CODE_OPTIONS.find(c => c.value === country)
}

// Custom Single Value component
const CountrySingleValue = (props) => {
  const { data } = props;
  return (
    <components.SingleValue {...props}>
      <span style={{ marginRight: 10 }}>
        <WorldFlag code={data.id} style={{ width: '20px', marginRight: '10px' }} />
      </span>
      {data.label}
    </components.SingleValue>
  );
};

  const updateRecipe = async(i) => {
    if(is_demo) {
      toast.success(t("Recipe updated successfully"))
      setLowerIngredients(lowerIngredients.map(item => ({...item, alternative_ingredient: item.original_ingredient, original_ingredient: item.alternative_ingredient})))
      return
    }

    if(lowerIngredients.length) {
      const updatedIngredients = fd.ingredients.map(ing => {
        if (lowerIngredients[i]?.original_ingredient === ing?.ingredient_id?.name) {
          return { 
            ingredient_id: lowerIngredients[i].alternative_id,
            ingredient_unit: ing?.ingredient_unit,
            ingredient_quantity: ing?.ingredient_quantity,
            ingredient_cost: null,
            ingredient_co2_impact: lowerIngredients[i].alternative_co2_impact_per_kg,
            ingredient_is_local: false,
            converted_unit: null,
            converted_quantity: null
          }
        }
        return {
          ingredient_id: ing?.ingredient_id?.id,
          ingredient_unit: ing?.ingredient_unit,
          ingredient_quantity: ing?.ingredient_quantity,
          ingredient_cost: null,
          ingredient_co2_impact: ing?.carbon_emission,
          ingredient_is_local: ing?.is_local,
          converted_unit: null,
          converted_quantity: null
        }
      })
      if(updatedIngredients?.length) {
          const matchedItemIndex = fd.ingredients.findIndex(ing => ing.ingredient_id?.name === lowerIngredients[i].original_ingredient);
          if(matchedItemIndex === -1) {
            return
          }
          setIsAlternativeLoading(true)
          setAlternativeIngredients(updatedIngredients)
          let fdIngredients = cloneDeep(fd.ingredients);
          setLowerIngredients(lowerIngredients.map((item, index) => {
            if(index == i) {
              return {...item, alternative_ingredient: item.original_ingredient, original_ingredient: item.alternative_ingredient}
            }
            return item
          }))
          const ingredient = { 
            carbon_emission: lowerIngredients[i].alternative_co2_impact_per_kg,
            converted_quantity: null,
            converted_unit: null,
            ingredient_cost: null,
            ingredient_id: {
                    id: lowerIngredients[i].alternative_id,
                    name: lowerIngredients[i].alternative_ingredient,
                    unit: fd.ingredients[matchedItemIndex].ingredient_unit
            },
            ingredient_quantity: fd.ingredients[matchedItemIndex].ingredient_quantity,
            ingredient_unit: fd.ingredients[matchedItemIndex].ingredient_unit,
            ingredient_is_local: false,
          }
  
          fdIngredients[matchedItemIndex] = ingredient
            // setAlternativesIngredients([])
            setFd({ ...fd, ingredients: fdIngredients })
            setIsAlternativeLoading(false)
      }
    }
  }

  const calculateCost = (ingredient) => {
    const { unit_price, unit } = ingredient?.ingredient_id || {};
    const {
      ingredient_unit,
      ingredient_quantity,
      converted_unit,
      converted_quantity,
    } = ingredient;
    if (["g", "kg", "mg", "lb", "l", "ml", "m", "mm"].includes(ingredient_unit)) {
      try {
        const convertQuantity = converted_quantity ?? ingredient_quantity;
        const convertFrom = converted_unit ?? ingredient_unit;
        const convertTo = unit ?? converted_unit ?? ingredient_unit;
        const convertedValue =
          convert(convertQuantity, convertFrom).to(convertTo) || 0;

        ingredient.ingredient_cost = (parseFloat(unit_price * convertedValue)).toFixed(2);
        ingredient.ingredient_quantity = roundToTwo(convertedValue);
        return ingredient
      } catch (error) {
        ingredient.ingredient_cost = roundToTwo(
          ingredient_quantity * unit_price || 0
        );
        return ingredient
      }
    } else {
      ingredient.ingredient_cost = roundToTwo(
        ingredient_quantity * unit_price || 0
      );
      return ingredient
    }
  };

  const formatInput = (input) => {
    // Allow only digits, dots, and commas in the input
    const sanitizedInput = input.replace(/[^0-9.,]/g, '');

    // Replace commas with dots, allowing both as decimal points
    let normalizedInput = sanitizedInput.replace(',', '.');

    // Check if there's already a dot present, and if so, remove any additional dots
    const parts = normalizedInput.split('.');

    if (parts.length > 2) {
        // If there are multiple dots, keep the first part and join the rest without additional dots
        normalizedInput = parts[0] + '.' + parts.slice(1).join('');
    }

    return normalizedInput;
};

  const onIngredientTextBoxChange =
    (i) =>
   async ({ target: { name, value: val } }) => {
      let value = val
      if(name = 'ingredient_quantity') {
        value = formatInput(val)
      }
      // let value = +val.replace(/[^\d.]/, "");

      // if (!(+value !== 0) && val !== "") {
      //   return;
      // }
      if (val === "") {
        value = "";
      }

      if (name === "meal_quantity") {
        // prevFdQuantity.current =
        //   fd.meals[i]?.format > 0 ? fd.meals[i]?.format : 1;
        let updatedIngredients = fd.meals[i].ingredients.map((item) => {
          // let  providerIngredient = ingredients.find(ing => ing.id === item.id)
          // let ratio =
          //   value === ""
          //     ? 0 / prevFdQuantity.current
          //     : +value / prevFdQuantity.current;
          return {
            ...item,
            CarbonFootprintIngredientMeal: {
              ...item.CarbonFootprintIngredientMeal,
              ingredient_cost: parseFloat(item.cost_excl_tax * (value * item.CarbonFootprintIngredientMeal.ingredient_quantity))
            },
          };
        });
        prevFdQuantity.current = parseFloat(value);
        fd.meals[i].format = value;
        fd.meals[i].ingredients = updatedIngredients;
        return setFd({ ...fd });
      }
      let newIngredients = cloneDeep(fd.ingredients);
      const ingredient = newIngredients[i];
      ingredient[name] = value;
      const newIngredient = await request.get(`carbon-footprints/ingredients/${ingredient.ingredient_id.id}`)

      if (name === "ingredient_quantity") {
        let carbon_unit_value = ingredient.ingredient_unit === 'g' ? newIngredient.climate_change / 1000 : newIngredient.climate_change
        ingredient["converted_quantity"] = value;
        ingredient["ingredient_quantity"] = value;
        ingredient["carbon_emission"] = (parseFloat(value) *  carbon_unit_value);
        ingredient["ingredient_cost"] =  (parseFloat(value * newIngredient?.cost_excl_tax));
      }

      // const updatedIngredient = calculateCost({...ingredient});
      // newIngredients = newIngredients.map(i => {
      //       if (i.ingredient_id.id === updatedIngredient.ingredient_id.id) {
      //        return {...updatedIngredient}
      //       }
      //       return i
      //     })
      newIngredients[i] = ingredient
      setFd({ ...fd, ingredients: newIngredients });
    };

  const onIngredientSelectBoxChange =
    (i) =>
     (type) =>
    async ({ id, unit_price, name, french, ingredient_unit,carbon_emission, unit, ingredient_id }) => {
      let updatedType = type === "ingredient_id" ? "id" : type
      let newIngredients = cloneDeep(ingredients);
      let fdIngredients = cloneDeep(fd.ingredients);
      let fdCarbonIngredients = cloneDeep(fd.carbon_footprint_ingredients);
      let ingredient = fdIngredients[i]
      if(type === "ingredient_id") {
        if(isRestaurantLoaded && !hasRetaurants) {
            let ingredient = ingredients.find(item => item.id === id)
            ingredient["ingredient_id"] = { id: ingredient.id, name: ingredient.name, unit: ingredient.unit };
            ingredient["ingredient_unit"] = ingredient.unit;
            ingredient["converted_unit"] = ingredient.unit;
            ingredient["carbon_emission"] = ingredient.climate_change;
            ingredient["ingredient_quantity"] = ingredient?.format || 1;
            fdIngredients[i] = ingredient
            setFd({ ...fd, ingredients: fdIngredients });
            return
        }else {
          if(!ingredient_id?.id && !french) {
            const createdIngredient = await request.post("/carbon-footprints/ingredients", { 
              name: ingredient_id?.name?.trim(),
              climate_change: carbon_emission,
              source: "CHATGPT"
            });
            const res = await createdIngredient.json();
            let updatedIngredient = {
              carbon_emission,
              ingredient_quantity: 1000,
              ingredient_unit: "g",
              converted_unit: "g",
              source: "CHATGPT",
              ingredient_id: {
                id: res.id,  
                name: res.name,
                unit: "g",
              }
            }
      
            fdIngredients[i] = updatedIngredient
            fdCarbonIngredients[i] = {
              CarbonFootprintIngredientMeal : {
                ingredient_unit: "g",
                ingredient_quantity: 1000,
                ingredient_cost: 0,
                converted_unit: null,
                converted_quantity: null,
                ingredient_co2_impact: carbon_emission,
                ingredient_is_local: false,
              },
              category: null,
              climate_change: carbon_emission,
              country: null,
              format: 1,
              id: res.id,
              image_path: null,
              name: null,
              nutritional_value: null,
              unit: null,
              seasonal_months: null
            };
            setFd({ ...fd, ingredients: fdIngredients, carbon_footprint_ingredients: fdCarbonIngredients });
            return
          }else if(!ingredient_id?.id && french) {
            const searchInput = translations[name?.trim()]
            if(!searchInput) {
              toast.warn(t("Ingredient is not available"));
              return
            }
            let response = await request.get("carbon-footprints/ingredients", { search: translations[name?.trim()] }, true, false);
            let foundIngredient = response.cf_ingredients.find(item => item.name === translations[name?.trim()])
            if(!foundIngredient) {
              foundIngredient = response.cf_ingredients[0]
            }
            let ingredient1 = {
              carbon_emission: foundIngredient?.climate_change,
              ingredient_quantity: 1000,
              image_path: foundIngredient?.image_path,
              nutritive_value: foundIngredient?.nutritive_value,
              seasonal_months: foundIngredient?.seasonal_months,
              cooking_state: foundIngredient?.cooking_state,
              country: foundIngredient?.country,
              ingredient_unit: foundIngredient.unit === 'kg' || foundIngredient.unit === 'l' ? "g" : foundIngredient.unit,
              converted_unit: foundIngredient.unit === 'kg' || foundIngredient.unit === 'l' ? "g" : foundIngredient.unit,
              source: foundIngredient.source,
              ingredient_id: {
                id: foundIngredient.id,  
                name: foundIngredient.name,
                unit: foundIngredient.unit === 'kg' || foundIngredient.unit === 'l' ? "g" : foundIngredient.unit,
              }
            }
            fdIngredients[i] = ingredient1
            setFd({ ...fd, ingredients: fdIngredients });
            return
          }else {
            ingredient = newIngredients.find(i => i.id === id);
            ingredient = {...ingredient, is_local: false}
            let obj = ingredient && {
              CarbonFootprintIngredientMeal : {
                ingredient_unit: ingredient.unit,
                ingredient_quantity: ingredient.format,
                ingredient_cost: ingredient.cost_excl_tax,
                converted_unit: ingredient.converted_unit,
                converted_quantity: ingredient.converted_quantity,
                ingredient_co2_impact: ingredient.climate_change,
                ingredient_is_local: false,
              },
              category: ingredient.category,
              climate_change: ingredient.climate_change,
              country: ingredient.country,
              format: ingredient.format,
              id: ingredient.id,
              image_path: ingredient.image_path,
              name: ingredient.name,
              nutritional_value: ingredient.nutritive_value,
              unit: ingredient.unit,
              seasonal_months: ingredient.seasonal_months
            }
            fdCarbonIngredients[i] = obj
          }
        }
        
        // ingredient["ingredient_id"] = { ...ingredient.ingredient_id, unit: ingredient_unit };
        // ingredient["ingredient_unit"] = ingredient_unit;
        // ingredient["converted_unit"] = ingredient_unit;
        // fdIngredients[i] = ingredient
        // setFd({ ...fd, ingredients: fdIngredients });
        // return
      }
    

      setSelectedIngredients((prev) => [
        ...prev,
        ingredients.find((i) => i.id === id),
      ]);

      if (ingredient && updatedType !== "ingredient_unit") {
        ingredient[updatedType] = id;
      }
      if (updatedType === "ingredient_unit") {
        ingredient["converted_unit"] = id;
        ingredient["ingredient_unit"] = id;
        ingredient.ingredient_id.unit = id;
        if((fd.ingredients[i].ingredient_unit === 'kg' || fd.ingredients[i].ingredient_unit === 'l') && id === 'g') {
          ingredient["ingredient_quantity"] = ingredient['ingredient_quantity'] * 1000
          ingredient["converted_quantity"] = ingredient['converted_quantity'] * 1000
        }else if (fd.ingredients[i].ingredient_unit === 'g' && (id === 'kg' || id === "l")) {
          ingredient["ingredient_quantity"] = ingredient['ingredient_quantity'] / 1000
          ingredient["converted_quantity"] = ingredient['converted_quantity'] / 1000
        }
      }

      if (updatedType === "id") {
        ingredient["ingredient_id"] = ingredient_id;
        ingredient["ingredient_unit"] = ingredient_id.unit === 'kg' || ingredient_id.unit === 'l' ? 'g' : ingredient_id.unit;
        ingredient["converted_unit"] = ingredient_id.unit === 'kg' || ingredient_id.unit === 'l' ? 'g' : ingredient_id.unit;
        ingredient["ingredient_quantity"] = 1000;
        ingredient["converted_quantity"] = 1000;
        ingredient["ingredient_cost"] = ingredient['cost_excl_tax']
        ingredient["carbon_emission"] = ingredient['climate_change']
      }

      fdIngredients[i] = ingredient
      setFd({ ...fd, ingredients: fdIngredients, carbon_footprint_ingredients: fdCarbonIngredients });
    };

  const deleteIngredient = (index, type) => () => {
    if (type === "meal") {
      fd.meals.splice(index, 1);
      return setFd({ ...fd });
    }

    const newIngredients = cloneDeep(fd.ingredients);
    newIngredients.splice(index, 1);
    // setSelectedIngredients((prev) => prev.filter((item) => item.id !== ingredient.ingredient_id.id));
    setFd({ ...fd, ingredients: newIngredients });
  };

  const deleteLowerIngredient = (index, type) => () => {
    if (type === "meal") {
      fd.meals.splice(index, 1);
      return setFd({ ...fd });
    }

    const newLowerIngredients = cloneDeep(lowerIngredients);
    newLowerIngredients.splice(index, 1);
  };

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="..." />
      </div>
    </div>
  ));



  const loadOptions = async (search) => {
    if (!search || search.length < 3 || search === "") {
      return { options: [], hasMore: false };
    }

    try {
      const response = await request.get(
        "meals",
        { search, restaurant_id: selectedRestaurantId },
        true,
        false
      );

      return {
        options: response.meals.map((i) => ({
          ...i,
          value: i.name,
          label: i.name,
        })),
        hasMore: false,
      };
    } catch (error) {
      return { options: [], hasMore: false };
    }
  };

  const createSelectChange = (obj, { action }) => {
    if (action === "select-option") {
      props.setSelectedMeal(obj);
    }
    if (action === "clear") {
      props.setSelectedMeal(null);
    }
    if (action === "create-option") {
      setFd({ ...fd, name: obj.value });
    }
  };

  const getCO2Value = (Co2Obj) => {
    return Co2Obj.climate_change * ((Co2Obj.CarbonFootprintIngredientMeal.ingredient_unit === 'kg' || Co2Obj.CarbonFootprintIngredientMeal.ingredient_unit === 'l') ? Co2Obj.CarbonFootprintIngredientMeal.ingredient_quantity : Co2Obj.CarbonFootprintIngredientMeal.ingredient_quantity / 1000)
  }

  const onLocalCheckboxChange = (i) => {
    setFd({ ...fd, ingredients: fd.ingredients.map((ing, index) => index === i ? { ...ing, is_local: !ing.is_local, carbon_emission: ing.is_local == false ? parseFloat(0.95 * ing.carbon_emission) : getCO2Value(fd.carbon_footprint_ingredients[i]) } : ing) })
  }

  const fetchWithTimeout = async (url, options = {}, timeout = 30000) => {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
  
    try {
      const response = await fetch(url, { ...options, signal: controller.signal });
      clearTimeout(id);
      return response;
    } catch (error) {
      if (error.name === 'AbortError') {
        console.warn("Fetch request timed out");
      }
      throw error;
    }
  };

  const setRecipe = (randomRecipe) => {
    setFd({
      ...fd,
      ingredients: randomRecipe.recipe.map(item => ({
        carbon_emission: item?.co2_impact_per_kg * item?.weight_in_kg,
        converted_quantity: item?.weight_in_kg || 1,
        converted_unit: item?.unit,
        ingredient_cost: item?.cost_excl_tax,
        seasonal_months: item?.seasonal_months,
        nutritive_value: item?.nutritive_value,
        image_path: item?.image_path,
        country: item?.country,
        cooking_state: item?.cooking_state,
        is_recipe: item.is_recipe,
        ingredient_id: {
          id: item?.id,
          name: item?.name,
          unit: item?.unit
        },
        ingredient_unit: item?.unit,
        is_local: false
      }))
    });
  }
  
  const getRandomRecipe = async () => {
    if (hasRetaurants && !isRestaurantLoaded) {
      const randomIndex = Math.floor(Math.random() * 16);
      const randomRecipe = carbon_footprint_recipes.cf_meals[randomIndex];
      let newIngredients = randomRecipe.carbon_footprint_ingredients.map(item => ({
        carbon_emission: item.carbon_value,
        converted_unit: null,
        ingredient_cost: null,
        ingredient_id: {
          id: item.id,
          name: item.name,
          unit: "kg",
        },
        ingredient_quantity: item?.CarbonFootprintIngredientMeal?.ingredient_quantity,
        is_local: true
      }));
      setFd({ ...randomRecipe, ingredients: newIngredients });
      return;
    }
  
    if (!fd.name) {
      toast.error(t("PleaseEnterMealName"));
      return;
    }
  
    setIsAILoading(true);
  
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${storage.getItem('token')}`
      }
    };
    
    try {
      // First Attempt
      let response = await fetchWithTimeout(
        `${process.env.REACT_APP_API_ENDPOINT}/carbon-footprints/meals/get-recommended-recipe/?meal=${fd?.name}&category=${CARBON_MEAL_CATEGORIES.find(i => i.id === fd.category)?.label}`,
        requestOptions
      );
      
      if (!response.ok) throw new Error("Recipe not found");
      
      const randomRecipe = await response.json();
      setRecipe(randomRecipe)
    } catch (error) {
      // Retry Attempt
      try {
        let response = await fetchWithTimeout(
          `${process.env.REACT_APP_API_ENDPOINT}/carbon-footprints/meals/get-recommended-recipe/?meal=${fd?.name}&category=${CARBON_MEAL_CATEGORIES.find(i => i.id === fd.category)?.label}`,
          requestOptions
        );
  
        if (!response.ok) throw new Error("Recipe not found");
  
        const randomRecipe = await response.json();
        setRecipe(randomRecipe)
      } catch (retryError) {
        console.error("Second attempt failed: ", retryError);
        toast.error(t("This recipe is not available. Please try another one."));
      }
    } finally {
      setIsAILoading(false);
    }
  };

  const renderTooltip = (props) => {
    return(
    <Tooltip id="button-tooltip" {...props}>
      {t("Please enter the name of the dish to use iA")}
    </Tooltip>
  )};

  return (
    <>
      <AddIngredientModal
        show={ingredientModal}
        onHide={() => {
          setIngredientModal(false);
          setIsLoadAlternative(true)
          setIsAlternativeLoading(false)
        }}
        data={{ allergens: state.allergens, providers: state.providers }}
        onRowAdded={(ingredientItem, a) => {
          onAddIngredientItem(ingredientItem);
        }}
        isUploadedModal={false}
      />
      {importDocument && <ImportModule title="Extract recipe from a document" setFd={setFd} setFileImportLoading={setFileImportLoading} setIsAILoading={setIsAILoading} modalName='Meal' requestPath='/meals/create-in-bulk' show={importDocument} options={mealsKeys} requiredOptions={requiredMealsKeys} onHide={importModalCloseHandler} providers={state.providers} onRowAdded={onRowAdded} />}
      <Modal
        show={show}
        onHide={() => {
          setIsLoadAlternative(true)
          setIsAlternativeLoading(false)
          setLowerIngredients([])
          setAlternativeIngredients([])
          setNextLoadAlternative(false)
          setIsAILoading(false);
          setSelectedRowIndex(null)
          onHide()
        }}                       
        centered
        className="add-meals dashboard-modal"
        dialogClassName="custom-width"
        style={{ padding: "40px" }}
      >
        <Modal.Header closeButton>
          {!isUploaded && (
            <Modal.Title>
              {state.selectedMeal ? t("Edit") : t("Add")} {t("Meal")}(s)
            </Modal.Title>
          )}
        </Modal.Header>

        <Modal.Body>
          <Container className="p-0" style={{ margin: "0px", minWidth: "100%" }}>
            {isUploaded && (
              <div className="d-flex justify-content-center flex-column  text-center upload-container">
                <div>
                  <img src={UploadedIcon} alt="..." />
                </div>
                <div className="heading mt-5">
                  <label>Meal uploaded !</label>
                </div>
                <div className="subheading mt-2 mb-5">
                  <label>Your meal has been successfully uploaded.</label>
                </div>
                <div>
                  <Button
                    className="add-restaurant-confirm-btn"
                    onClick={onHide}
                  >
                    OK
                  </Button>
                </div>
              </div>
            )}
            {!isUploaded && (
              <>
                <div style={{
                  display:"flex",
                  alignItems:"center",
                  marginBottom: "40px",
                  justifyContent: "space-between",
                }}>
                  {fd?.ingredients?.length > 0 && (
                    <div>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '12px',
                        marginRight: '10px',
                        flexShrink: '0'
                      }}>
                        {/* <div>
                          <img src={Mic} style={{ background: "#6353EA", cursor: "pointer", borderRadius: "5px", width: "28px", height: "26px", marginRight: "15px", objectFit: "contain" }} onClick={getRandomRecipe} />
                          <span className="add-restaurants-input-title">
                            {t("Recipes generation by voice")}
                          </span>
                        </div> */}
                        <div style={{ position: 'relative' }}>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <img src={Calque} style={{ background: "#6353EA", cursor: "pointer", width: "28px", borderRadius: "5px", height: "26px", marginRight: "15px", objectFit: "contain" }} onClick={getRandomRecipe} />
                            <span className="add-restaurants-input-title">
                              {t("Generate a recipe with AI")}
                            </span>
                            <div
                              onMouseEnter={() => setShowInfo(true)} onMouseLeave={() => setShowInfo(false)}
                              style={{
                                cursor: "pointer",
                                width: '38px',
                                height: '38px',
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "rgb(243, 244, 251)",
                                borderRadius: "50%",
                                zIndex: '1'
                              }}
                            ><img src={INFO_ICON} style={{ width: "25px" }} /></div>
                          </div>
                          {showInfo && (
                            <div style={{
                              position: 'absolute',
                              left: '205px',
                              bottom: '-4px',
                            }}>
                              <div style={{
                                display: "flex",
                                alignItems: "flex-start",
                                background: "white",
                                borderRadius: "9px",
                                padding: "20px",
                                width: "425px",
                                height: "96px",
                              }}>
                                <img src={Calque_2} style={{ marginRight: "15px" }} />
                                <p style={{ marginBottom: '0px', fontSize: '12px' }}>{t("To save you time, our powered technology by iA will allow you to generatively pre-populate recipes. You can tweak them later!")}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {fd?.ingredients?.length > 0 && (
                    <div>
                      <Button
                        variant="primary add-btn-container"
                        onClick={() => setImportDocument(true)}
                        style={{ borderRadius: '5px' }}
                      >
                        {t("Import from a document")}
                      </Button>
                    </div>
                  )}

                  <div style={{ marginLeft: '20px', width: '40%' }}>
                    <p className="add-restaurants-label-title" >
                      {t("Label of Meal based on carbon emission")}
                    </p>

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <div className="label-container" style={{ width: '100%', height: '25px', display: 'flex', position: "relative", backgroundColor: '#f2f2f2', borderRadius: '10px' }}>
                        <img style={{ marginRight: "10px", width: "11%", position: "absolute", left: `${offset}%`, top: "-35px", transform: 'translate(-50%, 0%)' }} src={imageUrl} />
                        <span style={{ width: "20%", height: "100%", backgroundColor: offset === 10 ? "transparent" :"#65a700", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px"}}></span>
                        <span style={{ width: "20%", height: "100%", backgroundColor: offset === 30 ? "transparent" : "#a4f500"}}></span>
                        <span style={{ width: "20%", height: "100%", backgroundColor: offset === 50 ? "transparent" : "#ffc300"}}></span>
                        <span style={{ width: "20%", height: "100%", backgroundColor: offset === 70 ? "transparent" : "#ff1d00"}}></span>
                        <span style={{ width: "20%", height: "100%", backgroundColor: offset === 90 ? "transparent" : "#890500", borderBottomRightRadius: "10px", borderTopRightRadius: "10px"}}></span>
                      </div>
                    </div>
                  </div>
                </div>

                <Form className="ps-0 pe-0" noValidate onSubmit={onSubmit}>
                  {/* Meal name, Type, Servings and Selling filds */}
                  <Row className=" d-flex justify-content-between">
                    <Col lg={12}>
                      <Row>
                        <Col lg={3}>
                        <span className="add-restaurants-input-title ps-0">
                          {t("MealName")} 
                          <span style={{ color: "red", fontSize: "14px" }}> *</span>
                        </span>
                          <CreatableAsyncPaginate
                            value={fd?.name ? { label: fd.name, value: fd.name } : ""}
                            styles={{ borderRadius: "10px" }}
                            loadOptions={loadOptions}
                            onChange={createSelectChange}
                            isDisabled={fileImportLoading || isAILoading}
                            placeholder={t("MealName")}
                            isClearable
                            className="meal-name-select"
                            style={{ whiteSpace: "normal" }}
                          />
                        </Col>
                        <Col lg={2}>
                          <span className="input-title ps-0">{t("MealType")}</span>
                          <SelectInput
                            dataField="category"
                            placeholder={t("SelectMealType")}
                            isDisabled={fileImportLoading || isAILoading}
                            options={CARBON_MEAL_CATEGORIES.map((m) => ({
                              ...m,
                              label: t(m.label),
                            }))}
                            onChange={onSelectionChange}
                            value={
                              CARBON_MEAL_CATEGORIES.find((item) => {
                                if(item.id === fd.category) {
                                  return {...item, label: t(item.label)}
                                }
                              }) ??
                              null
                            }
                          />
                        </Col>
                        <Col lg={2}>
                        <span className="input-title ps-0">
                          {t("Servings")}
                          <span style={{ color: "red", fontSize: "14px" }}> *</span>
                        </span>
                          <input
                            caption=""
                            name="servings"
                            placeholder={t("AddServings")}
                            className="form-control input-field"
                            onChange={onTextChange}
                            disabled={fileImportLoading || isAILoading}
                            value={fd?.servings}
                            type="number"
                            min="1"
                            step="1"
                          />
                        </Col>
                        <Col lg={2}>
                        <span className="input-title ps-0">
                          {t("MealCategory")}
                          <span style={{ color: "red", fontSize: "14px" }}> *</span>
                        </span>
                          <SelectInput
                            dataField="type"
                            placeholder={t("SelectMealType")}
                            isDisabled={fileImportLoading || isAILoading}
                            options={MEAL_CATEGORIES.map((m) => ({
                              ...m,
                              label: t(m.label),
                            }))}
                            onChange={onSelectionChange}
                            value={
                              MEAL_CATEGORIES.find((item) => {
                                if(item.id === fd?.type) {
                                  return {...item, label: t(item.label)}
                                }
                              }) ??
                              null
                            }
                          />
                        </Col>
                        {/* <Col lg={2}>
                          <span className="input-title ps-0">{`${t("SellingPrice")} / ${t("person")} ( $ )`}</span>
                          <input
                            name="selling_price_per_person"
                            caption={`${t("SellingPrice")} / ${t("person")}`}
                            placeholder={t("SellingPrice")}
                            className="form-control input-field"
                            onChange={onTextChange}
                            disabled={fileImportLoading || isAILoading}
                            value={fd?.selling_price_per_person}
                            type="number"
                            min="1"
                            step="1"
                          />
                        </Col> */}
                        {/* <Col lg={3} style={{color:"#000000", display:"flex", alignItems:"flex-end", justifyContent: "space-around"}}>
                        <a className="CE-icon1" style={{height: "100%", position: "relative", cursor: "pointer"}} href={imageUrl} download>
                          <img style={{ width: "70px"}} src={imageUrl}/>
                          <img src={TelechargerIcon} style={{position: "absolute", top: "90%", left: "70%", transform: "translate(-50%, -50%)"}} />
                        </a>
                          <div className="total-cost-container">
                            <div className="total-cost-text">
                              {t("Total CO2eq/servings (kg)")}
                            </div>
                            <div className="total-cost">
                            {(getSum()/(parseInt(fd?.servings) || 1)).toFixed(2)}
                            </div>
                          </div>
                        </Col> */}
                      </Row>
                    </Col>
                  </Row>
                  
                  {/* ingredients and p */}
                  <Row>
                    <Col lg={9}>
                      <Row className="mt-4">
                        <Col lg={12}>
                          <span className="input-title ps-0">
                            {t("Ingredients")}
                          </span>
                          <Card className="mb-0">
                            <div className="p-4 allergens-container">
                              <div className="row custom-table h-100">
                                <div className="col-lg-12 h-100 p-0">
                                  {fd?.ingredients.length > 0 ? (
                                    <>
                                      <div className="tablescroll">
                                        <table className="table" style={{ padding: '0px 20px' }}>
                                          <thead>
                                            <tr>
                                              <td className="text-center">
                                              </td>
                                              <td className="text-center" style={{ width: "30%", fontWeight: "bold" }}>
                                                {t("Ingredients")}
                                              </td>
                                              <td
                                                className="text-center"
                                                style={{ width: "19%", fontWeight: "bold" }}
                                              >
                                                {t("Unit")}
                                              </td>
                                              <td
                                                className="text-center"
                                                style={{ width: "14%", fontWeight: "bold" }}
                                              >
                                                {t("Quantity")}
                                              </td>
                                              <td
                                                className="text-center"
                                                style={{ width: "27%", textAlign: "end", fontWeight: "bold", whiteSpace: "normal" }}
                                              >
                                                {t("Carbon impact (in Kg CO2eq)")}
                                              </td>
                                              <td
                                                className="text-center"
                                                style={{ width: "109%", textAlign: "end", display: "flex", justifyContent: "space-evenly", fontWeight: "bold" }}
                                              >
                                                <span>{t("Local")}</span>
                                                <Col lg={1} style={{ cursor: "pointer" }} onMouseEnter={() => setShowLocalInfo(true)} onMouseLeave={() => setShowLocalInfo(false)} >
                                                  <img src={INFO_ICON} style={{ width: "15px" }} />
                                                  {showLocalInfo && (
                                                    <div style={{
                                                      display: "flex",
                                                      alignItems: "flex-start",
                                                      background: "white",
                                                      borderRadius: "9px",
                                                      padding: "12px",
                                                      position: "absolute",
                                                      top: "60px",
                                                      left: "12x",
                                                      width: "150px",
                                                      zIndex: 19999
                                                    }}>
                                                      <p>{t("local = <200km")}
                                                      </p>
                                                    </div>
                                                  )}
                                                </Col>
                                              </td>
                                              <td
                                                style={{
                                                  width: "37px",
                                                  textAlign: "end",
                                                  fontWeight: "bold"
                                                }}
                                              ></td>
                                            </tr>
                                          </thead>
                                          {
                                            isAILoading ?
                                              <tbody>
                                                <tr>
                                                  <td></td>
                                                  <td></td>
                                                  <td>
                                                    <div style={{ width: "100%", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                      <Spinner animation="border" variant="primary" />
                                                    </div>
                                                  </td>
                                                  <td></td>
                                                </tr>
                                              </tbody> :

                                              <tbody>
                                                {(fd.ingredients || []).map(
                                                  (ingredient, i) => {
                                                    return (
                                                      <React.Fragment key={i}>
                                                        <tr onDoubleClick={() => handleRowClick(i)} style={{ cursor: "pointer", borderRadius: '37px', boxShadow: `0px 1px 6px 0px ${ingredient.seasonal_months?.some((month => month?.toLowerCase() === currentMonth.toLowerCase())) ? "#00000040" : "red"}`, zIndex: selectedRowIndex === i ? 2 : 0, position: "relative" }} key={i}>
                                                          <td style={{
                                                             height: "50px",
                                                             borderTopLeftRadius: "37px",
                                                             borderBottomLeftRadius: "37px",
                                                             padding: "0px 0px 0px 12px",
                                                          }}>
                                                            <img src={ingredient?.image_path ? `https://fullsoon-uploads-dev.s3.eu-west-1.amazonaws.com/co2/ingredients/${ingredient?.image_path}` : NO_IMAGE_ICON} style={{ borderRadius: "50%", width: "50px", height: "50px", objectFit: "contain" }}/>
                                                          </td>
                                                          <td>
                                                            <SelectAsyncPaginate
                                                              {...(is_demo && {
                                                                datasource: dummyDatasource,
                                                              })}
                                                              dataField="ingredient_id"
                                                              placeholder={t("SelectIngredient")}
                                                              value={ingredient}
                                                              isMulti={false}
                                                              query="carbon-footprints/ingredients"
                                                              onChange={onIngredientSelectBoxChange(
                                                                i
                                                              )}
                                                              // mapper={(rows) =>
                                                              //   rows.map((row) => ({
                                                              //     ...row,
                                                              //     name: t(row.name)
                                                              //   }))
                                                              // }
                                                              mapper={(rows) =>
                                                                // Filter out duplicate ingredients by name
                                                                rows
                                                                  .filter((row, index, self) =>
                                                                    index === self.findIndex((t) => t.name === row.name)
                                                                  )
                                                                  .map((row) => ({
                                                                    ...row,
                                                                    name: t(row.name),
                                                                  }))
                                                              }
                                                            />
                                                          </td>
                                                          <td className="">
                                                            <SelectInput
                                                              portal={true}
                                                              dataField="ingredient_unit"
                                                              placeholder={t("SelectUnit")}
                                                              options={CO2_UNITS}
                                                              onChange={onIngredientSelectBoxChange(
                                                                i
                                                              )}
                                                              value={CO2_UNITS.find(({ id }) => id === ingredient.ingredient_unit)}
                                                            />
                                                          </td>
                                                          <td className="">
                                                            <input
                                                              placeholder="format"
                                                              name="ingredient_quantity"
                                                              onChange={onIngredientTextBoxChange(
                                                                i
                                                              )}
                                                              className="form-control custom-input"
                                                              value={
                                                                (ingredient?.converted_quantity) ||
                                                                ingredient?.ingredient_quantity
                                                              }
                                                              type="text"
                                                            />
                                                          </td>
                                                          <td className="text-center"
                                                            style={{
                                                              fontSize: "12px",
                                                              userSelect: "none",
                                                              color: getColorBasedOnCarbonEmission(ingredient.carbon_emission).color
                                                            }}>
                                                              {/* If country is other than France then increase CO2 value by 10% */}
                                                            {ingredient.carbon_emission ? `${ingredient.country === 'France' ? (ingredient.carbon_emission * 0.90).toFixed(3) : ingredient.carbon_emission.toFixed(3)} kg` : null}
                                                          </td>

                                                          {/* <td className="text-center">
                                                          {(ingredient?.ingredient_cost) || null}
                                                        </td> */}

                                                          <td className="text-center"
                                                          >
                                                            {/* {(ingredient?.ingredient_cost) || null} */}
                                                            <Form.Check aria-label="option 1" onChange={() => onLocalCheckboxChange(i)} checked={ingredient?.is_local} />
                                                          </td>

                                                          <td style={{
                                                            borderTopRightRadius: "37px",
                                                            borderBottomRightRadius: "37px",
                                                          }}>
                                                            <button
                                                              type="button"
                                                              onClick={deleteIngredient(i)}
                                                              className="table-delete-btn p-0"
                                                            >
                                                              <img src={CLOSE_ICON} alt="" />
                                                            </button>
                                                          </td>
                                                        </tr>

                                                        {selectedRowIndex === i && (
                                                          <tr style={{
                                                            position: 'relative',
                                                            top: '-40px',
                                                            zIndex: '1',
                                                            boxShadow: 'none'
                                                          }}>
                                                            <td colSpan="7" style={{ padding: "0" }}>
                                                              <table className="table" style={{width: "90%", margin:'auto'}}>
                                                                <tbody>
                                                                  <tr style={{
                                                                    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 6px 0px',
                                                                    borderBottomRightRadius: '25px',
                                                                    borderBottomLeftRadius: '25px',
                                                                  }}>
                                                                    <td style={{
                                                                      borderBottomLeftRadius: '25px',
                                                                      width: "220px"
                                                                    }}>
                                                                      <p style={{ color: "#B5B7C0", fontSize: "14px", fontWeight: "600" }}>{t("Cooking Methodology")}</p>
                                                                      <select
                                                                        value={selectedCookingState || 'No cooking methodology'}
                                                                        style={{ border: "1px solid #E0E0E0", height: "38px", borderRadius: "5px", width: "165px" }}
                                                                        onChange={(e) => handleCookingMethodologyChange(e, ingredient)} 
                                                                        // onClick={(e) => handleCookingMethodologyChange2(e, ingredient)}
                                                                      >
                                                                        {cookingStates.map((state) => {
                                                                          let cookingState = COOKING_OPTIONS.find((option) => option.value === state)
                                                                         return <option key={cookingState.value} value={cookingState.value}>
                                                                            {t(cookingState.label)}
                                                                          </option>
                                                                        }
                                                                        )}
                                                                      </select>

                                                                    </td>
                                                                    <td style={{  
                                                                      textAlign: "center",
                                                                      fontFamily: "Nunito Sans",
                                                                      color: "black"
                                                                      }}>
                                                                      <p style={{ color: "#B5B7C0", fontSize: "14px", fontWeight: "600" }}>{t("Season")}</p>
                                                                      <p>
                                                                        {(() => {
                                                                          const currentMonth = new Date().toLocaleString('en-US', { month: 'long' });
                                                                          const seasonalMonths = ingredient.seasonal_months || [];
                                                                          return seasonalMonths.some((month => month?.toLowerCase() === currentMonth.toLowerCase()))
                                                                            ? <span>{t("In season")}</span>
                                                                            : <span style={{ color: "red" }}>{t("No season")}</span>
                                                                        })()}
                                                                      </p>                                                            
                                                                    </td>
                                                                    <td className="country-of-origin" style={{ height: "auto" }}>
                                                                        <p style={{ color: "#B5B7C0", fontSize: "14px", fontWeight: "600", textAlign: "center" }}>{t("Country of origin")}</p>
                                                                        <Select
                                                                            styles={customStyles}
                                                                            value={getCountry(ingredient.country)}
                                                                            components={{ Option: CountryOption, SingleValue: CountrySingleValue }}
                                                                            options={COUNTRY_CODE_OPTIONS}
                                                                            onChange={(event) => {
                                                                              handleChange(event, i);
                                                                            }}
                                                                            isSearchable={true}
                                                                            placeholder="Choose a country"
                                                                          />
                                                                    </td>
                                                                    <td style={{
                                                                      borderBottomRightRadius: '25px',
                                                                      textAlign: "center",
                                                                      fontFamily: "Nunito Sans",
                                                                      color: "black"
                                                                    }}>
                                                                      <p style={{ color: "#B5B7C0", fontSize: "14px", fontWeight: "600" }}>{t("Nutritive value")}</p>
                                                                      <p>{ingredient?.nutritive_value || '0'}&nbsp;Kcal</p>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </td>
                                                          </tr>
                                                        )}
                                                      </React.Fragment>
                                                    )
                                                  }
                                                )}
                                              </tbody>
                                          }
                                        </table>
                                      </div>
                                    </>
                                  ) :
                                    <div className="button-container" style={{ marginBottom: "35px", display: "flex", justifyContent: "space-around" }}>
                                      {/* <Button
                                        variant="primary add-btn-container"
                                        style={{ display: "flex", borderRadius: "5px", marginRight: "65px", height: "38px", minWidth: "max-content" }}
                                      >
                                        <img src={Mic} style={{ background: "#6353EA", cursor: "pointer", borderRadius: "5px", marginRight: "15px" }} />
                                        {t("Generate by voice")}
                                      </Button> */}
                                       <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={!fd?.name ? renderTooltip : <></>} 
                                      >
                                        <span style={{ display: "inline-block"}}>
                                          <Button
                                            variant="primary add-btn-container"
                                            onClick={getRandomRecipe}
                                            disabled={!fd?.name} 
                                            style={{
                                              display: "flex",
                                              borderRadius: "5px",
                                              marginRight: "65px",
                                              height: "38px",
                                              pointerEvents: !fd?.name ? 'none' : 'auto',
                                            }}
                                          >
                                            <img
                                              src={Calque}
                                              style={{
                                                width: "30px",
                                                borderRadius: "5px",
                                                objectFit: "contain",
                                                height: "28px",
                                                marginRight: "15px",
                                              }}
                                              onClick={getRandomRecipe}
                                              alt="Generate by IA"
                                            />
                                            {t("Generate by IA")}
                                          </Button>
                                          </span>
                                      </OverlayTrigger>
                                      <Button
                                        variant="primary add-btn-container"
                                        onClick={() => setImportDocument(true)}
                                        style={{ borderRadius: "5px", marginRight: "65px", height: "38px", minWidth: "121px", textAlign: "center" }}
                                      >
                                        <img
                                              src={ImportIcon}
                                              style={{
                                                background: "#6353EA",
                                                width: "15px",
                                                borderRadius: "5px",
                                                objectFit: "contain",
                                                height: "19px",
                                                marginRight: "15px",
                                                marginLeft: "15px"
                                              }}
                                              alt="Import document"
                                            />
                                        {t("Import")}
                                      </Button>
                                      <Button
                                        variant="primary add-btn-container"
                                        // onClick={() => setImportDocument(true)}
                                        style={{textAlign: "center", borderRadius: "5px", height: "38px", width: "max-content", minWidth: "max-content" }}
                                      >
                                        <img
                                              src={ExistingRecipeIcon}
                                              style={{
                                                background: "#6353EA",
                                                width: "15px",
                                                borderRadius: "5px",
                                                objectFit: "contain",
                                                height: "19px",
                                                marginRight: "15px",
                                              }}
                                              alt="Import document"
                                            />
                                        {t("Reuse Existing recipe")}
                                      </Button>
                                    </div>
                                  }
                                  {
                                    isAILoading &&
                                        <div style={{ width: "100%", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                          <Spinner animation="border" variant="primary" />
                                        </div>
                                    }
                                  <div className="bottom-container d-flex justify-content-between">
                                    <Button
                                      variant="add-ingredient"
                                      onClick={onAddIngredientItem}
                                      style={{ width: "100%", boxShadow: "0px 1px 6px 0px #00000040", borderRadius: "100px" }}
                                    >
                                      <img
                                        src={Addicon}
                                        className="add-btn-icon"
                                        alt="..."
                                      />
                                    </Button>
                                  </div>
                                  <div className="total-cost-container">
                                    <div className="total-cost-text">
                                      {t("Total CO2eq (Kg)")}
                                    </div>
                                    <div className="total-cost">
                                      {getSum().toFixed(2)}
                                    </div>
                                    {/* {Boolean(fd.servings && getSum()) && (
                                      <div className="pre-serving">
                                        {(getSum() / fd.servings).toFixed(2)} EUR
                                        / {t("servings")}
                                      </div>
                                    )} */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={3} style={{ marginTop: "40px", position: "relative" }} className="coeq-container">
                      <Card className="coeq-card left" style={{ minHeight: "100%"}}>
                      <a style={{ cursor: "pointer", textDecoration: "none", height: "97px"}} href={imageUrl} download>
                        <Card style={{height: "113px", padding: "6px"}} className="download-icon-container">
                        <img src={TelechargerIcon} style={{position: "absolute", top: "20%", left: "80%", transform: "translate(-50%, -50%)"}} />
                          <div style={{ width: "100%", height: "50px" }}>
                            <img style={{ width: "100%", height: "90%", objectFit: "contain" }} src={imageUrl} />
                          </div>
                          <div style={{ width: "100%", textAlign: "center" }}>
                            <h5 style={{ fontWeight: "600", color: currentColor, margin: "1px" }}>{fd?.name}</h5>
                          </div>
                          <Card style={{ width: "max-content", boxShadow: "0px 3px 16px -8px rgba(0,0,0,0.52)"}}>
                            <p className="weight-container" style={{ margin: "0px", borderColor: currentColor, lineHeight: "19px", color: currentColor }}>{((getSum()/(fd.servings || 1)) || 0).toFixed(2)} Kg</p>
                          </Card>
                        </Card>
                      </a>
                        <div className="card-body">
                          {fd?.ingredients?.map((item, index) => (<ProgressItem key={index} style={{ marginTop: index === 0 ? "0px" : "24px" }} name={t(item?.ingredient_id?.name) || " "} value={((item?.carbon_emission / sumOfCarbonValue) * 100)?.toFixed(1)} />))}
                        </div>
                      </Card>
                    </Col>
                  </Row>

                  {/* alternative CO2 ingredients */}
                <Row className="mt-4">
                  <Col lg={12}>
                    <span className="input-title ps-0">
                      {t("Ingredients")}/{t("Meals")}
                    </span>
                    <Card className="mb-0">
                      <div className="p-4 allergens-container">
                        <div className="row custom-table h-100">
                          <div className="col-lg-12 h-100 p-0">
                            <div>
                              <h3 style={{
                                color: "#000000",
                                fontSize: "21px",
                                fontWeight: "700",
                                paddingBottom: "17px"
                              }}>{t("Alternative ingredients with lower CO2eq")}</h3>
                            </div>
                            <div className="tablescroll">
                              <table className="table" style={{ padding : "0px 20px"}}>
                                <thead>
                                  <tr>
                                    <td className="text-center" style={{ width: "35%", fontWeight: "bold" }}>
                                      {t("Instead of")}
                                    </td>
                                    <td className="text-center" style={{ width: "10%", fontWeight: "bold" }}>
                                      {t("Switch to")}
                                    </td>
                                    <td className="text-center" style={{ width: "35%", fontWeight: "bold" }}>
                                      {t("Alternative ingredient")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "10%", fontWeight: "bold" }}
                                    >
                                      {t("Unit")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "15%", fontWeight: "bold" }}
                                    >
                                      {t("Quantity")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "20%", textAlign: "end", fontWeight: "bold" }}
                                    >
                                      {t("Carbon - Emission")}
                                    </td>
                                    <td
                                      style={{
                                        width: "37px",
                                        textAlign: "end",
                                        fontWeight: "bold"
                                      }}
                                    ></td>
                                  </tr>
                                </thead>
                                {isAlternativeLoading ?
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <div style={{ width: "100%", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Spinner animation="border" variant="primary" />
                                      </div>
                                    </td>
                                    <td></td>
                                  </tr>
                                </tbody>
                                : isLoadAlternative ? <tbody>
                                  <tr>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <div style={{ width: "100%", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Button disabled={fd?.ingredients?.length == 0 || fd?.ingredients?.some((element) => element?.name?.length === 0)} style={{ whiteSpace: "normal" }} onClick={getLowerIngredients}>{t("Load low carbon ingredients")}</Button>
                                      </div>
                                    </td>
                                    <td></td>
                                  </tr>
                                </tbody>
                                  :
                                      <tbody>
                                        {(lowerIngredients || []).map(
                                          (ingredient, i) => {
                                            return (
                                              <tr key={i} style={{
                                                borderRadius: '37px', 
                                                boxShadow: "0px 1px 6px 0px #00000040"
                                              }}>
                                                <td style={{
                                                  height: "50px",
                                                  borderTopLeftRadius: "37px",
                                                  borderBottomLeftRadius: "37px"
                                                }}>
                                                  <SelectInput
                                                    portal={true}
                                                    dataField="ingredient_id"
                                                    placeholder={t("SelectIngredient")}
                                                    options={getConvertibleUnitOptions(ingredient?.converted_unit || ingredient?.ingredient_unit)}
                                                    onChange={onIngredientSelectBoxChange(
                                                      i
                                                    )}
                                                    value={{ label: ingredient.original_ingredient, value: "kg", id: "kg" }}
                                                  />
                                                </td>
                                                <td style={{ height: "50px", textAlign: "center" }}>
                                                  <img style={{ cursor: "pointer" }} onClick={() => updateRecipe(i)} src={SWITCH} alt="switch" />
                                                </td>
                                                <td style={{ height: "auto", whiteSpace: "normal" }}>
                                                  <SelectInput
                                                    portal={true}
                                                    dataField="ingredient_id"
                                                    placeholder={t("SelectIngredient")}
                                                    options={getConvertibleUnitOptions(ingredient?.converted_unit || ingredient?.ingredient_unit)}
                                                    onChange={onIngredientSelectBoxChange(
                                                      i
                                                    )}
                                                    value={{ label: ingredient.alternative_ingredient, value: "kg", id: "kg" }}
                                                  />
                                                </td>
                                                <td style={{ height: "auto", whiteSpace: "normal" }}>
                                                  <SelectInput
                                                    portal={true}
                                                    dataField="ingredient_unit"
                                                    placeholder={t("SelectUnit")}
                                                    isDisabled={true}
                                                    options={getConvertibleUnitOptions(ingredient?.converted_unit || ingredient?.ingredient_unit)}
                                                    onChange={onIngredientSelectBoxChange(
                                                      i
                                                    )}
                                                    value={{ label: "Kg", value: "kg", id: "kg" }}
                                                  />
                                                </td>
                                                <td className="">
                                                  <input
                                                    placeholder="format"
                                                    name="ingredient_quantity"
                                                    onChange={onIngredientTextBoxChange(
                                                      i
                                                    )}
                                                    value={ingredient?.ingredient_quantity}
                                                    type="number"
                                                    className="form-control custom-input"
                                                  />
                                                </td>
                                                <td className="text-center"
                                                  style={{
                                                    fontSize: "12px",
                                                    color: getColorBasedOnCarbonEmission(ingredient?.alternative_co2_impact_per_kg).color
                                                  }}>
                                                  {ingredient?.alternative_co2_impact_per_kg ? `${(ingredient.alternative_co2_impact_per_kg)?.toFixed(2)} CO2-eq` : null}
                                                </td>
                                                <td style={{
                                                  borderTopRightRadius: "37px",
                                                  borderBottomRightRadius: "37px",
                                                  padding: "10px !important"
                                                }}>
                                                  <button
                                                    type="button"
                                                    onClick={deleteLowerIngredient(i)}
                                                    className="table-delete-btn p-0"
                                                  >
                                                    <img src={CLOSE_ICON} alt="" />
                                                  </button>
                                                </td>
                                              </tr>
                                            )
                                          }
                                        )}
                                        <tr className="bg-transparent">
                                          <td className="bg-transparent"></td>
                                          <td></td>
                                          <td>
                                            <div style={{ width: "100%", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                              <Button disabled={fd?.ingredients?.length == 0 || fd?.ingredients?.some((element) => element?.name?.length === 0)} style={{ whiteSpace: "normal" }} onClick={getLowerIngredients}>{t("Reload Alternatives")}</Button>
                                            </div>
                                          </td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </Col>
                  </Row>

                  {/* allergen and download file */}
                  <Row className="mt-4">
                    <Col lg={8}>
                      <Row>
                        <Col lg={12}>
                          <span className="input-title ps-0">{t("Allergens")}</span>
                          <Card className="mb-0">
                            <div className="p-4 allergens-container">
                              {state.allergens.map((allergen, key) => (
                                <Badge
                                  key={key}
                                  pill
                                  className={`allergens-badge ${
                                    fd.allergens.includes(allergen.id) ? "active" : ""
                                  }`}
                                  onClick={onAllergenClick(allergen)}
                                >
                                  {t(allergen.name)}
                                </Badge>
                              ))}
                            </div>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4}>
                      <Row className="mt-4 photo-container">
                        <Col lg={12}>
                          <div className="">
                            <div {...getRootProps({ className: "dropzone" })}>
                              <input {...getInputProps()} />
                              <p>{t("Drag&Drop")}</p>
                            </div>
                            <aside>
                              <div className="files-list">
                                <ul>
                                  {files.map((file, i) => (
                                    <li
                                      key={i}
                                      className="d-flex justify-content-between"
                                    >
                                      <div className="d-flex">
                                        <img src={JPEG_ICON} alt="..." />
                                        <p className="ps-3">
                                          <span>{file.path}</span>
                                          <span className="size">
                                            {parseInt(file.size * 0.001)} Kb
                                          </span>
                                        </p>
                                      </div>
                                      <div>
                                        <img
                                          src={EYE_ICON}
                                          alt="..."
                                          className="eye me-3"
                                        />
                                        <img
                                          src={CLOSE_ICON}
                                          alt="..."
                                          className="close"
                                          onClick={onRemoveFile(file, i)}
                                        />
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </aside>
                            <aside style={thumbsContainer}>{thumbs}</aside>
                            {fd.image_path && !files.length && (
                              <div style={thumb}>
                                <div style={thumbInner}>
                                  <img src={fd.image_path} style={img} alt="..." />
                                </div>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                


                  {/* Conferm Button */}
                  <Row>
                    <Col lg={8}>
                      <Modal.Footer className="add-restaurants-modal-footer" style={{
                        display: "flex",
                        justifyContent: "center"
                      }}>
                        <Button
                          type="submit"
                          className="add-restaurant-confirm-btn"
                          disabled={isSubmitDisabled}
                          style={{ width: "100%", borderRadius: "5px", height: "40px" }}
                        >
                          {t("Confirm")}
                        </Button>
                      </Modal.Footer>
                    </Col>
                  </Row>
                </Form>
              </>
            )}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CustomModal
