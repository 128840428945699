import React, { useState, useReducer, useEffect } from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
import SelectInput from "components/selectInput";
import DashboardTable from "./dashboardTable"
import { useTranslation } from "react-i18next";
import storage from "services/storage";
import { toast } from "react-toastify";
import MealModal from "./mealModel";
import { useUserData } from "contexts/AuthContextManagement";
import request from "services/request";
import { BRANDS, ENERGY, POS } from "common/constants";
import SelectAsyncPaginate from "components/selectAsyncPaginate";


function Dashboard({show}) {
  const { t } = useTranslation();
  const [fd, setFd] = useState({hotel_code: "", code_api: "", pos: "", energy: "", selectedRestaurants: [], brand: {id: "", label: "", name: ""}});
  const { hasRetaurants, setSelectedRestaurantId, restaurants, setRestaurants, isRestaurantLoaded } = useUserData();
  const [showModel, setShowModal] = useState(storage.getItem("is_dashboard_active") || show)

  const is_demo = JSON.parse(storage.getItem("is_demo"))

  const onSelectionChange = (type) => ({ id, label, name }) => {
    if(type === "brand") {
      setFd((prevFd) => ({...prevFd, brand: {id, label, name}}))
      return
    }
    setFd((prevFd) => ({...prevFd, [type]: id}));
  };

  const onBrandSelectBoxChange =  () => () => (selectedBrand) => {
    setFd((prevFd) => ({...prevFd, brand: {id: selectedBrand.id, name: BRANDS.find(({ label }) => label === selectedBrand.name)?.id, label: selectedBrand.name }}))
  }
  const dashboardHandler = async () => {
    if(!fd.hotel_code){
      toast.error(t("PleaseEnterHotelCode"))
      return
    }


    if(isRestaurantLoaded && !hasRetaurants && is_demo) {
      storage.setItem("hotel_code", fd.hotel_code)
      storage.setItem("pos", fd.pos)
      storage.setItem("energy", fd.energy)
      storage.setItem("code_api", fd.code_api)
      storage.setItem("is_dashboard_active", true)
      storage.setItem("brand", fd.brand.id)
      setShowModal(true)
      return
    }

    if(!fd.selectedRestaurants.length && !hasRetaurants) {
      toast.error(t("PleaseSelectRestaurant"))
      return
    }

    if(fd.selectedRestaurants.length) {
      const result = await request.post("/carbon-footprints/connection", {hotel_code: fd?.hotel_code, restaurants: fd.selectedRestaurants, brand_id: fd?.brand?.id});
      const newRestaurant = await result.json();
      if(result?.ok) {
        const newCreatedRestaurant = newRestaurant?.restaurants?.find(restaurnt => restaurnt.name?.toLowerCase() === fd.selectedRestaurants[0]?.toLowerCase())
        setRestaurants([...restaurants, {id: newCreatedRestaurant?.id, name: newCreatedRestaurant?.name}]);
        storage.setItem("selected_restaurant_id", newCreatedRestaurant?.id);
        storage.setItem("brand", fd.brand.name)
        setSelectedRestaurantId(newCreatedRestaurant?.id);                                                          
      }else {
        toast.error(t("SomethingWentWrongWhileCreatingRestaurant"))
      }
    }
    setShowModal(true)
  }

  return (
    <>
      {
        showModel ? <DashboardTable/> :
        <div className="mt-5">
          <Row>
            <Col>
              <div>
                <h2 className="pb-2">{t("Enter your restaurants details")}</h2>
                <p className="add-restaurants-input-title" style={{ color: "8B8F94", fontSize:"16px"}}>{t("Enter your POS and API key if available")}</p>
              </div>
            </Col>
          </Row>

          <Row style={{marginTop:"30px"}}>
            <Col lg={2}>
              <span className="add-restaurants-input-title ps-0">
                {t("HotelCode")}
              </span>
              <input
                placeholder={t("Ex : HA595")}
                onChange={(e) => setFd({ ...fd, hotel_code: e.target.value })}
                type="string"
                className="form-control custom-input"
                value={fd?.hotel_code}
                style={{
                    height:"37px"
                }}
              />
            </Col>
            <Col lg={3}>
              <span className="add-restaurants-input-title ps-0">
                {t("RestaurantName")}
              </span>
              <input
                placeholder={t("EnterRestaurantName")}
                onChange={(e) => setFd({ ...fd, selectedRestaurants: [e.target.value] })}
                type="string"
                className="form-control custom-input"
                value={fd.selectedRestaurants[0]}
                style={{
                    height:"37px"
                }}
              />
            </Col>
            
            <Col lg={2}>
              <span className="add-restaurants-input-title ps-0">
                {t("POS")}
              </span>
              <SelectInput
                dataField="pos"
                placeholder={t("Cliquer pour selectionner votre logiciel...")}
                options={POS.map((m) => ({
                ...m,
                label: t(m.label),
                }))}
                onChange={onSelectionChange}
                value={
                    POS.find(({ id }) => id === fd?.pos) ?? null
                }
              />
            </Col>
            <Col lg={2}>
              <span className="add-restaurants-input-title ps-0">
                {t("Brand")}
              </span>
              <SelectAsyncPaginate
                  dataField="brands"
                  placeholder={t("SelectBrand")}
                  value={{...fd.brand, name: fd.brand.label}}
                  query="brands"
                  isMulti={false}
                  onChange={onBrandSelectBoxChange()}
                  is_demo={is_demo}
                />
            </Col>
          </Row>

          <Row style={{marginTop:"30px"}}>
            <Col lg={3}>
              <span className="add-restaurants-input-title ps-0">
                {t("Energy")}
              </span>
              <SelectInput
                dataField="energy"
                placeholder={t("Choisissez votre énergie")}
                options={ENERGY.slice(1).map((m) => ({
                  ...m,
                  label: t(m.label),
                }))}
                onChange={onSelectionChange}
                value={
                  ENERGY.find(({ id }) => id === fd?.energy) ?? null
                }
              />
            </Col>

            <Col lg={4}>
              <span className="add-restaurants-input-title ps-0">
                {t("API (optional)")}
              </span>
              <input
                placeholder={t("Ex : 21898490")}
                onChange={(ev) => setFd({ ...fd, code_api: ev.target.value })}
                value={fd?.code_api}
                className="form-control custom-input"
                type="string"
                style={{
                    height:"37px"
                }}
              />
            </Col>
          </Row>

          <div className="dashboardButton">
              <Button className="" type="submit" variant="primary save-button"
              onClick={dashboardHandler}
              >
                {t("Connexion")}
              </Button>
          </div>

          {/* <hr style={{ borderTop: '1px solid #C2C2C2', width : '680px', margin:"60px 0px"}} />
          
          <Row>
            <Col> 
              <div>
                <h2 className="pb-2">{t("RecipesManually")}</h2>
                <p className="add-restaurants-input-title" style={{ color: "8B8F94", fontSize:"16px" }}>
                  {t("POSNotConnected")}
                </p>
              </div>
            </Col>
          </Row>

          <Row style={{paddingTop: "25px"}}>
              <Col style={{display : "flex", justifyContent : "flex-end"}}>
                <Button type="submit" variant="primary save-button" style={{
                    width:"245px",
                    height:"auto"
                 }}
                 onClick={() => setModalShow(true)}
                 >
                  {t("Next")}
                </Button>
            </Col>
          </Row> */}
        </div>
      }
    </>
  );
}

export default Dashboard;