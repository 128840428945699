import React, { useState, useContext } from "react";

export const FiterContext = React.createContext({
  selectedFilterMeals : [],
  setSelectedFilterMeals : () => {},
  selectedFinanceFilterMeals : [],
  setSelectedFinanceFilterMeals : () => {},
  selectedCompetitorList : [],
  setSelectedCompetitorList : () => {},
  selectedFilterProucts : [],
  setSelectedFilterProducts : () => {},
  selectedFilterMyRestaurants : [],
  setSelectedFilterMyRestaurants : () => {},
  filterStartEndDate: { },
  setFilterStartEndDate: () => {}, 
  filterFormData: {},
  setFilterFormData: () => {},
  eventTypeData: {},
  setEventTypeData: () => {},
});

export const FilterContextProvider = (props) => {
  const updateSelectedOption = (value) => {
    setState((prevState) => ({
      ...prevState,
      selectedFilterMeals : value
    }));
  };

  const updateFinanceSelectedOption = (value) => {
    setState((prevState) => ({
      ...prevState,
      selectedFinanceFilterMeals : value
    }));
  }

  const updateSelectedCompetitor = (value) => {
    setState((prevState) => ({ 
      ...prevState,
      selectedCompetitorList : value
    }));
  }

  const updateSelectedProducts = (value) => {
    setState((prevState) => ({
      ...prevState,
      selectedFilterProucts : value
    }));
  }
  
  const updateSelectedMyRestaurants = (value) => {  
    setState((prevState) => ({
      ...prevState,
      selectedFilterMyRestaurants : value
    }));
  };

  const updateStartEndDate = (value) => {  
    setState((prevState) => ({
      ...prevState,
      filterStartEndDate : value
    }));
  };

  const updateFormData = (data) => {
      setState((prevState) => ({
        ...prevState,
        filterFormData : data
      }));
  };

  const updateEventData = (data) => {
    setState((prevState) => ({
      ...prevState,
      eventTypeData : data
    }));
};

  const initState = {
    selectedFilterMeals : [],
    setSelectedFilterMeals : updateSelectedOption,
    selectedFinanceFilterMeals : [],
    setSelectedFinanceFilterMeals : updateFinanceSelectedOption,
    selectedCompetitorList : [],
    setSelectedCompetitorList : updateSelectedCompetitor,
    selectedFilterProucts : [],
    setSelectedFilterProducts : updateSelectedProducts,
    selectedFilterMyRestaurants : [],
    setSelectedFilterMyRestaurants : updateSelectedMyRestaurants,
    filterStartEndDate : {
      start_date: "",
      end_date: "",
    },
    setFilterStartEndDate: updateStartEndDate,
    filterFormData: { total: true },
    setFilterFormData: updateFormData,
    eventTypeData: {},
    setEventTypeData: updateEventData,
  };

  const [state, setState] = useState(initState);
    return (
    <FiterContext.Provider value={state}>{props.children}</FiterContext.Provider>
  );
};

export function useFilterData() {
  const context = useContext(FiterContext);
  if (!context) {
    throw new Error("useFilterData must be used within FiterContext");
  }
  return context;
}
