import React, { useState, useEffect } from "react";
import moment from "moment";

import LeftSide from "./leftSide";
import RightSide from "./rightSide";
import { useUserData } from "contexts/AuthContextManagement";
import { Sidebar } from "react-pro-sidebar";
import "./index.scss";

function Event() {
  const [formData, setformData] = useState({
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD")
  });
  const {
    isFilterShown,
    setFilterShown
  } = useUserData();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onApply = (params) => {
    setformData({ ...params });
  };

  return (
    <div className="events" style={{ display: isFilterShown ? "flex" : "block", gap: "20px" }}>
      <LeftSide formData={formData} />
      {windowWidth > 750 && (
        isFilterShown &&
        <RightSide onApply={onApply} formData={formData} />
      )}

      {windowWidth <= 750 && (
        isFilterShown &&
        <Sidebar
          onBackdropClick={() => setFilterShown(false)}
          toggled={isFilterShown}
          breakPoint="always"
          rtl
          style={{ height: '100vh' }}
        >
          <RightSide onApply={onApply} formData={formData} />
        </Sidebar >
      )
      }
    </div>
  );
}

export default Event;
