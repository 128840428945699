import React from "react";
import { Modal, Button, Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import DeleteIcon from "assets/images/icon/DELETE.svg";

import "./index.scss";

function DeleteModal(props) {
  const { t } = useTranslation();

  return (
    <Modal
      {...props}
      className="delete-modal"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="modal-90w"
      centered
    >
      <Container>
        <Row>
          <Col className="icon-conatiner" lg={{ offset: 10, span: 2 }}>
            <img
              src={DeleteIcon}
              className="icon"
              alt=""
              onClick={() => {
                props.onHide();
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <p className="title">{props.modalData.title}</p>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <p className="description">{props.modalData.description}</p>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col lg={5}>
            <Button
              variant="outline-primary"
              type="submit"
              className="back-btn"
              onClick={() => {
                props.onHide();
              }}
            >
              {t("NoGoBack")}
            </Button>
          </Col>
          <Col lg={6} className="col-lg">
            <Button
              type="submit"
              className=""
              onClick={() => {
                props.onPositiveClicked();
              }}
            >
              {props.modalData.positiveBtnTitle}
            </Button>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
}

export default DeleteModal;
