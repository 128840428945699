import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import SelectInput from "components/selectInput";
import DashboardTable from "./dashboardTable"
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import storage from "services/storage";
import UploadedIcon from "assets/images/uploaded_meal.png";


import './index.scss'
import request from "services/request";
import { useUserData } from "contexts/AuthContextManagement";
import { BRANDS, ENERGY, POS } from "common/constants";
import SelectAsyncPaginate from "components/selectAsyncPaginate";

function RestaurantModel({ onHide, show, state, onRowAdded, ...props }) {
  const { t } = useTranslation();
  const [isUploaded, setIsUploaded] = useState(false);
  const { hasRetaurants, isRestaurantLoaded, setSelectedRestaurantId, restaurants, setRestaurants } = useUserData();


  const [fd, setFd] = useState({hotel_code: "", code_api: "", pos: "", energy: "", selectedRestaurants: [], brand: {id: "", label: ""}});

  const onSelectionChange = (type) => ({ id, label, name }) => {
    if(type === "brand") {
      setFd((prevFd) => ({...prevFd, brand: {id, label, name}}))
      return
    }
    setFd((prevFd) => ({...prevFd, [type]: id}));
  };


  const onBrandSelectBoxChange =  () => () => (selectedBrand) => {
    setFd((prevFd) => ({...prevFd, brand: {id: selectedBrand.id, name: BRANDS.find(({ label }) => label === selectedBrand.name)?.id, label: selectedBrand.name }}))
  }


  const dashboardHandler =async () => {
    if(!fd.hotel_code){
      toast.error(t("PleaseEnterHotelCode"))
      return
    }
    if(isRestaurantLoaded && !hasRetaurants) {
      setIsUploaded(true)
      storage.setItem("brand", fd.brand.id)
      return
    }

    const result = await request.get("restaurants");
    let _restaurants = result.restaurants;
    const restaurantFound = _restaurants.find((r) => r.name?.toLowerCase() === fd.selectedRestaurants[0]?.toLowerCase());
    if(restaurantFound) {
        toast.info(t(`${fd.selectedRestaurants[0]} `) + t(`already exists`))
        onHide()
        return
    }
    // if(!fd.pos){
    //   toast.error(t("PleaseSelectPos"))
    //   return
    // }
    // if(!fd.energy){
    //   toast.error(t("PleaseSelectEnergy"))
    //   return
    // }
    // if(!fd.code_api){
    //   toast.error(t("PleaseEnterApiCode"))
    //   return
    // }
    // if(fd.code_api !== SILVERWARE_API_KEY) {
    //   toast.error(t("PleaseEnterValidApiCode"))
    //   return
    // }

    if(!fd.selectedRestaurants.length) {
      toast.error(t("Please enter restaurant name"))
      return
    }
    storage.setItem("hotel_code", fd.hotel_code)

    if(fd.selectedRestaurants.length) {
      const result = await request.post("/carbon-footprints/connection", {hotel_code: fd?.hotel_code, restaurants: fd.selectedRestaurants, brand_id: fd?.brand?.id});
      const newRestaurant = await result.json();
      if(result?.ok) {
        const newCreatedRestaurant = newRestaurant?.restaurants?.find(restaurnt => restaurnt.name?.toLowerCase() === fd.selectedRestaurants[0]?.toLowerCase())
        setRestaurants([...restaurants, {id: newCreatedRestaurant.id, name: newCreatedRestaurant?.name}]);
        storage.setItem("selected_restaurant_id", newCreatedRestaurant?.id);
        setSelectedRestaurantId(newCreatedRestaurant?.id);
        storage.setItem("brand", fd.brand.name)
        setIsUploaded(true)                                                         
      }else {
        toast.error(t("SomethingWentWrongWhileCreatingRestaurant"))
      }
    }
  }

  useEffect(() => {
    return () => {
      setIsUploaded(false)
      setFd({hotel_code: "", code_api: "", pos: "", energy: "", selectedRestaurants: [], brand: {id: "", label: ""}})
    }
  }, [])


  return (
    
      <Modal
        show={show}
        onHide={() => {
          onHide()
          setIsUploaded(false)
          setFd({hotel_code: "", code_api: "", pos: "", energy: "", selectedRestaurants: [], brand: {id: "", label: ""}})
        }}
        size="xl"
        centered
        className="add-meals"
        contentClassName="rest-model-width"
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <Container>
          {isUploaded && (
              <div className="d-flex justify-content-center flex-column  text-center upload-container">
                <div>
                  <img src={UploadedIcon} alt="..." />
                </div>
                <div className="heading mt-5">
                  <label>{t("Restaurant Created !")}</label>
                </div>
                <div className="subheading mt-2 mb-5">
                  <label>{t("Your restaurant has been successfully created.")}</label>
                </div>
                <div>
                  <Button
                    className="add-restaurant-confirm-btn"
                    onClick={() => {
                      onHide()
                      setIsUploaded(false)
                      setFd({hotel_code: "", code_api: "", pos: "", energy: "", selectedRestaurants: [], brand: {id: "", label: ""}})
                    }}
                  >
                    OK
                  </Button>
                </div>
              </div>
            )}
            {!isUploaded && (
              <>  
              <Row>
                <Col>
                  <div>
                    <h2 className="pb-2">{t("Enter your restaurants details")}</h2>
                    <p style={{ color: "8B8F94" }}>{t("Enter your POS and API key if available")}</p>
                  </div>
                </Col>
              </Row>
              <Row style={{marginTop:"30px"}}>
            <Col lg={2}>
              <span className="add-restaurants-input-title ps-0">
                {t("HotelCode")}
              </span>
              <input
                placeholder={t("Ex : HA595")}
                onChange={(e) => setFd({ ...fd, hotel_code: e.target.value })}
                type="string"
                className="form-control custom-input"
                value={fd?.hotel_code}
                style={{
                    height:"37px"
                }}
              />
            </Col>
            <Col lg={3}>
              <span className="add-restaurants-input-title ps-0">
                {t("RestaurantName")}
              </span>
              <input
                placeholder={t("EnterRestaurantName")}
                onChange={(e) => setFd({ ...fd, selectedRestaurants: [e.target.value] })}
                type="string"
                className="form-control custom-input"
                value={fd.selectedRestaurants[0]}
                style={{
                    height:"37px"
                }}
              />
            </Col>
            
            <Col lg={2}>
              <span className="add-restaurants-input-title ps-0">
                {t("POS")}
              </span>
              <SelectInput
                dataField="pos"
                placeholder={t("Cliquer pour selectionner votre logiciel...")}
                options={POS.map((m) => ({
                  ...m,
                  label: t(m.label),
                  }))}
                onChange={onSelectionChange}
                value={
                    POS.find(({ id }) => id === fd?.pos) ?? null
                }
              />
            </Col>
            <Col lg={2}>
              <span className="add-restaurants-input-title ps-0">
                {t("Brand")}
              </span>
                <SelectAsyncPaginate
                  dataField="brands"
                  placeholder={t("SelectBrand")}
                  value={{...fd.brand, name: fd.brand.label}}
                  query="brands"
                  isMulti={false}
                  onChange={onBrandSelectBoxChange()}
                />
            </Col>
          </Row>

          <Row style={{marginTop:"50px"}}>
            <Col lg={3}>
              <span className="add-restaurants-input-title ps-0">
                {t("Energy")}
              </span>
              <SelectInput
                dataField="energy"
                placeholder={t("Choisissez votre énergie")}
                options={ENERGY.slice(1).map((m) => ({
                  ...m,
                  label: t(m.label),
                }))}
                onChange={onSelectionChange}
                value={
                  ENERGY.find(({ id }) => id === fd?.energy) ?? null
                }
              />
            </Col>

            <Col lg={4}>
              <span className="add-restaurants-input-title ps-0">
                {t("API (optional)")}
              </span>
              <input
                placeholder={t("Ex : 21898490")}
                onChange={(ev) => setFd({ ...fd, code_api: ev.target.value })}
                value={fd?.code_api}
                className="form-control custom-input"
                type="string"
                style={{
                    height:"37px"
                }}
              />
            </Col>
          </Row>
              </>
              )}
          </Container>
        </Modal.Body>

        {!isUploaded && <Modal.Footer className="add-restaurants-modal-footer" style={{
          display : "flex",
          justifyContent: "flex-end"
        }}>
          <Button
            type="submit"
            className="add-restaurant-confirm-btn"
            onClick={dashboardHandler}
          >
             {t("Connexion")}
          </Button>
        </Modal.Footer> }
      </Modal>
  );
}

export default RestaurantModel;


