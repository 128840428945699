import LineChart from "components/lineChart";
import HighchartsReact from "highcharts-react-official";
import {useMemo} from "react";
import Highcharts from "highcharts";
import { useTranslation } from "react-i18next";
import CustomTable from "components/customTable";


const linearGradient = { x1: 0, y1: 0, x2: 0, y2: 1 };
const color1 = {
  color: "#6353EA",
  fillColor: {
    linearGradient,
    stops: [
      [0, "rgba(99, 83, 234, 0.3)"],
      [1, Highcharts.color("#6353EA").setOpacity(0).get("rgba")],
    ],
  },
};
const color2 = {
  color: "#34CA70",
  fillColor: {
    linearGradient,
    stops: [
      [0, "#C5F8DF"],
      [1, Highcharts.color("#C5F8DF").setOpacity(0).get("rgba")],
    ],
  },
};

export function ParentChildCount(props) {
  const { t } = useTranslation();
  return (
    // <CustomTable
    //         // columns={sameOrderMealsColumns}
    //         data={props.data}
    //       />
    <div>
        <div>
          <table style={{display: "flex", backgroundColor: "transparent"}}>
            <thead>
              <tr style={{display: "flex", flexDirection: "column", backgroundColor: "transparent"}}>
                <th style={{backgroundColor: "transparent", width: "70px", wordWrap: "breakWord", whiteSpace: "normal"}}>{t("Sold in menu")}</th>
                <th style={{backgroundColor: "transparent", width: "70px", wordWrap: "breakWord", whiteSpace: "normal"}}>{t("Sold alone")}</th>
              </tr>
            </thead>
            <tbody>
                <tr style={{ textAlign: "center", display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-around", width: "80px" }}>
                  <td>{props.data.sales?.child_sales}</td>
                  <td>{props.data.sales?.parent_sales}</td>
                </tr>
                {/* {props.data.prediction.isOpen ?
                  Object.keys(props.data.sales.details).map((key) => (
                    Object.keys(props.data.sales.details[key]).map((ase) => (
                      <tr key={ase} style={{ textAlign: "center", display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-around", width: "80px" }}>
                        <td>{props.data.sales.details[key][ase]['child_sales']}</td>
                        <td>{props.data.sales.details[key][ase]['parent_sales']}</td>
                      </tr>
                    ))
                  ))
                  : null
                } */}
              </tbody>
          </table>
        </div>
    </div>
//     <div className="horizontal-table">
//       <div className="table-row bg-white">
//         <div className="table-cell heading">{t("Sold in menu")}</div>
//         <div className="table-cell">{props.data.sales?.parent_sales}</div>
//       </div>
//       <div className="table-row">
//         <div className="table-cell heading">{t("Sold alone")}</div>
//         <div className="table-cell">{props.data.sales?.child_sales}</div>
//       </div>
// </div>
  )
}

function PriceHistory() {

  const randomData = useMemo(() => {
    const xAxis = [];
    const prices = [];
    for (let i = 1; i < 25; i++) {
      xAxis.push(`0${i}/02`);
      prices.push(Math.floor(Math.random() * 40));
    }
    return { xAxis, prices }
  }, [])
  
  return (
    <div className="price-history-container">
      <h5>Price history</h5>
      <div className="price-history-body">
        <HighchartsReact
        containerProps={{ style: { height: "100%", width: "100%" } }}
        highcharts={Highcharts}
          options={{
            chart: {
                zoomType: 'xy',
                backgroundColor: 'transparent'
            },
            title: {
                text: '',
            },
            
            xAxis: [{
                categories: randomData.xAxis,
                crosshair: true
            }],
            yAxis: [{ // Primary yAxis
                labels: {
                    format: '{value}$',
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                },
                title: {
                    text: 'Price',
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                }
            }, { // Secondary yAxis
                title: {
                    text: 'Price',
                    style: {
                        color: Highcharts.getOptions().colors[0]
                    }
                },
                
                opposite: true
            }],
            tooltip: {
              backgroundColor: "#FFF",
              borderColor: "#D8D8D8",
              borderRadius: 4,
              shared: true,
              formatter: function () {
                return `
                  <label style="font-family: Nunito Sans;font-size: 10px;line-height: 14px;color: #8B8F94">${this.x}</label>
                  <label style="color:#D8D8D8;" >|</label>
                  <strong style="font-family: Nunito Sans;font-size: 10px;line-height: 14px;">${this.y}</strong>`;
              },
            },
            legend: {
                enabled: false
            },
            plotOptions: {
              column: {
                pointPadding: 0.2,
                borderWidth: 0,
                borderRadius: 5,
                color: "rgba(99, 83, 234, 0.3)",
                states: { hover: { color: "#6353EA" } },
              },
              series: {
                selected: false,
              },
            },
            series: [{
                name: 'Precipitation',
                type: 'column',
                yAxis: 1,
                data: randomData.prices,
                tooltip: {
                    valueSuffix: '$'
                }
        
            }, {
                name: 'Temperature',
                type: 'areaspline',
                data: randomData.prices,
                tooltip: {
                    valueSuffix: '$'
                },
                zones: [color1, color2],
                marker: { enabled: false, fillColor: "#fff" },
            }]
        }}
        />
      </div>
    </div>
  );
}

export default PriceHistory;
