function ProgressItem({name, value, style}) {
  return (
    <>
      <div style={style} className="progress-item">
        <p style={{ maxHeight: "30px", overflow: "hidden"}}>{name}</p>
        <div className="progress-container">
          <div className="progress-bar">
            <div style={{ width: `${value}%` }} className="progress-value">{value}%</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProgressItem;
