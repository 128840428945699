import React, { useState, useEffect, useMemo, useRef } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import makeAnimated from "react-select/animated";

import { useUserData } from "contexts/AuthContextManagement";

import CloseIcon from "assets/images/close.png";
import CalendarIcon from "assets/images/calendar_datepicker.png";
import DeleteIcon from "assets/images/icon/DELETE_WHITE.svg";

import "react-datepicker/dist/react-datepicker.css";

/**
 * function to render the filter screen of the sctock page
 * @param {*} param0
 * @returns UI component
 */
const START_DATE = new Date(new Date().setDate(new Date().getDate()));
const END_DATE = new Date(new Date().setDate(new Date().getDate()));

function RightSide({ onApply: onSubmit, formData: fd }) {
  const { t } = useTranslation();

  const {
    isFilterShown,
    isRestaurantLoaded,
    hasRetaurants,
    setFilterShown,
    selectedRestaurantId,
    selectedRestaurant,
    restaurants
  } = useUserData();

  const selectMyRestaurantRef = useRef(null);
  const [selectedMyRestaurants, setSelectedMyRestaurants] = useState([]);
  const [resturantOptions, setResturantOptions] = useState(restaurants);
  const [selectedOption, setSelectedOption] = useState([]);
  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [startEndDate, setStartEndDate] = useState({
    start_date:  START_DATE,
    end_date: END_DATE,
  });

  /**
   * to be called whenever the user selects the date from date-window
   * @param {*} name field name
   * @returns
   */
  
  const onDateChange = (name) => (e) => {
    setStartEndDate({ ...startEndDate, [name]: e });
    setIsStartDateOpen(false);
    setIsEndDateOpen(false);
  };

  useEffect(() => {
    onClearAll();
  }, [selectedRestaurantId]);

  /**
   * submits the selected filters to api
   * @param {*} isInitial
   */
  const onApply = (isInitial = false) => {
    const payload = JSON.parse(
      JSON.stringify({ ...(isInitial ? {} : formData), ...startEndDate })
    );
    const getFormattedDate = (d) => moment(d).format("YYYY-MM-DD");

    if (payload.start_date) {
      payload.start_date = getFormattedDate(payload.start_date);
    }
    if (payload.end_date) {
      payload.end_date = getFormattedDate(payload.end_date);
    }

    onSubmit({
      ...payload,
    });
  };

  /**
   * update various filter options
   * @param {*} param0
   */

  const onClearDates = () => {
    setStartEndDate({
      start_date: new Date(),
      end_date: new Date(),
    });
  };

  const onClearAll = (apply = true) => {
    onClearDates();
    setFormData({});
    onClearProducts();

    if (apply) onApply(true);
  };

  const onClearMyRestaurants = () => {
    if (selectMyRestaurantRef?.current) {
      selectMyRestaurantRef.current.clearValue();
    }
  };

  const setDateOpen =
    (type, checked = false) =>
    () => {
      type === "start_date"
        ? setIsStartDateOpen(checked)
        : setIsEndDateOpen(checked);
    };

  const onClearProducts = () => {
    setSelectedOption([]);
  };

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected, ...args }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
            ? data.color
            : isFocused
              ? "#F3F4FB"
              : undefined,
        color: isDisabled ? "#ccc" : isSelected ? "black" : "black",
      };
    },
    multiValueLabel: (base) => ({ ...base }),
  };

  return (
    isFilterShown && (
        <div className="rightcontent">
          <div className="card">
            <div className="card-body">
              <Row>
                <Col lg={4}>
                  <h1>{t("Filters")}</h1>
                </Col>
                <Col lg={8}>
                  <div
                    className="hide-filter"
                    onClick={() => setFilterShown(false)}
                  >
                    {t("HideFilters")} <img src={DeleteIcon} alt="" />
                  </div>
                </Col>
              </Row>

              <div className="mb-5">
                  <div className="fltr-heading">
                    <label>{t("MyRestaurants")}</label>
                    <button onClick={onClearMyRestaurants}>{t("Clear")}</button>
                  </div>
                  <Select
                    ref={selectMyRestaurantRef}
                    styles={colourStyles}
                    defaultValue={selectedMyRestaurants}
                    value={selectedMyRestaurants}
                    onChange={setSelectedMyRestaurants}
                    components={makeAnimated()}
                    options={resturantOptions.map((r) => ({
                      value: r.id,
                      label: r.name,
                    }))}
                    isMulti
                    isSearchable
                    placeholder={t("SelectRestaurants")}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        danger: "#fff",
                        dangerLight: "hsl(53deg 2% 73%)",
                      },
                    })}
                  />
                </div>

              <div className="rightcontent-fltr">
                <div className="mb-5">
                  <div className="fltr-heading">
                    <label>{t("Date")}</label>
                    <button onClick={onClearDates}>{t("Clear")}</button>
                  </div>
                  <div className="d-flex datepicker-wrapper">
                    <span>{t("Start")}</span>
                    <div className="datepicker-wrapper-img">
                      <DatePicker
                        selected={new Date(startEndDate.start_date)}
                        dateFormat="dd MMM yyyy"
                        placeholderText={t("Start date")}
                        onChange={onDateChange("start_date")}
                        className="date-picker"
                        open={isStartDateOpen}
                        onClickOutside={setDateOpen("start_date")}
                        onClick={setDateOpen("start_date", true)}
                        // minDate={new Date()}
                      />
                      <img
                        src={CalendarIcon}
                        onClick={setDateOpen("start_date", !isStartDateOpen)}
                        alt=""
                      />
                    </div>
                  </div>
                  <hr className="hr-separator" />
                  <div className="d-flex datepicker-wrapper">
                    <span>{t("End")}&nbsp;&nbsp;</span>
                    <div className="datepicker-wrapper-img">
                      <DatePicker
                        selected={new Date(startEndDate.end_date)}
                        dateFormat="dd MMM yyyy"
                        placeholderText={t("End date")}
                        onChange={onDateChange("end_date")}
                        className="date-picker"
                        open={isEndDateOpen}
                        onClickOutside={setDateOpen("end_date")}
                        onClick={setDateOpen("end_date", true)}
                        // minDate={new Date()}
                      />
                      <img
                        src={CalendarIcon}
                        onClick={setDateOpen("end_date", !isEndDateOpen)}
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="mt-4">
                    <div className="fltr-heading">
                      <label>{t("Types")}</label>
                      <button onClick={onClearEventTypes}>{t("Clear")}</button>
                    </div>
                    {((isRestaurantLoaded && !hasRetaurants) ? EVENT_TYPES : EVENT_TYPES).map((check, i) => {
                      const label = t(check.label);
                      return (
                        <Fragment key={i}>
                          {customCheckbox({
                            name: check.id,
                            label,
                            checked: formData?.["type"] == check.id || false,
                            type: "eventTypes",
                          })}
                        </Fragment>
                      );
                    })}
                </div> */}
              </div>

              <div className="action-btns" style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column"
              }}>
                <div style={{
                  display: "flex"
                }}>
                  <button onClick={onClearAll} className="close-btn me-3">
                    <img src={CloseIcon} alt="" />
                    {t("Clear")}
                  </button>
                  <button onClick={() => onApply(false)} className="apply-btn">
                    {t("Apply")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  );
}

export default RightSide;
