import { useEffect } from "react";

import { NavLink, useLocation, useHistory } from "react-router-dom";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { useTranslation } from "react-i18next";

import Routes from "routes/router";
import useWindowSize from "customHooks/useWindowResize";
import storage from "services/storage";
import { MAX_COLLAPISBLE_SIDEBAR } from "common/constants";
import { useUserData } from "contexts/AuthContextManagement";
import { useFilterData } from "contexts/FilterContextManagment";

import Logo from "assets/images/cible_l.png";
import FullSoonLogo from "assets/images/Fullsoon.png";
import CloseSidebarIcon from "assets/images/icon/CLOSED_SIDEBAR.svg";
import { ReactComponent as LogoutSvg } from "assets/images/icon/LOGOUT.svg";

import "./sidebar.scss";

function SidebarComp({ isCollapsed, setIsCollapsed, isToggle, setIsToggle, broken, setBroken }) {
  const location = useLocation();
  const history = useHistory();
  const [width] = useWindowSize();
  const { t } = useTranslation();
  const { id: userId, setRestaurants, setSelectedRestaurantId } = useUserData();
  const {
    setSelectedFilterMeals,
    setSelectedFinanceFilterMeals,
    setSelectedFilterMyRestaurants,
    setSelectedFilterProducts,
    setFilterStartEndDate,
    setFilterFormData,
    setSelectedCompetitorList,
    setEventTypeData
  } = useFilterData();

  /* hide routes manually/temporarily for clients of some restaurants 
    as currently we don't have roles/permissions implemented */
  let routes = Routes;

  useEffect(() => {
    setTimeout(() => {
      const resizeEvent = window.document.createEvent("UIEvents");
      resizeEvent.initUIEvent("resize", true, false, window, 0);
      window.dispatchEvent(resizeEvent);
    }, 50);
  }, [isCollapsed]);

  const activeRoute = (routeName) =>
    location.pathname?.indexOf(routeName) > -1 ? "selected" : "";

  const onCollapsedSidebar = () => {
    setIsCollapsed(() => !isCollapsed);
  };

  const collapsedSidebar =
    width < MAX_COLLAPISBLE_SIDEBAR || isCollapsed || false;

  const onLogoClick = () => {
    history.push("/");
  };

  const onLogout = (e) => {
    e.preventDefault();
    storage.clear();
    setRestaurants([], false);
    setSelectedRestaurantId("");
    setSelectedFilterMeals([]);
    setSelectedFinanceFilterMeals([]);
    setSelectedFilterMyRestaurants([]);
    setSelectedFilterProducts([]);
    setFilterStartEndDate({
      start_date: "",
      end_date: "",
    });
    setFilterFormData({ total: true });
    setSelectedCompetitorList([]);
    setEventTypeData([]);
    history.push("/signin");
  };

  const handleBreakPoint = (isBreak) => {
    setIsToggle(false)
    setBroken(isBreak);
  }

  return (
    <>
      <Sidebar
        onBackdropClick={() => setIsToggle(false)}
        customBreakPoint="800px"
        onBreakPoint={handleBreakPoint}
        collapsed={isCollapsed}
        toggled={isToggle}
        backgroundColor={'white'}
        collapsedWidth="103px"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: isCollapsed ? '103px' : '250px',
          height: '100%',
        }}
      >
        {!broken && (
          <img
            src={CloseSidebarIcon}
            alt=""
            className={`toggleIcon ${isCollapsed ? "collapsed" : ""}`}
            onClick={onCollapsedSidebar}
          />
        )}
        <Menu>
          <aside className="leftmenu">
            <div className="logomain cursor-pointer" onClick={onLogoClick}>
              <img src={Logo} className="img-fluid mx-3 " alt="" />
              {!isCollapsed && (
                <img src={FullSoonLogo} className="img-fluid hidesm" alt="" />
              )}
            </div>
            <ul
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
              style={{
                display: isToggle ? 'block' : 'flex',
              }}
            >
              {routes.map(({ path, name, icon: Icon, keyName }, key) => (
                <MenuItem key={key} style={{
                  paddingLeft: '0px',
                  paddingRight: '0px',
                  height: '45px'
                }}>
                  <NavLink
                    to={path}
                    className={`sidebar-link ${activeRoute(
                      path
                    )} mb-0`}
                    activeClassName="active"
                  >
                    <span className="menu-icon">{Icon}</span>
                    <span className="menu-txt">{t(name)}</span>
                  </NavLink>
                </MenuItem>
              ))}

              <MenuItem style={{
                paddingLeft: '0px',
                paddingRight: '0px',
                height: '45px'
              }}>
                <NavLink to="/logout" className={`sidebar-link  mb-0 logoutbtn`} onClick={onLogout}>
                  <span className="menu-icon">
                    <LogoutSvg />
                  </span>
                  <span className="menu-txt">{t("LogOut")} </span>
                </NavLink>
              </MenuItem>
            </ul>
          </aside>
        </Menu>
      </Sidebar >
    </>
  );
}

export default SidebarComp;
