import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Row, Col, Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PieChart from "components/pieChart";

import request from "services/request";
import { getDummyFilteredData, timezoneFormat, getTimezoneFormatUtc } from "common/utils.ts";
import { useLoading } from "contexts/LoadingContextManagement";
import { DEFAULT_ERROR_MESSAGE } from "common/constants";
import { useUserData } from "contexts/AuthContextManagement";

import FilterIcon from "assets/images/icon/FILTER_ICON1.svg";

import LineChart from "components/lineChart";
import { sumDailyOccupancy, parseData } from "./utils";
import occupancyGuestsDummy from "../../../data/occupancy_guests.json";
import { getCarbonMealDummyData } from "../../settings/utils";
import carbonIngredientsDummy from "../../../data/carbon_ingredient.json";
import storage from "services/storage";
import "./index.scss";


function randomRange(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

function LeftSide({ formData }) {
  const { t } = useTranslation();
  const { setLoading, setError } = useLoading();
  const [fd, setfd] = useState({ orderBy: "desc", search: "" });
  // We start with an empty list of items.
  const [lineChartData, setLineChartData] = useState([]);
  const [impactByMeals, setImpactByMeals]= useState([]);
  const [impactByIngredient, setImpactByIngredient]= useState([]);
  const prevFromData = useRef(formData)

  let randomIngredientRange = randomRange(0, 40)
  let randomMealRange = randomRange(0, 12)
  let dummyCarbonIngredients = carbonIngredientsDummy.cf_ingredients.slice(randomIngredientRange, randomIngredientRange+4)
  let dummyCarbonMeals = getCarbonMealDummyData()?.cf_meals.slice(randomMealRange, randomMealRange+4)

  const {
    selectedRestaurantId,
    isRestaurantLoaded,
    hasRetaurants,
    isFilterShown,
    selectedRestaurant,
    setFilterShown,
  } = useUserData();


  useEffect(() => {
    if (isRestaurantLoaded && !hasRetaurants) {
      const occupancyByDays = getDummyFilteredData(
        occupancyGuestsDummy,
        {
        start_date: moment().subtract(15, 'days').format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD")
      },
        selectedRestaurant?.timezone
      );
      setLineChartData(() => sumDailyOccupancy(occupancyByDays, formData));
    }
  }, [formData, isRestaurantLoaded, hasRetaurants]);

  useEffect(async() => {
    if (selectedRestaurantId !== "") {
      await getFootPrintAnalytics(formData.start_date, formData.end_date);
      await getImpactIngredientAndMeals();
    }
  }, [formData]);

  const getFootPrintAnalytics = async (startDate, endDate) => {  
    if (selectedRestaurantId === "") {
      return;
    }
    setLoading(true);
    try {
      const result = await request.get(
        "carbon-footprints/analytics/sales",
        {
          start_date: getTimezoneFormatUtc(formData.start_date, formData.end_date, selectedRestaurant?.timezone)?.start_date,
          end_date: getTimezoneFormatUtc(formData.start_date, formData.end_date, selectedRestaurant?.timezone)?.end_date,
          restaurant_id: selectedRestaurantId,
        },
        true,
        true,
        true
      );

      const occupancyByDays = parseData(
        result.days,
        selectedRestaurant?.timezone
      );
      setLineChartData(() => sumDailyOccupancy(occupancyByDays, formData));
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.status !== 499) {
        setError(DEFAULT_ERROR_MESSAGE);
        setLoading(false);
      }
    }
  };

  const getImpactIngredientAndMeals = async () => {  
    if (selectedRestaurantId === "") {
      return;
    }
    setLoading(true);
    try {
      const result = await request.get(
        "carbon-footprints/analytics/impacts",
        {
          start_date: getTimezoneFormatUtc(formData.start_date, formData.end_date, selectedRestaurant?.timezone)?.start_date,
          end_date: getTimezoneFormatUtc(formData.start_date, formData.end_date, selectedRestaurant?.timezone)?.end_date,
          restaurant_id: selectedRestaurantId,
        }
      );

      setImpactByMeals(() =>
        result?.impact_by_meals?.map((m) => ({
          name: m.name,
          y: m.co2_impact_percentage,
        })));
      setImpactByIngredient(() =>
        result?.impact_by_ingredients?.map((m) => ({
          name: m.name,
          y: m.co2_impact_percentage,
        })))
      setLoading(false);
    } catch (error) {
      if (error?.status !== 499) {
        setError(DEFAULT_ERROR_MESSAGE);
        setLoading(false);
      }
    }
  };

  const totalEarnings = [
    { name: 'Cheese', y: 17.5 },
    { name: 'Cake', y: 10.2 },
    { name: 'Bread', y: 5.6 },
    { name: 'Butter', y: 12.3 },
    { name: 'Milk', y: 18.7 },
    { name: 'Juice', y: 8.4 },
    { name: 'Pasta', y: 14.2 },
    { name: 'Eggs', y: 3.9 },
    { name: 'Chicken', y: 16.1 },
    { name: 'Beef', y: 19.0 }
]

  return (
    <div
      className="my-ingredients"
      style={{ width: isFilterShown ? "80%" : "" }}
    >
      <Row>
        <div className="d-flex row" style={{ marginTop: "42px" }}>
          <Col className="analytics-footprint" style={{ width: "max-content", paddingLeft: "2%" }}>
            <h2 className="title-text">{t("CarbonFootprintAnalytics")}</h2>
            <p className="sub-title-text">
              {t("analyzeFootprint")}
            </p>
          </Col>
          <Col className="sort-container d-flex justify-content-end">
            {!isFilterShown && (
              <div className="headerbtns ms-3">
                <button
                  onClick={() => setFilterShown(true)}
                  className="btn btn-white btn-icon btn-theme"
                  style={{
                    height: "35px",
                  }}
                >
                  <img src={FilterIcon} alt="" style={{ height: "20px" }} className="m-0" />
                </button>
              </div>
            )}
          </Col>
        </div>
      </Row>
      <Row className="coeq-container my-3">
        <Card className="coeq-card left py-4">
          <div className="card-head-co2">
            <span className="card-heading">
              {t("ImpactCO2By")}{" "}
              <span style={{ color: "#6353ea" }}>{t("ingredients")}</span>
            </span>
          </div>
          <div className="card-body">
          <PieChart
                  data={impactByIngredient}
                  formatterPrefix={`%`}
                />
            </div>
        </Card>
        <Card className="coeq-card right py-4">
          <div className="card-head-co2">
            <span className="card-heading">
            {t("ImpactCO2By")} <span style={{ color: "#6353ea" }}>{t("meals")}</span>
            </span>
          </div>
          <div className="card-body">
          <PieChart
                  data={impactByMeals}
                  formatterPrefix={`%`}
                />
          </div>
        </Card>
      </Row>
      <Row className="mt-3 coeq-container">
        <div
          style={{
            marginTop: "42px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Col style={{ width: "max-content", paddingLeft: "2%" }}>
            <h2 className="title-text">{t("CarbonFootprintAnalytics")}</h2>
            <p className="sub-title-text">
              {t("analyzeFootprint")}
            </p>
          </Col>
          <Col>
            <div className="card">
              <div className="card-header d-flex align-items-center justify-content-between card-navbtns border-bottom" style={{paddingBottom: '10px'}}>
                <h2>{t("YourCarbonImpact")}</h2>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "10px", color: "#8b8f94" }}>
                <Button style={{ height: "30px", display: "flex", alignItems: "center", cursor: "pointer" }}>{t("Forecast")}</Button>
                <span>{storage.getItem("hotel_code")}</span>
                </div>
              </div>
              <div className="card-body">
                <LineChart
                  data={lineChartData}
                  dateTimeFormatter="DD/MM"
                  extendedDate={new Date(moment().format("YYYY-MM-DD")) / 1000}
                />
              </div>
            </div>
          </Col>
        </div>
      </Row>
    </div>
  );
}

export default LeftSide;
