import React, { useEffect, useState } from "react";
import Router from "../routes/router";
import { useLocation } from "react-router-dom";
import { Col, Dropdown, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import SelectLanguage from "components/selectLanguage";

import request from "services/request";
import storage from "services/storage";

import { useLoading } from "contexts/LoadingContextManagement";
import { useUserData } from "contexts/AuthContextManagement";
import { DEFAULT_ERROR_MESSAGE } from "common/constants";
import CloseSidebarIcon from "assets/images/icon/CLOSED_SIDEBAR.svg";

import TelechargerIcon from "assets/images/telecharger.png";
import SendIcon from "assets/images/send.png";
import AddMealIcon from "assets/images/add_meal.png";
import FilterIcon from "assets/images/icon/FILTER_ICON1.svg";

function Header({ handleToggle, broken }) {
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [hotels, setHotels] = useState([]);
  const currentLanguage = i18n.language;
  const getCurrentRoute = () =>
    Router.find((r) => r.path === location.pathname);
  const currentRoute = getCurrentRoute();
  const { setError } = useLoading();
  const {
    setSelectedRestaurantId,
    selectedRestaurantId,
    restaurants,
    setRestaurants,
    isFilterShown,
    setFilterShown,
    isRestaurantLoaded,
  } = useUserData();

  useEffect(async () => {
    const result = await request.get("hotels");
    setHotels(result.hotels);
}, [restaurants])
  //get user restaurants first time
  useEffect(() => {
    getUserRestaurants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //get user restaurants api
  const getUserRestaurants = async () => {
    try {
      const result = await request.get("restaurants");
      const response = await request.get("hotels");
      let _restaurants = result.restaurants;
      setRestaurants(_restaurants);
      let r = storage.getItem("selected_restaurant_id");
      if (r === null && _restaurants.length !== 0) {
        setSelectedRestaurantId(_restaurants[0].id);
        storage.setItem("brand", response?.hotels.find(hotel => hotel?.restaurants.some(i => i.id === _restaurants[0].id))?.brand_name?.toLowerCase()?.replace(" ", "_") || response?.hotels[0]?.brand_name?.toLowerCase()?.replace(" ", "_") || "fullsoon")
        storage.setItem("hotel_code", response?.hotels.find(hotel => hotel?.restaurants.some(i => i.id === _restaurants[0].id))?.code || response?.hotels[0]?.code)
        storage.setItem("selected_restaurant_id", _restaurants[0].id);
      }
      if (r != null) {
        for (let restaurant of _restaurants) {
          if (restaurant.id === r) {
            setSelectedRestaurantId(r);
            storage.setItem("brand", response?.hotels.find(hotel => hotel?.restaurants.some(i => i.id === r))?.brand_name?.toLowerCase()?.replace(" ", "_") || response?.hotels[0]?.brand_name?.toLowerCase()?.replace(" ", "_") || "fullsoon")
            storage.setItem("hotel_code", response?.hotels.find(hotel => hotel?.restaurants.some(i => i.id === r))?.code || response?.hotels[0]?.code)
            storage.setItem("selected_restaurant_id", r);
            break;
          }
        }
      }
    } catch (error) {
      console.log(error)
      //setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  //dropdown select function
  const handleRestaurantsSelect = (eventKey) => {
    for (let r of restaurants) {
      if (r.id === eventKey) {
        storage.setItem("selected_restaurant_id", r.id);
        storage.setItem("brand", hotels.find(hotel => hotel?.restaurants.some(i => i.id === eventKey))?.brand_name?.toLowerCase()?.replace(" ", "_") || hotels[0]?.brand_name?.toLowerCase()?.replace(" ", "_") || "fullsoon")
        storage.setItem("hotel_code", hotels.find(hotel => hotel?.restaurants.some(i => i.id === eventKey))?.code || hotels[0]?.code)
        setSelectedRestaurantId(r.id);
        break;
      }
    }
  };

  //Get reataurant name by its id
  const getRestaurantNameByItsId = (restaurantId) => {
    let restaurantName = "";
    for (let r of restaurants) {
      if (r.id === restaurantId) {
        restaurantName = r.name;
        break;
      }
    }

    if (isRestaurantLoaded && !selectedRestaurantId) {
      restaurantName = "Accor";
    }

    return restaurantName;
  };

  /**
   * Gets the current restaurant object by its id
   * @returns Object
   */
  const getSelectedRestaurant = () =>
    restaurants?.find((r) => r.id === selectedRestaurantId) || {};

  let date;
  if (currentRoute?.subHeading) {
    let dateArray = currentRoute?.subHeading?.split(" ");
    let month = dateArray?.length && t(dateArray[0]);
    let day = currentLanguage === 'en' ? dateArray[1] : dateArray[1]?.replace(/\D/g, "") + ",";
    let year = dateArray[2];
    date = currentLanguage === 'en' ? [month, day, year]?.join(" ") : [day.replace(/,/g, ''), month, year]?.join(" ")
  }

  return (
    <>
      <header className="header1 ">
        <Row>
          <Col lg={6} className="align-self-center pe-0">
            <div className="userheader">

              <div style={{ display: 'flex', alignItems: 'center' }}>
                {
                  broken &&
                  <img
                    src={CloseSidebarIcon}
                    alt=""
                    className={`collapsed`}
                    onClick={() => handleToggle()}
                    style={{ transform: 'rotate(180deg)', marginBottom: '0.5rem', cursor: 'pointer' }}
                  />
                }

                <h1>{t(currentRoute?.heading)}</h1>
              </div>
            </div>
          </Col>
          <Col
            lg={{ span: 3, offset: 3 }}
            className="pe-0 d-flex justify-content-end"
          >
            <li className="nav-item  userdd">
              <div className="d-flex">
                {getSelectedRestaurant()?.image_path && (
                  <img
                    src={getSelectedRestaurant()?.image_path}
                    className="user_image mt-3"
                    alt=""
                  />
                )}

                <Dropdown
                  className="restaurant_dropdown"
                  onSelect={handleRestaurantsSelect}
                >
                  <Dropdown.Toggle
                    variant="link"
                    id="dropdownMenuLink"
                    className="btn btn-transparent dropdown-toggle p-3"
                  >
                    <strong className="d-sm-inline-block dropdown-text">
                      {getRestaurantNameByItsId(selectedRestaurantId)}
                    </strong>
                  </Dropdown.Toggle>

                  {Boolean(restaurants.length) && (
                    <Dropdown.Menu>
                      {restaurants.map((r, i) => (
                        <Dropdown.Item
                          key={i}
                          eventKey={r.id}
                          className="d-flex"
                        >
                          {r.image_path && (
                            <img
                              src={r.image_path || ""}
                              className="dropdown_image mt-3 me-2"
                              alt=""
                            />
                          )}
                          <span className="dropdown_item_name">{r.name}</span>
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  )}
                </Dropdown>
                <SelectLanguage />
              </div>
            </li>
          </Col>
        </Row>
      </header>
      {currentRoute?.name !== "CO2eq" && <div className="headerbtns">
        {currentRoute?.name === "Meals" && (
          <button className="float-start add-new-btn">
            <img src={AddMealIcon} className="me-2" alt="" />
            {t("addNewMeal")}
          </button>
        )}
        {currentRoute?.name === "Stock" && (
          <button className="float-start add-new-btn">
            <img src={AddMealIcon} className="me-2" alt="" />
            {t("AddNewProduct")}
          </button>
        )}
        <button className="btn btn-white btn-icon me-3">
          <img src={TelechargerIcon} alt="" className="m-0" />
        </button>
        <button className="btn btn-white btn-icon me-3">
          <img src={SendIcon} alt="" className="m-0" />
        </button>
        {currentRoute?.filterIcon && !isFilterShown && (
          <button
            onClick={() => setFilterShown(true)}
            className="btn btn-white btn-icon btn-theme"
          >
            <img src={FilterIcon} alt="" className="m-0" />
          </button>
        )}
      </div>}
    </>
  );
}

export default Header;
