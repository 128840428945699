import React from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";
function ProgressItem({name, value, style}) {
  const { t } = useTranslation();

  return (
    <>
      <div style={style} className="progress-item">
        <p style={{ fontWeight: "700", fontSize: "16px", color: "#000000"}}>{t(name)}</p>
        <div className="progress-container" style={{ display: "flex", alignItems: "center"}}>
          <div className="progress-bar">
            <div style={{ width: `${value}%` }} className="progress-value">{value}%</div>
          </div>
          <span style={{ marginLeft: "10px", fontSize: "12px", fontWeight: "400", color: "#000000" }}>CO2(kg)</span>
        </div>
      </div>
    </>
  );
}

export default ProgressItem;
