import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import SettingsNav from "./settingsNav";
import Account from "./account";
import "./index.scss";

const TABS = [
  "Account",
];

function Settings() {
  const location = useLocation();

  const [selectedNav, setSelectedNav] = useState(() =>
    location?.state?.tab === "events" ? TABS[7] : TABS[0]
  );

  const selectedNavView = () => {
    switch (selectedNav) {
      case TABS[0]:
        return <Account />;
      default:
        return <></>;
    }
  };

  return (
    <div className="settings">
      <SettingsNav
        selectedNav={selectedNav}
        setSelectedNav={setSelectedNav}
        TABS={TABS}
      />
      {selectedNavView()}
    </div>
  );
}

export default Settings;
