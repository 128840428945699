import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import storage from "services/storage";
import useWindowSize from "customHooks/useWindowResize";
import { MAX_COLLAPISBLE_SIDEBAR } from "common/constants";
import { useUserData } from "contexts/AuthContextManagement";
import SampleRestaurantModal from "components/sampleRestaurant";

import Sidebar from "./sidebar";
import Header from "./header";
import Dashboard from "./dashboard";

function Layouts() {
  const [width] = useWindowSize();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isToggle, setIsToggle] = useState(false);
  const [broken, setBroken] = useState(window.matchMedia('(max-width: 1440px)').matches);

  const { sampleRestaurantModal, setSampleRestaurantModal } = useUserData();


  const handleToggle = () => {
    setIsToggle(true)
    setIsCollapsed(false)
  }

  if (!storage.getItem("token")) {
    return <Redirect exact to="/signin" />;
  }

  const collapsedSidebar =
    width < MAX_COLLAPISBLE_SIDEBAR || isCollapsed || false;

  return (
    <div className="wrappers">
      <Sidebar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} isToggle={isToggle} setIsToggle={setIsToggle}
        broken={broken} setBroken={setBroken} />
      <section
        className={`maincontent h-100 ${collapsedSidebar ? "maincontent-collapsed" : ""
          }`}
        style={{
          width: !isCollapsed ? "calc(100% - 250px)" : "calc(100% - 103px)",
          marginLeft: !isCollapsed ? "250px" : "103px"
        }}
      >
        <SampleRestaurantModal
          show={sampleRestaurantModal}
          onHide={() => setSampleRestaurantModal(false)}
        />
        <Header handleToggle={() => handleToggle()} broken={broken} />
        <Dashboard />
      </section>
    </div>
  );
}

export default Layouts;
