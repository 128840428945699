import React from "react";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";

import { useUserData } from "contexts/AuthContextManagement";

import Routes from "../routes/router";

function Dashboard() {
  const { id: userId } = useUserData();
  const location = useLocation();

  const getCurrentRoute = () =>
    Routes.find((r) => r.path === location.pathname);

  const currentRoute = getCurrentRoute();

  return (
    <section
      className={`dashboard ${(
        currentRoute?.name?.replace(" ", "-") ?? ""
      ).toLowerCase()}-container`}
    >
      <Switch>
        {Routes.map((prop, key) => {
          return (
            <Route
              exact={prop.exact}
              title={prop.name}
              path={prop.path}
              component={prop.component}
              key={key}
            />
          );
        })}
        <Route exact path="/">
          {
            (userId === '45eedd99-ef55-4086-92b4-885fe101aaa2') ?
            <Redirect to="/signin" /> :
            <Redirect to="/co2eq" />
          }
        </Route>
      </Switch>
    </section>
  );
}

export default Dashboard;
