export const CUTTOFF_HEIGHT = 850;
export const MAX_COLLAPISBLE_SIDEBAR = 1440;
export const DEBOUNCE_DELAY = 500;
export const ITEMS_PER_PAGE = 25;
export const localStoragePrefix = "fullsoon_";
export const DEFAULT_ERROR_MESSAGE =
  "Something went wrong please try again later";

export const RESTAURANTS_TYPES_LIST = [
  "Ethnic South Asian (Pakistan India Afghan)",
  "Ethinc Italian",
  "Ethnic Mediterranean",
  "Ethinc sub-Saharan",
  "Ethnic North African",
  "Ethnic South American",
  "Ethnic Mexican",
  "Ethnic Japanese",
  "Ethnic Chinese",
  "Ethnic Korean",
  "Ethnic - Vietnamese",
  "Ethnic Balkan",
  "Ethnic French",
  "Fast Food",
  "Fast Casual",
  "Casual Dinning",
  "Premium casual",
  "Family style",
  "Fine dining",
  "Brasserie and Bistro",
  "Buffet",
  "Cafeteria",
  "Coffee House",
  "Destination Restaurant",
  "Pub",
  "Night Club",
];

export const CURRENCIES_LIST = [
  { name: "usd", code: "840", symbol: "$" },
  { name: "gbp", code: "826", symbol: "£" },
  { name: "eur", code: "978", symbol: "€" },
  { name: "cad", code: "124", symbol: "$" },
];

export const EXPIRY_OPTIONS = [
  { label: "Good", value: "good", id: 1 },
  { label: "Soon", value: "soon", id: 2 },
  { label: "Expired", value: "expired", id: 3 },
  { label: "Not Expired", value: "no_expiry", id: 4 },
];

export const COOKING_OPTIONS = [
  { id: 'raw', label: 'Raw', value: 'raw' },
  { id: 'cooked', label: 'Cooked', value: 'cooked' },
  { id: 'canned', label: 'Canned', value: 'canned' },
  { id: 'oven grilled', label: 'Oven Grilled', value: 'oven grilled' },
  { id: 'ready-to-drink', label: 'Ready-to-Drink', value: 'ready-to-drink' },
  { id: 'powder', label: 'Powder', value: 'powder' },
  { id: 'filled', label: 'Filled', value: 'filled' },
  { id: 'reconstituted', label: 'Reconstituted', value: 'reconstituted' },
  { id: 'extruded and grilled', label: 'Extruded and Grilled', value: 'extruded and grilled' },
  { id: 'fermented', label: 'Fermented', value: 'fermented' },
  { id: 'puffed/popped', label: 'Puffed/Popped', value: 'puffed/popped' },
  { id: 'not instant', label: 'Not Instant', value: 'not instant' },
  { id: 'roasted/baked', label: 'Roasted/Baked', value: 'roasted/baked' },
  { id: 'on skewer', label: 'On Skewer', value: 'on skewer' },
  { id: 'semi-dry', label: 'Semi-Dry', value: 'semi-dry' },
  { id: 'grilled/pan-fried', label: 'Grilled/Pan-Fried', value: 'grilled/pan-fried' },
  { id: 'textured', label: 'Textured', value: 'textured' },
  { id: 'still', label: 'Still', value: 'still' },
  { id: 'braised or boiled', label: 'Braised or Boiled', value: 'braised or boiled' },
  { id: 'roast', label: 'Roast', value: 'roast' },
  { id: 'boiled/cooked', label: 'Boiled/Cooked', value: 'boiled/cooked' },
  { id: 'ripe', label: 'Ripe', value: 'ripe' },
  { id: 'whole', label: 'Whole', value: 'whole' },
  { id: 'prepacked', label: 'Prepacked', value: 'prepacked' },
  { id: 'to be filled', label: 'To Be Filled', value: 'to be filled' },
  { id: 'braised', label: 'Braised', value: 'braised' },
  { id: 'cooked in water', label: 'Cooked in Water', value: 'cooked in water' },
  { id: 'immature', label: 'Immature', value: 'immature' },
  { id: 'fresh', label: 'Fresh', value: 'fresh' },
  { id: 'puree', label: 'Puree', value: 'puree' },
  { id: 'dry', label: 'Dry', value: 'dry' },
  { id: 'grilled', label: 'Grilled', value: 'grilled' },
  { id: 'in olive oil', label: 'In Olive Oil', value: 'in olive oil' },
  { id: 'old', label: 'Old', value: 'old' },
  { id: 'toasts', label: 'Toasts', value: 'toasts' },
  { id: 'lean', label: 'Lean', value: 'lean' },
  { id: 'marinated', label: 'Marinated', value: 'marinated' },
  { id: 'refrigerated', label: 'Refrigerated', value: 'refrigerated' },
  { id: 'with bordelaise sauce', label: 'With Bordelaise Sauce', value: 'with bordelaise sauce' },
  { id: 'cooked, sautéed/pan-fried', label: 'Cooked, Sautéed/Pan-Fried', value: 'cooked, sautéed/pan-fried' },
  { id: 'caramelized', label: 'Caramelized', value: 'caramelized' },
  { id: 'non rehydrated', label: 'Non Rehydrated', value: 'non rehydrated' },
  { id: 'fried', label: 'Fried', value: 'fried' },
  { id: 'steamed', label: 'Steamed', value: 'steamed' },
  { id: 'cooked, roast', label: 'Cooked, Roast', value: 'cooked, roast' },
  { id: 'stewed', label: 'Stewed', value: 'stewed' },
  { id: 'in crust', label: 'In Crust', value: 'in crust' },
  { id: 'crunchy', label: 'Crunchy', value: 'crunchy' },
  { id: 'grilled, pan-fried', label: 'Grilled, Pan-Fried', value: 'grilled, pan-fried' },
  { id: 'refined', label: 'Refined', value: 'refined' },
  { id: 'not drained', label: 'Not Drained', value: 'not drained' },
  { id: 'popped', label: 'Popped', value: 'popped' },
  { id: 'young leaves', label: 'Young Leaves', value: 'young leaves' },
  { id: 'fillets', label: 'Fillets', value: 'fillets' },
  { id: 'prepared', label: 'Prepared', value: 'prepared' },
  { id: 'in Catalan sauce', label: 'In Catalan Sauce', value: 'in Catalan sauce' },
  { id: 'breaded', label: 'Breaded', value: 'breaded' },
  { id: 'stuffed', label: 'Stuffed', value: 'stuffed' },
  { id: 'pan-fried', label: 'Pan-Fried', value: 'pan-fried' },
  { id: 'poached', label: 'Poached', value: 'poached' },
  { id: 'with curry and coconut milk sauce', label: 'With Curry and Coconut Milk Sauce', value: 'with curry and coconut milk sauce' },
  { id: 'plain', label: 'Plain', value: 'plain' },
  { id: 'dried', label: 'Dried', value: 'dried' },
  { id: 'to shell', label: 'To Shell', value: 'to shell' },
  { id: 'in sauce', label: 'In Sauce', value: 'in sauce' },
  { id: 'coulis', label: 'Coulis', value: 'coulis' },
  { id: 'spreadable', label: 'Spreadable', value: 'spreadable' },
  { id: 'green', label: 'Green', value: 'green' },
  { id: 'in season', label: 'In Season', value: 'in season' },
  { id: 'pre-cooked', label: 'Pre-Cooked', value: 'pre-cooked' },
  { id: 'Basque style', label: 'Basque Style', value: 'Basque style' },
  { id: 'crunchy (thin or dry)', label: 'Crunchy (Thin or Dry)', value: 'crunchy (thin or dry)' },
  { id: 'cooked, to pan-fry', label: 'Cooked, To Pan-Fry', value: 'cooked, to pan-fry' },
  { id: 'ground', label: 'Ground', value: 'ground' },
  { id: 'in puff pastry', label: 'In Puff Pastry', value: 'in puff pastry' },
  { id: 'toasted', label: 'Toasted', value: 'toasted' },
  { id: 'cured', label: 'Cured', value: 'cured' },
  { id: 'in salad dressing sauce', label: 'In Salad Dressing Sauce', value: 'in salad dressing sauce' },
  { id: 'iced', label: 'Iced', value: 'iced' },
  { id: 'instant', label: 'Instant', value: 'instant' },
  { id: 'delicatessen style', label: 'Delicatessen Style', value: 'delicatessen style' },
  { id: 'pan-reheated', label: 'Pan-Reheated', value: 'pan-reheated' },
  { id: 'soft ripened', label: 'Soft Ripened', value: 'soft ripened' },
  { id: 'cooked, braised or boiled', label: 'Cooked, Braised or Boiled', value: 'cooked, braised or boiled' },
  { id: 'in mustard sauce', label: 'In Mustard Sauce', value: 'in mustard sauce' },
  { id: 'for soups', label: 'For Soups', value: 'for soups' },
  { id: 'sautéed/pan-fried', label: 'Sautéed/Pan-Fried', value: 'sautéed/pan-fried' },
  { id: 'boiled/cooked in water', label: 'Boiled/Cooked in Water', value: 'boiled/cooked in water' },
  { id: 'rolled', label: 'Rolled', value: 'rolled' },
  { id: 'stir-fried', label: 'Stir-Fried', value: 'stir-fried' },
  { id: 'flaked', label: 'Flaked', value: 'flaked' },
  { id: 'without sugar', label: 'Without Sugar', value: 'without sugar' },
  { id: 'not filled', label: 'Not Filled', value: 'not filled' },
  { id: 'with mustard sauce', label: 'With Mustard Sauce', value: 'with mustard sauce' },
  { id: 'au gratin', label: 'Au Gratin', value: 'au gratin' },
  { id: 'virgin', label: 'Virgin', value: 'virgin' },
  { id: 'pre-fried, raw', label: 'Pre-Fried, Raw', value: 'pre-fried, raw' },
  { id: 'cooked, boiled', label: 'Cooked, Boiled', value: 'cooked, boiled' },
  { id: 'dehydrated', label: 'Dehydrated', value: 'dehydrated' },
  { id: 'in a shallot and white wine broth', label: 'In a Shallot and White Wine Broth', value: 'in a shallot and white wine broth' },
  { id: 'home-made', label: 'Home-Made', value: 'home-made' },
  { id: 'in parsley butter', label: 'In Parsley Butter', value: 'in parsley butter' },
  { id: 'hard-boiled', label: 'Hard-Boiled', value: 'hard-boiled' },
  { id: 'cooked, fried', label: 'Cooked, Fried', value: 'cooked, fried' },
  { id: 'raw, intended to be roasted/baked', label: 'Raw, Intended to Be Roasted/Baked', value: 'raw, intended to be roasted/baked' },
  { id: 'smoked', label: 'Smoked', value: 'smoked' },
  { id: 'pre-fried', label: 'Pre-Fried', value: 'pre-fried' },
  { id: 'boiled', label: 'Boiled', value: 'boiled' },
  { id: 'dry-cured', label: 'Dry-Cured', value: 'dry-cured' },
  { id: 'regular', label: 'Regular', value: 'regular' },
  { id: 'mashed', label: 'Mashed', value: 'mashed' },
  { id: 'cooked in red wine sauce', label: 'Cooked in Red Wine Sauce', value: 'cooked in red wine sauce' },
  { id: 'made with French bread, chicken', label: 'Made with French Bread, Chicken', value: 'made with French bread, chicken' },
  { id: 'diced', label: 'Diced', value: 'diced' },
  { id: 'fried or pan-fried', label: 'Fried or Pan-Fried', value: 'fried or pan-fried' },
  { id: 'popped or puffed', label: 'Popped or Puffed', value: 'popped or puffed' },
  { id: 'dark', label: 'Dark', value: 'dark' },
  { id: 'soft-boiled', label: 'Soft-Boiled', value: 'soft-boiled' },
  { id: 'Milanese-style', label: 'Milanese-Style', value: 'Milanese-style' },
  { id: 'salted', label: 'Salted', value: 'salted' },
  { id: 'peeled', label: 'Peeled', value: 'peeled' },
  { id: 'carbonated', label: 'Carbonated', value: 'carbonated' },
  { id: 'rehydrated', label: 'Rehydrated', value: 'rehydrated' },
  { id: 'deep-fried', label: 'Deep-Fried', value: 'deep-fried' },
  { id: 'with starch', label: 'With Starch', value: 'with starch' },
  { id: 'baked', label: 'Baked', value: 'baked' },
  { id: 'frozen', label: 'Frozen', value: 'frozen' },
  { id: 'fillet', label: 'Fillet', value: 'fillet' },
  { id: 'stewing', label: 'Stewing', value: 'stewing' },
  { id: 'puffed', label: 'Puffed', value: 'puffed' },
  { id: 'in oil', label: 'In Oil', value: 'in oil' },
  { id: 'low calorie', label: 'Low Calorie', value: 'low calorie' },
  { id: 'roasted', label: 'Roasted', value: 'roasted' },
  { id: 'without banana', label: 'Without Banana', value: 'without banana' },
  { id: 'slow-simmered', label: 'Slow-Simmered', value: 'slow-simmered' },
  { id: 'crispy', label: 'Crispy', value: 'crispy' },
  { id: 'with sauce', label: 'With Sauce', value: 'with sauce' },
  { id: 'microwaved', label: 'Microwaved', value: 'microwaved' },
  { id: 'braised/boiled', label: 'Braised/Boiled', value: 'braised/boiled' },
  { id: 'eviscerated', label: 'Eviscerated', value: 'eviscerated' },
  { id: 'cooked, pre-fried', label: 'Cooked, Pre-Fried', value: 'cooked, pre-fried' },
  { id: 'flamed', label: 'Flamed', value: 'flamed' },
  { id: 'no precision', label: 'No Precision', value: 'no precision' },
  { id: 'pure', label: 'Pure', value: 'pure' },
  { id: 'scrambled', label: 'Scrambled', value: 'scrambled' },
  { id: 'toasted, fried', label: 'Toasted, Fried', value: 'toasted, fried' },
  { id: 'drained', label: 'Drained', value: 'drained' }
];


export const ALLERGENS = [
  { id: "SelectAllergen", value: "", label: "Select Allergen" },
  { id: "dairy", value: "daily", label: "Dairy" },
  { id: "gluten", value: "gluten", label: "Gluten" },
  { id: "sesame", value: "sesame", label: "Sesame" },
  { id: "nuts", value: "nuts", label: "Nuts" },
  { id: "fish", value: "fish", label: "Fish" },
  { id: "soya", value: "soya", label: "Soya" },
  { id: "eggs", value: "eggs", label: "Eggs" },
  { id: "crustaceans", value: "crustaceans", label: "Crustaceans" },
  { id: "lupin", value: "lupin", label: "Lupin" },
  { id: "molluscs", value: "molluscs", label: "Molluscs" },
  { id: "mustard", value: "mustard", label: "Mustard" },
  { id: "sulphites", value: "sulphites", label: "Sulphites" },
]

export const CO2_UNITS = [
  { id: "g", value: "g", label: "gram (g)" },
  { id: "kg", value: "kg", label: "kilogram (kg)" },
  { id: "l", value: "l", label: "litre (l)" },
]

// export const UNITS = [
//   { id: "mm", value: "mm", label: "millimeter (mm)" },
//   { id: "cm", value: "cm", label: "centimeter (cm)" },
//   { id: "ml", value: "ml", label: "milliliter (ml)" },
//   { id: "cl", value: "cl", label: "centiliter (cl)" },// new
//   { id: "dL", value: "dL", label: "deciliter (dL)" },
//   { id: "mg", value: "mg", label: "milligram (mg)" },
//   { id: "g", value: "g", label: "gram (g)" },
//   { id: "kg", value: "kg", label: "kilogram (kg)" },
//   { id: "unit", value: "unit", label: "Unit" },
//   { id: "m", value: "m", label: "meter (m)" },
//   { id: "l", value: "l", label: "litre (l)" },
//   { id: "unity", value: "unity", label: "unity" },
//   { id: "oz", value: "oz", label: "ounce (oz)" },
//   { id: "fl oz", value: "fl oz", label: "fluid ounce (fl oz)" },
//   { id: "gal", value: "gal", label: "gallon (gal)" },
//   { id: "gill", value: "gill", label: "gill (1/2 cup)" },
//   { id: "in", value: "in", label: "inch (in)" },
//   { id: "c", value: "c", label: "cup (c)" },
//   { id: "pt", value: "pt", label: "pint (pt)" },
//   { id: "lb", value: "lb", label: "pound (lb)" },
//   { id: "qt", value: "qt", label: "quart (qt)" },
//   { id: "tsp.", value: "tsp.", label: "teaspoon (tsp.)" },
//   { id: "tbsp.", value: "tbsp.", label: "tablespoon (tbsp.)" },
//   // { id: "PIECE", value: "item", label: "Item" },
//   { id: "item", value: "item", label: "Item" },
// ];

export const UNITS = [
  { id: "SelectUnit", value: "", label: "Select Unit" },
  { id: "g", value: "g", label: "gram (g)" },
  { id: "kg", value: "kg", label: "kilogram (kg)" },
  { id: "cl", value: "cl", label: "centiliter (cl)" },
  { id: "ml", value: "ml", label: "milliliter (ml)" },
  { id: "l", value: "l", label: "litre (l)" },
  { id: "item", value: "item", label: "item" },
  { id: "cm", value: "cm", label: "centimeter (cm)" },
  { id: "m", value: "m", label: "meter (m)" },
]

export const STORAGE_UNITS = [
  { id: "SelectUnit", value: "", label: "Select Unit" },
  { id: "conditioning", value: "conditioning", label: "Conditioning" },
  { id: "recipe", value: "recipe", label: "Recipe" },
];

export const INGREDIENT_CATEGORIES = [
  { id: "SelectCategory", value: "", label: "Select Category" },
  { id: "eggs", value: "eggs", label: "Eggs" },
  { id: "milks", value: "milks", label: "Milks" },
  { id: "milkProduct", value: "milkProduct", label: "Milk Product" },
  { id: "drinks", value: "drinks", label: "Drinks" },
  { id: "oilsAndButter", value: "oilsAndButter", label: "Oils & Butter" },
  { id: "fatsAndOils", value: "fatsAndOils", label: "Fats & Oils" },
  { id: "fruits", value: "fruits", label: "Fruits" },
  { id: "fruitsAndVegetables", value: "fruitsAndVegetables", label: "Fruits & Vegetables"},
  { id: "pastaAndRiceAndLegumes", value: "pastaAndRiceAndLegumes", label: "Pasta, Rice and Legumes"},
  { id: "sauces", value: "sauces", label: "Sauces"},
  { id: "pasta", value: "pasta", label: "Pasta"},
  { id: "breadsAndBakery", value: "breadsAndBakery", label: "Breads & Bakery"},
  { id: "riceAndPulses", value: "riceAndPulses", label: "Rice & Pulses"},
  { id: "cheese", value: "cheese", label: "Cheese"},
  { id: "packaging", value: "packaging", label: "Packaging"},
  { id: "cleaning", value: "cleaning", label: "Cleaning"},
  {
    id: "gnbProducts",
    value: "gnbProducts",
    label: "Grain, Nuts & Baking Products",
  },
  { id: "herbsAndSpices", value: "herbsAndSpices", label: "Herbs and Spices" },
  { id: "meatFish", value: "meatFish", label: "Meat Fish" },
  { id: "vegetables", value: "vegetables", label: "Vegetables" },
  { id: "pulses", value: "pulses", label: "Pasta, Rice and pulses" },
  { id: "sauce", value: "sauce", label: "sauce" },
  {
    id: "cheeseAndButter",
    value: "cheeseAndButter",
    label: "Cheese and Butter",
  },
  { id: "brasserie", value: "brasserie", label: "Brasserie" },
  { id: "consommableCHR", value: "consommableCHR", label: "Consommable CHR" },
  { id: "oph", value: "oph", label: "OPH" },
  { id: "dryGroceries", value: "dryGroceries", label: "Dry Groceries" },
  {
    id: "desktopFurnitures",
    value: "desktopFurnitures",
    label: "Desktop Furnitures",
  },
  { id: "caterer", value: "caterer", label: "Caterer" },
  { id: "frozen", value: "frozen", label: "Frozen" },
  { id: "others", value: "others", label: "Others" },
];

export const PROVIDER_INGREDIENT_CATEGORIES = [
  { id: "eggs", value: "eggs", label: "Eggs" },
  { id: "milkProduct", value: "milkProduct", label: "Milk Product" },
  { id: "drinks", value: "drinks", label: "Drinks" },
  { id: "fatsAndOils", value: "fatsAndOils", label: "Fats & Oils" },
  { id: "fruits", value: "fruits", label: "Fruits" },
  {
    id: "gnbProducts",
    value: "gnbProducts",
    label: "Grain, Nuts & Baking Products",
  },
  { id: "herbsAndSpices", value: "herbsAndSpices", label: "Herbs and Spices" },
  { id: "meatAndFish", value: "meatAndFish", label: "Meat & Fish" },
  { id: "vegetables", value: "vegetables", label: "Vegetables" },
  { id: "pulses", value: "pulses", label: "Pasta, Rice and pulses" },
  { id: "sauce", value: "sauce", label: "sauce" },
  {
    id: "cheeseAndButter",
    value: "cheeseAndButter",
    label: "Cheese and Butter",
  },
  { id: "brasserie", value: "brasserie", label: "Brasserie" },
  { id: "consommableCHR", value: "consommableCHR", label: "Consommable CHR" },
  { id: "oph", value: "oph", label: "OPH" },
  { id: "dryGroceries", value: "dryGroceries", label: "Dry Groceries" },
  {
    id: "desktopFurnitures",
    value: "desktopFurnitures",
    label: "Desktop Furnitures",
  },
  { id: "caterer", value: "caterer", label: "Caterer" },
  { id: "frozen", value: "frozen", label: "Frozen" },
  { id: "others", value: "others", label: "Others" },
];

export const CARBON_MEAL_CATEGORIES = [
  { id: "option", value: "option", label: "Option" },
  { id: "redMeat", value: "redMeat", label: "Red Meat" },
  { id: "whiteMeat", value: "whiteMeat", label: "White Meat" },
  { id: "fish", value: "fish", label: "Fish" },
  { id: "vegetarian", value: "vegetarian", label: "Vegetarian" },
  { id: "vegan", value: "vegan", label: "Vegan" },
]

export const POS = [
  { id: "silverware", value: "silverware", label: "Silverware"},
  { id: "simphony", value: "Simphony", label: "Simphony" },
  { id: "mews", value: "mews", label: "Mews" },
  { id: "other", value: "other", label: "Other" },
];



export const BRANDS = [
  { id: 'generic', label: 'GENERIC' },
  { id: '25h', label: '25H' },
  { id: 'angsana', label: 'ANGSANA' },
  { id: "fairmont", label: 'FAIRMONT' },
  { id: 'adagio', label: 'ADAGIO' },
  { id: 'art_series', label: 'ART SERIES' },
  { id: 'beyan_tree', label: 'BEYAN TREE' },
  { id: 'break_free', label: 'BREAK FREE' },
  { id: 'delano', label: 'DELANO' },
  { id: 'emblems', label: 'EMBLEMS' },
  { id: 'glene_eagles', label: 'GLENE EAGLES' },
  { id: 'greet', label: 'GREET' },
  { id: 'hotel_f1', label: 'HOTEL F1' },
  { id: 'hyde', label: 'HYDE' },
  { id: 'ibis', label: 'IBIS' },
  { id: 'ibis_styles', label: 'IBIS STYLES' },
  { id: 'jo$joe', label: 'JO$JOE' },
  { id: 'mama_shelter', label: 'MAMA SHELTER' },
  { id: 'mantis', label: 'MANTIS' },
  { id: 'mantra', label: 'MANTRA' },
  { id: 'mercure_group', label: 'MERCURE GROUP' },
  { id: 'mondrian', label: 'MONDRIAN' },
  { id: 'mercure_hotel', label: 'MERCURE HOTEL' },
  { id: 'morganoriginals', label: 'MORGANORIGINALS' },
  { id: 'movenpick', label: 'MOVENPICK' },
  { id: 'museum_hotel', label: 'MUSEUM HOTEL' },
  { id: 'novotel', label: 'NOVOTEL' },
  { id: 'onefinestay', label: 'ONEFINESTAY' },
  { id: 'orient_express', label: 'ORIENT EXPRESS' },
  { id: 'peppers', label: 'PEPPERS' },
  { id: 'pullman', label: 'PULLMAN' },
  { id: 'raffles', label: 'RAFFLES' },
  { id: 'rixos', label: 'RIXOS' },
  { id: 'sls', label: 'SLS' },
  { id: 'so', label: 'SO' },
  { id: 'sofitel', label: 'SOFITEL' },
  { id: 'sotitel_legends', label: 'SOTITEL LEGENDS' },
  { id: 'swisshotel', label: 'SWISSHOTEL' },
  { id: 'the_hoxton', label: 'THE HOXTON' },
  { id: 'the_sebel', label: 'THE SEBEL' },
  { id: 'tribe', label: 'TRIBE' },
  { id: 'workingfrom', label: 'WORKINGFROM' }
]

export const ENERGY = [
  { id: "chooseYourEnergy", value: "", label: "ChooseYourEnergy" },
  { id: "gas", value: "gas", label: "Gas" },
  { id: "biogas", value: "biogas", label: "Biogas" },
  { id: "electricity", value: "electricity", label: "Electricity" },
  { id: "greenElectricity", value: "greenElectricity", label: "GreenElectricity" }
]

export const MEAL_CATEGORIES = [
  // { id: "appetizers", value: "appetizers", label: "Appetizers" },
  { id: "starters", value: "starters", label: "Starters" },
  { id: "mainDishes", value: "mainDishes", label: "Main dishes" },
  { id: "desserts", value: "desserts", label: "Desserts" },
  // { id: "drinks", value: "drinks", label: "Drinks" },
  // { id: "intermediate-recipe", value: "intermediate-recipe", label: "Intermediate Recipe" },
];

export const MEAL_CATEGORIES_DEMO = [
  { id: "starters", value: "starters", label: "Starters" },
  { id: "mainDishes", value: "mainDishes", label: "Main dishes" },
  { id: "desserts", value: "desserts", label: "Desserts" },
];

export const SERVINGS = [
  { id: 1, label: "1 person" },
  { id: 2, label: "2 persons" },
  { id: 3, label: "3 persons" },
  { id: 4, label: "4 persons" },
  { id: 5, label: "5 persons" },
  { id: 6, label: "6 persons" },
];

export const TIMES_CHECKBOXES = [
  {
    name: "breakfast",
    label: "Breakfast (00.00 - 11.00)",
    time: "00:00-10:59",
  },
  { 
    name: "lunch", 
    label: "Lunch (11.00 - 15.00)", 
    time: "11:00-14:59" 
  },
  {
    name: "afternoon",
    label: "Afternoon (15.00 - 18.00)",
    time: "15:00-17:59",
  },
  { 
    name: "dinner", 
    label: "Dinner (18.00 - 00:00)", 
    time: "18:00-23:59" 
  },
];

export const TIME_DURATIONS = {
  allDay: ["00:00", "23:59"],
  breakfast: ["00:00", "10:59"],
  lunch: ["11:00", "14:59"],
  afternoon: ["15:00", "17:59"],
  dinner: ["18:00", "23:59"],
};


export const EVENT_TYPES = [
  {id: "sports", label: "Sports", value: "sports" },
  {id: "cultural", label: "Cultural", value: "cultural" },
  {id: "internal", label: "Internal", value: "internal" },
  {id: "professional", label: "Professional", value: "professional" },
  {id: "exceptional_discount", label: "Exceptional Discount", value: "exceptional_discount" },
  {id: "closing", label: "Closing", value: "closing" },
  {id: "other", label: "Other", value: "other" },

];

export const TIME_ZONES = [
  "Europe/Andorra",
  "Asia/Dubai",
  "Asia/Kabul",
  "Europe/Tirane",
  "Asia/Yerevan",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville",
  "Antarctica/Mawson",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Cordoba",
  "America/Argentina/Salta",
  "America/Argentina/Jujuy",
  "America/Argentina/Tucuman",
  "America/Argentina/Catamarca",
  "America/Argentina/La_Rioja",
  "America/Argentina/San_Juan",
  "America/Argentina/Mendoza",
  "America/Argentina/San_Luis",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Ushuaia",
  "Pacific/Pago_Pago",
  "Europe/Vienna",
  "Australia/Lord_Howe",
  "Antarctica/Macquarie",
  "Australia/Hobart",
  "Australia/Currie",
  "Australia/Melbourne",
  "Australia/Sydney",
  "Australia/Broken_Hill",
  "Australia/Brisbane",
  "Australia/Lindeman",
  "Australia/Adelaide",
  "Australia/Darwin",
  "Australia/Perth",
  "Australia/Eucla",
  "Asia/Baku",
  "America/Barbados",
  "Asia/Dhaka",
  "Europe/Brussels",
  "Europe/Sofia",
  "Atlantic/Bermuda",
  "Asia/Brunei",
  "America/La_Paz",
  "America/Noronha",
  "America/Belem",
  "America/Fortaleza",
  "America/Recife",
  "America/Araguaina",
  "America/Maceio",
  "America/Bahia",
  "America/Sao_Paulo",
  "America/Campo_Grande",
  "America/Cuiaba",
  "America/Santarem",
  "America/Porto_Velho",
  "America/Boa_Vista",
  "America/Manaus",
  "America/Eirunepe",
  "America/Rio_Branco",
  "America/Nassau",
  "Asia/Thimphu",
  "Europe/Minsk",
  "America/Belize",
  "America/St_Johns",
  "America/Halifax",
  "America/Glace_Bay",
  "America/Moncton",
  "America/Goose_Bay",
  "America/Blanc-Sablon",
  "America/Toronto",
  "America/Nipigon",
  "America/Thunder_Bay",
  "America/Iqaluit",
  "America/Pangnirtung",
  "America/Atikokan",
  "America/Winnipeg",
  "America/Rainy_River",
  "America/Resolute",
  "America/Rankin_Inlet",
  "America/Regina",
  "America/Swift_Current",
  "America/Edmonton",
  "America/Cambridge_Bay",
  "America/Yellowknife",
  "America/Inuvik",
  "America/Creston",
  "America/Dawson_Creek",
  "America/Fort_Nelson",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Dawson",
  "Indian/Cocos",
  "Europe/Zurich",
  "Africa/Abidjan",
  "Pacific/Rarotonga",
  "America/Santiago",
  "America/Punta_Arenas",
  "Pacific/Easter",
  "Asia/Shanghai",
  "Asia/Urumqi",
  "America/Bogota",
  "America/Costa_Rica",
  "America/Havana",
  "Atlantic/Cape_Verde",
  "America/Curacao",
  "Indian/Christmas",
  "Asia/Nicosia",
  "Asia/Famagusta",
  "Europe/Prague",
  "Europe/Berlin",
  "Europe/Copenhagen",
  "America/Santo_Domingo",
  "Africa/Algiers",
  "America/Guayaquil",
  "Pacific/Galapagos",
  "Europe/Tallinn",
  "Africa/Cairo",
  "Africa/El_Aaiun",
  "Europe/Madrid",
  "Africa/Ceuta",
  "Atlantic/Canary",
  "Europe/Helsinki",
  "Pacific/Fiji",
  "Atlantic/Stanley",
  "Pacific/Chuuk",
  "Pacific/Pohnpei",
  "Pacific/Kosrae",
  "Atlantic/Faroe",
  "Europe/Paris",
  "Europe/London",
  "Asia/Tbilisi",
  "America/Cayenne",
  "Africa/Accra",
  "Europe/Gibraltar",
  "America/Godthab",
  "America/Danmarkshavn",
  "America/Scoresbysund",
  "America/Thule",
  "Europe/Athens",
  "Atlantic/South_Georgia",
  "America/Guatemala",
  "Pacific/Guam",
  "Africa/Bissau",
  "America/Guyana",
  "Asia/Hong_Kong",
  "America/Tegucigalpa",
  "America/Port-au-Prince",
  "Europe/Budapest",
  "Asia/Jakarta",
  "Asia/Pontianak",
  "Asia/Makassar",
  "Asia/Jayapura",
  "Europe/Dublin",
  "Asia/Jerusalem",
  "Asia/Kolkata",
  "Indian/Chagos",
  "Asia/Baghdad",
  "Asia/Tehran",
  "Atlantic/Reykjavik",
  "Europe/Rome",
  "America/Jamaica",
  "Asia/Amman",
  "Asia/Tokyo",
  "Africa/Nairobi",
  "Asia/Bishkek",
  "Pacific/Tarawa",
  "Pacific/Enderbury",
  "Pacific/Kiritimati",
  "Asia/Pyongyang",
  "Asia/Seoul",
  "Asia/Almaty",
  "Asia/Qyzylorda",
  "Asia/Qostanay", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  "Asia/Aqtobe",
  "Asia/Aqtau",
  "Asia/Atyrau",
  "Asia/Oral",
  "Asia/Beirut",
  "Asia/Colombo",
  "Africa/Monrovia",
  "Europe/Vilnius",
  "Europe/Luxembourg",
  "Europe/Riga",
  "Africa/Tripoli",
  "Africa/Casablanca",
  "Europe/Monaco",
  "Europe/Chisinau",
  "Pacific/Majuro",
  "Pacific/Kwajalein",
  "Asia/Yangon",
  "Asia/Ulaanbaatar",
  "Asia/Hovd",
  "Asia/Choibalsan",
  "Asia/Macau",
  "America/Martinique",
  "Europe/Malta",
  "Indian/Mauritius",
  "Indian/Maldives",
  "America/Mexico_City",
  "America/Cancun",
  "America/Merida",
  "America/Monterrey",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Chihuahua",
  "America/Ojinaga",
  "America/Hermosillo",
  "America/Tijuana",
  "America/Bahia_Banderas",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Africa/Maputo",
  "Africa/Windhoek",
  "Pacific/Noumea",
  "Pacific/Norfolk",
  "Africa/Lagos",
  "America/Managua",
  "Europe/Amsterdam",
  "Europe/Oslo",
  "Asia/Kathmandu",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "America/Panama",
  "America/Lima",
  "Pacific/Tahiti",
  "Pacific/Marquesas",
  "Pacific/Gambier",
  "Pacific/Port_Moresby",
  "Pacific/Bougainville",
  "Asia/Manila",
  "Asia/Karachi",
  "Europe/Warsaw",
  "America/Miquelon",
  "Pacific/Pitcairn",
  "America/Puerto_Rico",
  "Asia/Gaza",
  "Asia/Hebron",
  "Europe/Lisbon",
  "Atlantic/Madeira",
  "Atlantic/Azores",
  "Pacific/Palau",
  "America/Asuncion",
  "Asia/Qatar",
  "Indian/Reunion",
  "Europe/Bucharest",
  "Europe/Belgrade",
  "Europe/Kaliningrad",
  "Europe/Moscow",
  "Europe/Simferopol",
  "Europe/Kirov",
  "Europe/Astrakhan",
  "Europe/Volgograd",
  "Europe/Saratov",
  "Europe/Ulyanovsk",
  "Europe/Samara",
  "Asia/Yekaterinburg",
  "Asia/Omsk",
  "Asia/Novosibirsk",
  "Asia/Barnaul",
  "Asia/Tomsk",
  "Asia/Novokuznetsk",
  "Asia/Krasnoyarsk",
  "Asia/Irkutsk",
  "Asia/Chita",
  "Asia/Yakutsk",
  "Asia/Khandyga",
  "Asia/Vladivostok",
  "Asia/Ust-Nera",
  "Asia/Magadan",
  "Asia/Sakhalin",
  "Asia/Srednekolymsk",
  "Asia/Kamchatka",
  "Asia/Anadyr",
  "Asia/Riyadh",
  "Pacific/Guadalcanal",
  "Indian/Mahe",
  "Africa/Khartoum",
  "Europe/Stockholm",
  "Asia/Singapore",
  "America/Paramaribo",
  "Africa/Juba",
  "Africa/Sao_Tome",
  "America/El_Salvador",
  "Asia/Damascus",
  "America/Grand_Turk",
  "Africa/Ndjamena",
  "Indian/Kerguelen",
  "Asia/Bangkok",
  "Asia/Dushanbe",
  "Pacific/Fakaofo",
  "Asia/Dili",
  "Asia/Ashgabat",
  "Africa/Tunis",
  "Pacific/Tongatapu",
  "Europe/Istanbul",
  "America/Port_of_Spain",
  "Pacific/Funafuti",
  "Asia/Taipei",
  "Europe/Kiev",
  "Europe/Uzhgorod",
  "Europe/Zaporozhye",
  "Pacific/Wake",
  "America/New_York",
  "America/Detroit",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Indiana/Indianapolis",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Chicago",
  "America/Indiana/Tell_City",
  "America/Indiana/Knox",
  "America/Menominee",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/North_Dakota/Beulah",
  "America/Denver",
  "America/Boise",
  "America/Phoenix",
  "America/Los_Angeles",
  "America/Anchorage",
  "America/Juneau",
  "America/Sitka",
  "America/Metlakatla",
  "America/Yakutat",
  "America/Nome",
  "America/Adak",
  "Pacific/Honolulu",
  "America/Montevideo",
  "Asia/Samarkand",
  "Asia/Tashkent",
  "America/Caracas",
  "Asia/Ho_Chi_Minh",
  "Pacific/Efate",
  "Pacific/Wallis",
  "Pacific/Apia",
  "Africa/Johannesburg",
];

export const WEEKDAYS = [
  {id: 0,day: "Monday"},
  {id: 1,day: "Tuesday"},
  {id: 2,day: "Wednesday"},
  {id: 3,day: "Thursday"},
  {id: 4,day: "Friday"},
  {id: 5,day: "Saturday"},
  {id: 6,day: "Sunday"},
];

export const DUMMY_INGREDIENTS = [
  "Milk",
  "Cream",
  "Tomatoes",
  "Cheese",
  "Pasta",
  "Sugar",
  "Flour",
  "Butter",
  "Buttermilk",
  "Chili Sauce",
  "Egg",
  "Garlic",
  "Ketchup",
  "Onion",
  "Marshmallows",
  "Mayonnaise",
  "Rice",
  "Yeast",
];

export const LANGUAGES = [
  { name: "English", code: "en" },
  { name: "French", code: "fr" },
];

export const COUNTRY_CODE_OPTIONS = [
  { id: 'AF', value: "Afghanistan", label: 'Afghanistan' },
  { id: 'AL', value: "Albania", label: 'Albania' },
  { id: 'DZ', value: "Algeria", label: 'Algeria' },
  { id: 'AS', value: "American Samoa", label: 'American Samoa' },
  { id: 'AD', value: "Andorra", label: 'Andorra' },
  { id: 'AO', value: "Angola", label: 'Angola' },
  { id: 'AI', value: "Anguilla", label: 'Anguilla' },
  { id: 'AG', value: "Antigua and Barbuda", label: 'Antigua and Barbuda' },
  { id: 'AR', value: "Argentina", label: 'Argentina' },
  { id: 'AM', value: "Armenia", label: 'Armenia' },
  { id: 'AW', value: "Aruba", label: 'Aruba' },
  { id: 'AU', value: "Australia", label: 'Australia' },
  { id: 'AT', value: "Austria", label: 'Austria' },
  { id: 'AZ', value: "Azerbaijan", label: 'Azerbaijan' },
  { id: 'BS', value: "Bahamas", label: 'Bahamas' },
  { id: 'BH', value: "Bahrain", label: 'Bahrain' },
  { id: 'BD', value: "Bangladesh", label: 'Bangladesh' },
  { id: 'BB', value: "Barbados", label: 'Barbados' },
  { id: 'BY', value: "Belarus", label: 'Belarus' },
  { id: 'BE', value: "Belgium", label: 'Belgium' },
  { id: 'BZ', value: "Belize", label: 'Belize' },
  { id: 'BJ', value: "Benin", label: 'Benin' },
  { id: 'BM', value: "Bermuda", label: 'Bermuda' },
  { id: 'BT', value: "Bhutan", label: 'Bhutan' },
  { id: 'BO', value: "Bolivia", label: 'Bolivia' },
  { id: 'BA', value: "Bosnia and Herzegovina", label: 'Bosnia and Herzegovina' },
  { id: 'BW', value: "Botswana", label: 'Botswana' },
  { id: 'BR', value: "Brazil", label: 'Brazil' },
  { id: 'IO', value: "British Indian Ocean Territory", label: 'British Indian Ocean Territory' },
  { id: 'BN', value: "Brunei Darussalam", label: 'Brunei Darussalam' },
  { id: 'BG', value: "Bulgaria", label: 'Bulgaria' },
  { id: 'BF', value: "Burkina Faso", label: 'Burkina Faso' },
  { id: 'BI', value: "Burundi", label: 'Burundi' },
  { id: 'KH', value: "Cambodia", label: 'Cambodia' },
  { id: 'CM', value: "Cameroon", label: 'Cameroon' },
  { id: 'CA', value: "Canada", label: 'Canada' },
  { id: 'CV', value: "Cape Verde", label: 'Cape Verde' },
  { id: 'KY', value: "Cayman Islands", label: 'Cayman Islands' },
  { id: 'CF', value: "Central African Republic", label: 'Central African Republic' },
  { id: 'TD', value: "Chad", label: 'Chad' },
  { id: 'CL', value: "Chile", label: 'Chile' },
  { id: 'CN', value: "China", label: 'China' },
  { id: 'CO', value: "Colombia", label: 'Colombia' },
  { id: 'KM', value: "Comoros", label: 'Comoros' },
  { id: 'CG', value: "Congo", label: 'Congo' },
  { id: 'CD', value: "Congo, Democratic Republic", label: 'Congo, Democratic Republic' },
  { id: 'CR', value: "Costa Rica", label: 'Costa Rica' },
  { id: 'CI', value: "Cote d\'Ivoire", label: 'Cote d\'Ivoire' },
  { id: 'HR', value: "Croatia", label: 'Croatia' },
  { id: 'CU', value: "Cuba", label: 'Cuba' },
  { id: 'CY', value: "Cyprus", label: 'Cyprus' },
  { id: 'CZ', value: "Czech Republic", label: 'Czech Republic' },
  { id: 'DK', value: "Denmark", label: 'Denmark' },
  { id: 'DJ', value: "Djibouti", label: 'Djibouti' },
  { id: 'DM', value: "Dominica", label: 'Dominica' },
  { id: 'DO', value: "Dominican Republic", label: 'Dominican Republic' },
  { id: 'EC', value: "Ecuador", label: 'Ecuador' },
  { id: 'EG', value: "Egypt", label: 'Egypt' },
  { id: 'SV', value: "El Salvador", label: 'El Salvador' },
  { id: 'GQ', value: "Equatorial Guinea", label: 'Equatorial Guinea' },
  { id: 'ER', value: "Eritrea", label: 'Eritrea' },
  { id: 'EE', value: "Estonia", label: 'Estonia' },
  { id: 'ET', value: "Ethiopia", label: 'Ethiopia' },
  { id: 'FJ', value: "Fiji", label: 'Fiji' },
  { id: 'FI', value: "Finland", label: 'Finland' },
  { id: 'FR', value: "France", label: 'France' },
  { id: 'GA', value: "Gabon", label: 'Gabon' },
  { id: 'GM', value: "Gambia", label: 'Gambia' },
  { id: 'GE', value: "Georgia", label: 'Georgia' },
  { id: 'DE', value: "Germany", label: 'Germany' },
  { id: 'GH', value: "Ghana", label: 'Ghana' },
  { id: 'GR', value: "Greece", label: 'Greece' },
  { id: 'GD', value: "Grenada", label: 'Grenada' },
  { id: 'GU', value: "Guam", label: 'Guam' },
  { id: 'GT', value: "Guatemala", label: 'Guatemala' },
  { id: 'GN', value: "Guinea", label: 'Guinea' },
  { id: 'GW', value: "Guinea-Bissau", label: 'Guinea-Bissau' },
  { id: 'GY', value: "Guyana", label: 'Guyana' },
  { id: 'HT', value: "Haiti", label: 'Haiti' },
  { id: 'HN', value: "Honduras", label: 'Honduras' },
  { id: 'HK', value: "Hong Kong", label: 'Hong Kong' },
  { id: 'HU', value: "Hungary", label: 'Hungary' },
  { id: 'IS', value: "Iceland", label: 'Iceland' },
  { id: 'IN', value: "India", label: 'India' },
  { id: 'ID', value: "Indonesia", label: 'Indonesia' },
  { id: 'IR', value: "Iran", label: 'Iran' },
  { id: 'IQ', value: "Iraq", label: 'Iraq' },
  { id: 'IE', value: "Ireland", label: 'Ireland' },
  { id: 'IL', value: "Israel", label: 'Israel' },
  { id: 'IT', value: "Italy", label: 'Italy' },
  { id: 'JM', value: "Jamaica", label: 'Jamaica' },
  { id: 'JP', value: "Japan", label: 'Japan' },
  { id: 'JO', value: "Jordan", label: 'Jordan' },
  { id: 'KZ', value: "Kazakhstan", label: 'Kazakhstan' },
  { id: 'KE', value: "Kenya", label: 'Kenya' },
  { id: 'KI', value: "Kiribati", label: 'Kiribati' },
  { id: 'KP', value: "Korea, Democratic People\'s Republic of", label: 'Korea, Democratic People\'s Republic of' },
  { id: 'KR', value: "Korea, Republic of", label: 'Korea, Republic of' },
  { id: 'KW', value: "Kuwait", label: 'Kuwait' },
  { id: 'KG', value: "Kyrgyzstan", label: 'Kyrgyzstan' },
  { id: 'LA', value: "Lao People\'s Democratic Republic", label: 'Lao People\'s Democratic Republic' },
  { id: 'LV', value: "Latvia", label: 'Latvia' },
  { id: 'LB', value: "Lebanon", label: 'Lebanon' },
  { id: 'LS', value: "Lesotho", label: 'Lesotho' },
  { id: 'LR', value: "Liberia", label: 'Liberia' },
  { id: 'LY', value: "Libyan Arab Jamahiriya", label: 'Libyan Arab Jamahiriya' },
  { id: 'LI', value: "Liechtenstein", label: 'Liechtenstein' },
  { id: 'LT', value: "Lithuania", label: 'Lithuania' },
  { id: 'LU', value: "Luxembourg", label: 'Luxembourg' },
  { id: 'MO', value: "Macao", label: 'Macao' },
  { id: 'MK', value: "Macedonia", label: 'Macedonia' },
  { id: 'MG', value: "Madagascar", label: 'Madagascar' },
  { id: 'MW', value: "Malawi", label: 'Malawi' },
  { id: 'MY', value: "Malaysia", label: 'Malaysia' },
  { id: 'MV', value: "Maldives", label: 'Maldives' },
  { id: 'ML', value: "Mali", label: 'Mali' },
  { id: 'MT', value: "Malta", label: 'Malta' },
  { id: 'MH', value: "Marshall Islands", label: 'Marshall Islands' },
  { id: 'MQ', value: "Martinique", label: 'Martinique' },
  { id: 'MR', value: "Mauritania", label: 'Mauritania' },
  { id: 'MU', value: "Mauritius", label: 'Mauritius' },
  { id: 'YT', value: "Mayotte", label: 'Mayotte' },
  { id: 'MX', value: "Mexico", label: 'Mexico' },
  { id: 'FM', value: "Micronesia, Federated States of", label: 'Micronesia, Federated States of' },
  { id: 'MD', value: "Moldova, Republic of", label: 'Moldova, Republic of' },
  { id: 'MC', value: "Monaco", label: 'Monaco' },
  { id: 'MN', value: "Mongolia", label: 'Mongolia' },
  { id: 'ME', value: "Montenegro", label: 'Montenegro' },
  { id: 'MS', value: "Montserrat", label: 'Montserrat' }, 
  { id: 'MA', value: "Morocco", label: 'Morocco' },
  { id: 'MZ', value: "Mozambique", label: 'Mozambique' },
  { id: 'MM', value: "Myanmar", label: 'Myanmar' },
  { id: 'NA', value: "Namibia", label: 'Namibia' },
  { id: 'NR', value: "Nauru", label: 'Nauru' },
  { id: 'NP', value: "Nepal", label: 'Nepal' },
  { id: 'NL', value: "Netherlands", label: 'Netherlands' },
  { id: 'AN', value: "Netherlands Antilles", label: 'Netherlands Antilles' },
  { id: 'NC', value: "New Caledonia", label: 'New Caledonia' },
  { id: 'NZ', value: "New Zealand", label: 'New Zealand' },
  { id: 'NI', value: "Nicaragua", label: 'Nicaragua' },
  { id: 'NE', value: "Niger", label: 'Niger' },
  { id: 'NG', value: "Nigeria", label: 'Nigeria' },
  { id: 'NU', value: "Niue", label: 'Niue' },
  { id: 'NF', value: "Norfolk Island", label: 'Norfolk Island' },
  { id: 'MP', value: "Northern Mariana Islands", label: 'Northern Mariana Islands' },
  { id: 'NO', value: "Norway", label: 'Norway' },
  { id: 'OM', value: "Oman", label: 'Oman' },
  { id: 'PK', value: "Pakistan", label: 'Pakistan' },
  { id: 'PW', value: "Palau", label: 'Palau' },
  { id: 'PS', value: "Palestinian Territory, Occupied", label: 'Palestinian Territory, Occupied' },
  { id: 'PA', value: "Panama", label: 'Panama' }, 
  { id: 'PG', value: "Papua New Guinea", label: 'Papua New Guinea' },
  { id: 'PY', value: "Paraguay", label: 'Paraguay' },
  { id: 'PE', value: "Peru", label: 'Peru' }, 
  { id: 'PH', value: "Philippines", label: 'Philippines' },
  { id: 'PN', value: "Pitcairn", label: 'Pitcairn' },
  { id: 'PL', value: "Poland", label: 'Poland' },
  { id: 'PT', value: "Portugal", label: 'Portugal' },
  { id: 'PR', value: "Puerto Rico", label: 'Puerto Rico' },
  { id: 'QA', value: "Qatar", label: 'Qatar' },
  { id: 'RE', value: "Reunion", label: 'Reunion' },
  { id: 'RO', value: "Romania", label: 'Romania' },
  { id: 'RU', value: "Russian Federation", label: 'Russian Federation' },
  { id: 'RW', value: "Rwanda", label: 'Rwanda' },
  { id: 'SH', value: "Saint Helena", label: 'Saint Helena' },
  { id: 'KN', value: "Saint Kitts and Nevis", label: 'Saint Kitts and Nevis' },
  { id: 'LC', value: "Saint Lucia", label: 'Saint Lucia' },
  { id: 'PM', value: "Saint Pierre and Miquelon", label: 'Saint Pierre and Miquelon' },
  { id: 'VC', value: "Saint Vincent and the Grenadines", label: 'Saint Vincent and the Grenadines' },
  { id: 'WS', value: "Samoa", label: 'Samoa' },
  { id: 'SM', value: "San Marino", label: 'San Marino' },
  { id: 'ST', value: "Sao Tome and Principe", label: 'Sao Tome and Principe' },
  { id: 'SA', value: "Saudi Arabia", label: 'Saudi Arabia' },
  { id: 'SN', value: "Senegal", label: 'Senegal' },
  { id: 'RS', value: "Serbia", label: 'Serbia' },
  { id: 'SC', value: "Seychelles", label: 'Seychelles' },
  { id: 'SL', value: "Sierra Leone", label: 'Sierra Leone' },
  { id: 'SG', value: "Singapore", label: 'Singapore' },
  { id: 'SX', value: "Sint Maarten (Dutch Part)", label: 'Sint Maarten (Dutch Part)' },
  { id: 'SK', value: "Slovakia", label: 'Slovakia' },
  { id: 'SI', value: "Slovenia", label: 'Slovenia' },
  { id: 'SB', value: "Solomon Islands", label: 'Solomon Islands' },
  { id: 'SO', value: "Somalia", label: 'Somalia' },
  { id: 'ZA', value: "South Africa", label: 'South Africa' },
  { id: 'GS', value: "South Georgia and the South Sandwich Islands", label: 'South Georgia and the South Sandwich Islands' },
  { id: 'SS', value: "South Sudan", label: 'South Sudan' },
  { id: 'ES', value: "Spain", label: 'Spain' },
  { id: 'LK', value: "Sri Lanka", label: 'Sri Lanka' },
  { id: 'SD', value: "Sudan", label: 'Sudan' },
  { id: 'SR', value: "Suriname", label: 'Suriname' },
  { id: 'SJ', value: "Svalbard and Jan Mayen", label: 'Svalbard and Jan Mayen' },
  { id: 'SZ', value: "Swaziland", label: 'Swaziland' },
  { id: 'SE', value: "Sweden", label: 'Sweden' },
  { id: 'CH', value: "Switzerland", label: 'Switzerland' },
  { id: 'SY', value: "Syrian Arab Republic", label: 'Syrian Arab Republic' },
  { id: 'TW', value: "Taiwan, Province of China", label: 'Taiwan, Province of China' },
  { id: 'TJ', value: "Tajikistan", label: 'Tajikistan' },
  { id: 'TZ', value: "Tanzania, United Republic of", label: 'Tanzania, United Republic of' },
  { id: 'TH', value: "Thailand", label: 'Thailand' },
  { id: 'TL', value: "Timor-Leste", label: 'Timor-Leste' },
  { id: 'TG', value: "Togo", label: 'Togo' },
  { id: 'TK', value: "Tokelau", label: 'Tokelau' },
  { id: 'TO', value: "Tonga", label: 'Tonga' },
  { id: 'TT', value: "Trinidad and Tobago", label: 'Trinidad and Tobago' },
  { id: 'TN', value: "Tunisia", label: 'Tunisia' },
  { id: 'TR', value: "Turkey", label: 'Turkey' },
  { id: 'TM', value: "Turkmenistan", label: 'Turkmenistan' },
  { id: 'TC', value: "Turks and Caicos Islands", label: 'Turks and Caicos Islands' },
  { id: 'TV', value: "Tuvalu", label: 'Tuvalu' },
  { id: 'UG', value: "Uganda", label: 'Uganda' },
  { id: 'UA', value: "Ukraine", label: 'Ukraine' },
  { id: 'AE', value: "United Arab Emirates", label: 'United Arab Emirates' },
  { id: 'GB', value: "United Kingdom", label: 'United Kingdom' },
  { id: 'US', value: "United States", label: 'United States' },
  { id: 'UY', value: "Uruguay", label: 'Uruguay' },
  { id: 'UZ', value: "Uzbekistan", label: 'Uzbekistan' },
  { id: 'VU', value: "Vanuatu", label: 'Vanuatu' },
  { id: 'VE', value: "Venezuela", label: 'Venezuela' },
  { id: 'VN', value: "Viet Nam", label: 'Viet Nam' },
  { id: 'WF', value: "Wallis and Futuna", label: 'Wallis and Futuna' },
  { id: 'EH', value: "Western Sahara", label: 'Western Sahara' },
  { id: 'YE', value: "Yemen", label: 'Yemen' },
  { id: 'ZM', value: "Zambia", label: 'Zambia' },
  { id: 'ZW', value: "Zimbabwe", label: 'Zimbabwe' }
]


