import { useTranslation } from "react-i18next";

function SettingsNav({ selectedNav, setSelectedNav, TABS }) {
  const { t } = useTranslation();
  return (
    <div className="leftcontent leftcontent-settings">
      <ul className=" navbtns ">
        {TABS.map((navRow) => (
          <li
            key={navRow}
            className={`${selectedNav === navRow ? "active" : ""}`}
          >
            <button
              className={`nav-link ${selectedNav === navRow ? "active" : ""}`}
              onClick={() => setSelectedNav(navRow)}
            >
              {t(navRow)}
            </button>
          </li>
        ))}

        {/* For Extra Space */}
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
        <li />
      </ul>
    </div>
  );
}

export default SettingsNav;
